import React, { useEffect } from "react";

import { connect } from "react-redux";
import {
  Card,
  Box,
  CardActions,
  Button,
  CardContent,
  Typography,
} from "@mui/material";

import { createBrowserHistory } from "history";
import {
  getPricesHistory,
  requestSummary,
  getAccounts,
} from "../../redux/services/homeServices";
import { resetHomeState } from "../../redux/action/homeAction";
export const browserHistory = createBrowserHistory();

const OrderConfirm = (props) => {
  const { orderSuccessResponse, sellSuccessResponse, callReset } = props;

  useEffect(() => {
    // console.log("orderSuccessResponse in fc-->", orderSuccessResponse);
    // console.log("sellSuccessResponse in fc -->", sellSuccessResponse);
  }, [orderSuccessResponse, sellSuccessResponse]);

  const navigateHome = () => {
    callReset();
  };

  return (
    <Box>
      <Card>
        {orderSuccessResponse && (
          <CardContent>
            <Typography variant={"caption"}>Quantity Purchased</Typography>
            <Typography>
              {`${orderSuccessResponse.quantity.amount} ${orderSuccessResponse.quantity.currency}`}
            </Typography>
            <br />

            <Typography variant={"caption"}>Price</Typography>
            <Typography>
              {`${orderSuccessResponse.unit_price.amount} ${orderSuccessResponse.unit_price.currency}`}
            </Typography>
            <br />

            <Typography variant={"caption"}>Fee</Typography>
            <Typography>
              {`$${orderSuccessResponse.paid_fee.amount}`}
            </Typography>
            <br />
          </CardContent>
        )}
        {sellSuccessResponse && (
          <CardContent>
            <Typography variant={"caption"}>Quantity Sold</Typography>
            <Typography>
              {`${sellSuccessResponse.quantity.amount} ${sellSuccessResponse.quantity.currency}`}
            </Typography>
            <br />

            <Typography variant={"caption"}>Price</Typography>
            <Typography>
              {`${sellSuccessResponse.unit_price.amount} ${sellSuccessResponse.unit_price.currency}`}
            </Typography>
            <br />

            <Typography variant={"caption"}>Fee</Typography>
            <Typography>
              {`$${Math.abs(sellSuccessResponse.paid_fee.amount)}`}
            </Typography>
            <br />
          </CardContent>
        )}
        <CardActions>
          <Button
            variant="contained"
            fullWidth={true}
            size="medium"
            onClick={navigateHome}
          >
            New Order
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

const mapStatetoProps = (state) => {
  return {
    token: state.auth.token,
    msg: state.auth.msg,
    status: state.auth.status,
    userdata: state.auth.userdata,
    priceHistory: state.home.priceHistory,
    userPortfolioSummary: state.home.userPortfolioSummary,
    userAccounts: state.home.userAccounts,
    orderSuccessResponse: state.home.orderSuccessResponse,
    sellSuccessResponse: state.home.sellSuccessResponse,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    callPriceHistory: function () {
      dispatch(getPricesHistory());
    },
    callRequestSummary: function () {
      dispatch(requestSummary());
    },
    callGetAccounts: function () {
      dispatch(getAccounts());
    },
    callReset: function () {
      dispatch(resetHomeState());
    },
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(OrderConfirm);

export const validIdProofs = [
        {
            value: 'default',
            label: '--- Select Document ---',
        },
        {
            value: 'drivers_license',
            label: 'Driver\'s License',
        },
        {
            value: 'state_issued_identification_card',
            label: 'State-issued Identification Card',
        },
        {
            value: 'passport',
            label: 'Passport',
        },
    ];

export const validAddressProofs = [
    {
        value: 'default',
        label: '--- Select Document ---',
    },
    {
        value: 'drivers_license',
        label: 'Driver\'s License',
    },
    {
        value: 'property_tax_receipt',
        label: 'Property Tax Receipt',
    },
    {
        value: 'posted_mail_with_name_of_applicant',
        label: 'Posted Mail with name of applicant',
    },
    {
        value: 'utility_bill',
        label: 'Utility Bill',
    },
    {
        value: 'lease_agreement_or_mortgage_statement',
        label: 'Lease Agreement or mortgage statement',
    },
    {
        value: 'insurance_card',
        label: 'Insurance Card',
    },
    {
        value: 'voter_registration_card',
        label: 'Voter Registration Card',
    },
    {
        value: 'bank_statement',
        label: 'Bank or credit card statement',
    },
    {
        value: 'insurance_policy',
        label: 'Insurance policy',
    },
    {
        value: 'bill',
        label: 'Bill',
    },
    ];
import React, {useEffect, useState} from "react";
import { useParams, useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import {DialogTitle, DialogContent} from '@mui/material';

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { createBrowserHistory } from "history";
import {getAccounts}  from '../../redux/services/homeServices';
import {send, receive} from '../../redux/services/walletServices';
import {resetWalletState} from '../../redux/action/walletAction';
import { getDetailedProfileNextStep, getIsSystemKycInprogress} from "../common/Utils";

export const browserHistory = createBrowserHistory();

const currencies = [
  {
    value: 'USD',
    label: 'USD',
  },
];


const Transfer = (props) => {
  const {userAccounts, userdata, callSend, callReceive, sendFailResponse, priceHistory, token, receiveFailResponse, callReset} = props;
  const [orderCurrency, setOrderCurrency] = useState([...currencies]);

  let {coinId} = useParams();
  const [transferType, setTransferType] = useState("send");
  const [label, setLabel] = useState("Pay From");
  const [errorMessage, setErrorMessage] = useState();
  const [isAuthorizeForTrade, setIsAuthorizeForTrade] = useState(false);
  const [kycStatus, setKycStatus] = useState();
  const navigate = useNavigate();

  const [cryptoEntries, setCryptoEntries] = useState([]);
  const [selectedCryptoEntry, setSelectedCryptoEntry] = useState(null);
  const [currentBalance, setCurrentBalance] = useState(0.00);
  const [amount, setAmount] = useState(null);
  const [to, setTo] = useState(null);
  const [mfa, setMfa] = useState(null);
  const [equivalentValue, setEquivalentValue] = useState(0.00);
  const [desc, setDesc] = useState(null);

  const [isSystemKycInprogress, setIsSystemKycInprogress] = useState(true);


  const handleTransferType = (event, value) => {
    setTransferType(value);
    if(value === "send") {
      setLabel("Pay From");
    } else {
      setLabel("Receive In");
    }
  };

  useEffect( () => {
    setIsSystemKycInprogress(getIsSystemKycInprogress(userdata));
      const i = (userdata.kyc_status === 'not_started'||
                 userdata.kyc_status === 'draft' ||
                 userdata.kyc_status === 'pending' ||
                 userdata.kyc_status === "require_action") ? false: true;

      setIsAuthorizeForTrade(i);
      setKycStatus(userdata.kyc_status);

      const tempOrderInCurrency = {
        value: coinId,
        label: coinId
      }
      setOrderCurrency([...orderCurrency, tempOrderInCurrency]);
    }, [userdata, coinId, orderCurrency]
  );

  useEffect(() => {
    if(userAccounts && userAccounts.crypto_entries && userAccounts.crypto_entries.length>0){
      setCryptoEntries(userAccounts.crypto_entries);
      console.log(userAccounts.crypto_entries);
      setSelectedCryptoEntry(userAccounts.crypto_entries[0].type);
      setCurrentBalance(userAccounts.crypto_entries[0].balance.amount)
    }
  }, [userAccounts]);

  useEffect(() => {
    if(sendFailResponse){
      setErrorMessage(sendFailResponse);
    } else if (receiveFailResponse){
      setErrorMessage(receiveFailResponse);
    }
    callReset();
  }, [sendFailResponse, receiveFailResponse]);

  const handlePayWithChange = (event) => {
    setSelectedCryptoEntry(event.target.value);

    const x = cryptoEntries.find(x => x.type === event.target.value);
    setCurrentBalance(x.balance.amount)
  }

  const handleAmountChange = (event) => {
    const selectedCurrencyObject = Object.values(priceHistory).find(element =>
      element.currency === selectedCryptoEntry
    );
    const x = selectedCurrencyObject.current_price*event.target.value
    setEquivalentValue(x);
    setAmount(event.target.value);
  }

  const handleToChange = (event) => {
    setTo(event.target.value);
  }

  const handleMFAChange = (event) => {
    setMfa(event.target.value);
  }

  const handleEquivalentValueChange = (event) => {
    const selectedCurrencyObject = Object.values(priceHistory).find(element => element.currency === selectedCryptoEntry);
    const x = (event.target.value/selectedCurrencyObject.current_price).toFixed(8);
    setEquivalentValue(event.target.value);
    setAmount(x);
  }

  const placeTransfer = () => {
    if(transferType === 'send') {
      const request = {
        amount: {
          amount: amount,
          currency: selectedCryptoEntry,
        },
        two_fa_code: mfa,
        to: to,
      };

      callSend(request, token);
    } else if (transferType === 'receive'){
      let description = desc? desc: `to ${to}`;
      const request = {
        amount: {
          amount: amount,
          currency: selectedCryptoEntry,
        },
        to: to,
        description
      };
      callReceive(request, token);

    }
  }

  const authorizeCoinSpaze = () => {
    let nextStep = getDetailedProfileNextStep(userdata);
    navigate(nextStep.navigateTo);
  }

  const handleDecsChange = (event) => {
    setDesc(event.target.value);
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, '& .MuiTextField-root': { m: 1},}}>
        <Card sx={{redius:10,bgcolor:'primary.secondary', borderColor: 'primary.borderColor', width:'80%', margin:'auto'}}>
            <CardActions>
            <ToggleButtonGroup
              value={transferType}
              exclusive
              onChange={handleTransferType}
              aria-label="order type"
              color='secondary'
              fullWidth={true}>
              <ToggleButton value="send" size="large" aria-label="send transfer">
                Send
              </ToggleButton>
              <ToggleButton value="receive" size="large" aria-label="receive transfer">
                Receive
              </ToggleButton>
            </ToggleButtonGroup>
            </CardActions>
            { isAuthorizeForTrade && <CardContent>
            {errorMessage && <Box
              size="medium"
              sx={{ m:2, height: '25%', color: 'red' }}
            >
              {errorMessage.message}
              </Box>
            }
                <DialogContent>
                {cryptoEntries &&
                <>
                  <DialogTitle sx={{ p: 4, color:'text.gray', fontSize:'14px'}}> {`Current Balance: ${currentBalance} ${selectedCryptoEntry}`} </DialogTitle>
                    <TextField
                    required
                    label= 'email or coin address'
                    id="outlined-basic"
                    variant="outlined"
                    color="secondary"
                    onChange={handleToChange}
                    placeholder="email or address"
                    fullWidth
                  />
                  <TextField
                    id="outlined-select-currency"
                    className="form-item help-text-field"
                    value={selectedCryptoEntry}
                    select
                    label= {label}
                    onChange={handlePayWithChange}
                    color="secondary"
                    sx={{color:"text.primary"}}
                    fullWidth
                    >
                    {cryptoEntries.map((cryptoEntry) => (
                      <MenuItem key={cryptoEntry.type} value={cryptoEntry.type} style={{color:"black"}}>
                        {cryptoEntry.type}
                      </MenuItem>
                    ))}
                  </TextField>
                </>
                }
                <TextField
                  required
                  id="outlined-basic"
                  variant="outlined"
                  color="secondary"
                  label='Amount or Quantity'
                  placeholder="0.00"
                  onChange={handleAmountChange}
                  value={amount}
                  InputProps={{
                    inputProps: {
                      type: 'number',
                      min: 0,
                    },
                    style: { fontSize: 56},
                    align:'center'}}
                  fullWidth
                />
                <TextField
                    required
                    label= 'Dollar equivalent'
                    id="outlined-basic"
                    variant="outlined"
                    color="secondary"
                    onChange={handleEquivalentValueChange}
                    value={equivalentValue}
                    placeholder="0.00"
                    fullWidth
                />
                {transferType && transferType !== 'receive' && <TextField
                  id="outlined-basic"
                  variant="outlined"
                  color="secondary"
                  lable="MFA Code"
                  placeholder="MFA Code"
                  onChange={handleMFAChange}
                  fullWidth
                />}
                {transferType && transferType === 'receive' && <TextField
                  id="outlined-basic"
                  variant="outlined"
                  color="secondary"
                  lable="Description"
                  placeholder="Description"
                  onChange={handleDecsChange}
                  fullWidth
                />}
              </DialogContent>
            </CardContent>}
            { isAuthorizeForTrade && <CardActions sx={{justifyContent:"center"}}>
                <Button
                  variant="contained" fullWidth={true}
                  size="medium" onClick={placeTransfer}
                  sx={{m:2, fontWeight: 'bold',}}
                  style={{
                    color:"#000000",
                    background: "linear-gradient(180deg, #FED3A5 10%, #D461E6 100%)",
                    width:"80%",
                    height:"40px"
                  }}>Continue</Button>
            </CardActions>}
            {!isAuthorizeForTrade && !isSystemKycInprogress && <CardActions >
                <Button
                  variant="contained" fullWidth={true}
                  size="medium" onClick={authorizeCoinSpaze}
                  sx={{m:2}}
                  style={{
                    color:"#000000",
                    background: "linear-gradient(180deg, #FED3A5 10%, #D461E6 100%)",
                    width:"80%",
                    height:"80px"
                  }}>Complete profile to start trading</Button>
            </CardActions>}
            {isSystemKycInprogress && <Box
              size="medium"
              sx={{ m:2, height: '25%', color: 'text.error' }}
            >
              {`Your Kyc check is ${kycStatus}. Please try after some time`}
              </Box>}
        </Card>
      </Box>
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    token:state.auth.token,
    userdata: state.auth.userdata,
    userAccounts: state.home.userAccounts,
    sendSuccessResponse: state.wallet.sendSuccessResponse,
    sendFailResponse: state.wallet.sendFailResponse,
    priceHistory: state.home.priceHistory,
    receiveFailResponse: state.wallet.receiveFailResponse,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    callGetAccounts: function (token) {
      dispatch(getAccounts(token));
    },
    callReset: function () {
      dispatch(resetWalletState());
    },
    callSend: function(request, token) {
      dispatch(send(request, token));
    },
    callReceive: function(request, token) {
      dispatch(receive(request, token));
    }
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(Transfer);

/* Helpter function to return users allowed actions based upon current profile state.*/
export function getDetailedProfileNextStep(userdata){
    if(userdata) {
        if (userdata.kyc_status === "verified") {
            return {
                "navigateTo":"/home",
                "readyForTransaction":true,
            };
        } else if(userdata.kyc_status === "not_started" || userdata.kyc_status === "draft" || userdata.kyc_status === "require_action") {
            return {
                "navigateTo":"/profile",
                "readyForTransaction":false
            };    
        } else if(userdata.agreement_accepted && !userdata.id_proof_uploaded){
            return {
                "navigateTo":"/upload-document",
                "readyForTransaction":false
            };
        } else if(userdata.agreement_accepted && !userdata.address_proof_uploaded){
            return {
                "navigateTo":"/upload-address-document",
                "readyForTransaction":false
            };
        } else {
            return {
                "navigateTo":"/profile",
                "readyForTransaction":false
            };
        }
    } else {
        return {
            "nextPage":"/home",
            "readyForTransaction":false
        };
    }
}

/* function to generate UUID */
export function generateUUID(digits) {
    let str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVXZ';
    let uuid = [];
    for (let i = 0; i < digits; i++) {
        uuid.push(str[Math.floor(Math.random() * str.length)]);
    }
    return uuid.join('');
}

/* Helper function to return custome file to support backend API (Should be align to BE API). */
export function getCustomDocument(inputFile, documentType, docId) {
    var file = inputFile;
    var name = documentType + "__docId__" + docId  + "__" + file.name;
    var blob = file.slice(0, file.size, file.type);
    var customeFile = new File([blob], name, { type: file.type });
    return customeFile
}

export function getIsSystemKycInprogress(userdata){
    if(userdata.kyc_status === "pending" && userdata.id_proof_uploaded && userdata.address_proof_uploaded) {
        return true
    }
    return false;
}

export function getKycRequiredAction(userdata){
    if(userdata && userdata.kyc_required_actions && userdata.kyc_required_actions.length > 0){
        return {
            isActionRequire: true,
            requireActionList : userdata.kyc_required_actions
        }     
    } else {
        return {
            isActionRequire: false,
            requireActionList : undefined
        }   
    }
}

import React from "react";
import { Route, Routes} from "react-router-dom";

import SplashScreen from "./SplashScreen";

//import AuthLogin from "./AuthComponent/Login";
import ResetPwd from "./AuthComponent/Resetpwd";
import AuthRegister from "./AuthComponent/Register";
import SetupProfileStep1 from "./AuthComponent/setupProfilestep1";
import SetupProfileStep2 from "./AuthComponent/setupProfilestep2";

import PatriotAgreement  from "./KycComponent/PatriotAgreement";

import DisplayAgreement from "./KycComponent/displayAgreement";
import UploadDocument from "./KycComponent/uploadDocument";
import UploadAddressProofDocument from "./KycComponent/uploadAddressProofDocument";
import DocumentType from "./KycComponent/documentType";
import DocumentVerify from "./KycComponent/documentVerify";
import UploadSuccess from "./KycComponent/uploadSuccess";
import Home from "./Home/home";

import Profile from './UserProfile';
import CoinbaseAuthorization from './SettingComponent/CoinbaseAuthorization';
import Preference from './SettingComponent/Preferences';
import CryptoAddress from './SettingComponent/CryptoAddress';
import Transfer from './SettingComponent/Transfer';
import TwoFectorAuth from './SettingComponent/TwoFectorAuth';
import Invite from './SettingComponent/Invite';
import Help from './SettingComponent/Help';

import Crypto from "./Trade/Crypto";
import Order from "./Trade/Order";

import Transactions from "./Transactions/latestTransactions";

import Amount from "./TransferComponent/Amount";
import BankAccountDetail from "./TransferComponent/BankAccountDetail";
import DisbursementAccount from "./TransferComponent/DisbursementAccount";
import WireTransferInstructions from "./TransferComponent/WireTransferInstructions";

import Invoices from "./Invoices/invoices"; 
import CreateInvoice from "./Invoices/CreateInvoice";
import  Socure  from "./KycComponent/Socure";
import Faq from "./FAQ/faq";

export const MainContainer = () => {
  return (
    <>
    <Routes>
      <Route path="/" element={<SplashScreen />} />
      <Route path="/login" element={<SplashScreen />} />
      <Route path="/reset-password" element={<ResetPwd />} />
      <Route path="/register" element={<AuthRegister />} />
      <Route path="patriot-agreement" element={<PatriotAgreement />} />
      <Route path="/user-profile" element={<Profile />} />
      <Route path="/patriot-agreement" element={<PatriotAgreement />} />
      <Route path="/show-agreement" element={<DisplayAgreement />} />
      <Route path="/upload-document" element={<UploadDocument />} />
      <Route path="/upload-address-document" element={<UploadAddressProofDocument />} />
      <Route path="/choose-document-type" element={<DocumentType />} />
      <Route path="/verify-document" element={<DocumentVerify />} />
      <Route path="/upload-success" element={<UploadSuccess />} />
      <Route path="/profile" element={<SetupProfileStep1 />} />
      <Route path="/kyc" element={<SetupProfileStep2 />} />
      <Route path="/dummy" element={<dummyComponent />} />
      <Route path="/home" element={<Home />} />
      <Route path="/user-profile" element={<Profile />} />
      <Route path="/coinbase-authorization" element={<CoinbaseAuthorization />} />
      <Route path="/preference" element={<Preference />} />
      <Route path="/transfer" element={<Transfer />} />
      <Route path="/mfa" element={<TwoFectorAuth />} />
      <Route path="/crypto-address" element={<CryptoAddress />} />
      <Route path="/invite" element={<Invite />} />
      <Route path="/help" element={<Help />} />
      <Route path="/crypto/:coinId" element={<Crypto />} />
      <Route path="/order" element={<Order />} />
      <Route path="/transactions" element={<Transactions />}/>
      <Route path="/bank-account-detail" element={<BankAccountDetail />}/>
      <Route path="/amount" element={<Amount />}/>
      <Route path="/disbursement-account" element={<DisbursementAccount />}/>
      <Route path="/wire-transfer-instruction" element={<WireTransferInstructions />}/>
      <Route path="/invoices" element={<Invoices/>} />
      <Route path="/create-invoice" element={<CreateInvoice/>} />
      <Route path="/socure" element={<Socure/>}/>
      <Route path="/faq" element={<Faq />}>
      </Route> 
    </Routes>
    </>
  );
};

import {
  createInvoiceResponse,
  getInvoiceListResponse,
  uploadInvoiceResponse
} from '../action/invoiceAction';
import { API } from "./api";
import {API_ENDPOINTS} from "./api-endpoint";

export function createInvoice(data, token) {
  return (dispatch) => API.request({
    method: 'POST',
    url: `${API_ENDPOINTS.INVOICE}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data:data,
  }).then(response => {
    dispatch(createInvoiceResponse(response));
  }).catch((error) => {
    dispatch(createInvoiceResponse(error));
  });
}

export function invoiceList(token) {
  return (dispatch) => API.request({
    method: 'GET',
    url: `${API_ENDPOINTS.INVOICE}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(response => {
    dispatch(getInvoiceListResponse(response));
  }).catch((error) => {
    dispatch(getInvoiceListResponse(error));
  });
}

export function uploadInvoice(data, token) {
  return (dispatch) => API.request({
    method: 'POST',
    url: `${API_ENDPOINTS.INVOICE_FILE}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
  }).then(response => {
    // console.log(response);
    dispatch(uploadInvoiceResponse(response));
  }).catch((error) => {
    dispatch(uploadInvoiceResponse(error));
  });
}

import * as React from 'react';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';


const CoinbaseAuthorization = () => {
  return (
    <>
      <div className="login_wrapper">
          <Container maxWidth="lg">  
              <Box sx={{ bgcolor: '#cfe8fc', height: '80vh' }} />
          </Container>
      </div>
     </>      
  );
}

export default CoinbaseAuthorization;

import {
  resetPasswordSuccess,
  LoginWithTempDetailsAction,
  responseFail,
  LogOut,
  redirectTologIn,
  logInSuccess,
  setupProfilestep1Success,
  setupProfilestep2Success,
} from "../action/authAction";
import { API } from "./api";
import { API_ENDPOINTS } from "./api-endpoint";

export function Login(email, password) {
  return (dispatch) => {
    API.request({
      method: "POST",
      url: `${API_ENDPOINTS.LOGIN}`,
      auth: {
        username: email,
        password: password,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        response.data["email"] = email;
        response.data["password"] = password;
        //var rndm = JSON.parse(response.data);
        //console.log(response.data['id']);
        //console.log(response.data['password']);
        //console.log(response.data['password']);
        localStorage.setItem("userdata", JSON.stringify(response.data));
        setToken(email, password);
        dispatch(logInSuccess(response));
      })
      .catch(() => {
        dispatch(responseFail(false));
      });
  };
}

export function getProfile(token) {
  return (dispatch) => {
    API.request({
      method: "POST",
      url: `${API_ENDPOINTS.LOGIN}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
        localStorage.setItem("userdata", JSON.stringify(response.data));
        dispatch(logInSuccess(response));
      })
      .catch(() => {
        dispatch(responseFail(false));
      });
  };
}

export function LoginWithTempDetails(email, password) {
  return (dispatch) => {
    API.request({
      method: "POST",
      url: `${API_ENDPOINTS.TEMPORARY_PASSWORD}`,
      auth: {
        username: email,
        password: password,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        dispatch(LoginWithTempDetailsAction(response));
      })
      .catch(() => {
        dispatch(responseFail(false));
      });
  };
}

export function checkuserbyemail(email, hmac, clientid) {
  return (dispatch) => {
    API.request({
      method: "POST",
      url: `${API_ENDPOINTS.VERIFY_CLIENT_CUSTOMER}`,
      data: { email: email },
      headers: {
        "x-coinspaze-hmac": hmac,
        "x-coinspaze-client-id": clientid,
      },
    })
      .then((response) => {
        return dispatch(redirectTologIn(response));
      })
      .catch((error) => {
        console.error(error);
        if(error.status=== 409){
          return dispatch(redirectTologIn(error));
        }
        return dispatch(responseFail(error.message));
      });
  };
}

export function updatepassword(password, token) {
  return (dispatch) => {
    API.request({
      method: "PUT",
      url: `${API_ENDPOINTS.RESET_PASSWORD}`,
      data: { password: password },
      headers: {
        Authorization: `Basic ${token}`,
      },
    })
      .then((response) => {
        return dispatch(resetPasswordSuccess(response));
      })
      .catch((error) => {
        // console.log(error);
        return dispatch(responseFail(error));
      });
  };
}

export function setupProfilestep1Service(
  fname,
  mname,
  lname,
  dob,
  phone,
  phoneNumberCountry,
  token,
) {
  var userdata = JSON.parse(localStorage.getItem("userdata"));
  let latestUserData1 = {
    first_name: fname,
    mname: mname,
    last_name: lname,
    date_of_birth: dob,
    phone_number: phone,
    phone_number_country: phoneNumberCountry,
    country: userdata["country"],
    state: userdata["state"],
    zip: userdata["zip"],
    city: userdata["city"],
    address_one: userdata["address_one"],
    address_two: userdata["address_two"],
    tax_id_number: userdata["tax_id"],
    tax_id_country: userdata["tax_id_country"],
    tax_id_state: userdata["tax_state"],
  };

  localStorage.setItem("userdata", JSON.stringify(latestUserData1));
  
  return (dispatch) => {
    API.request({
      method: "PUT",
      url: `${API_ENDPOINTS.PROFILE_UPDATE}`,
      data: latestUserData1,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        localStorage.setItem("userdata", JSON.stringify(response.data));
        dispatch(setupProfilestep1Success(response));
      })
      .catch(() => {
        dispatch(responseFail(false));
      });
  };
}

export function setupProfilestep2Service(
  country,
  state,
  zipcode,
  city,
  addressone,
  addresstwo,
  Taxid,
  tax_id_country,
  taxState,
  token,
) {
  var fetchCredentials = JSON.parse(localStorage.getItem("userdata"));
  let latestUserData2 = {
    name: `${fetchCredentials["first_name"]} ${fetchCredentials["last_name"]}`,
    first_name: fetchCredentials["first_name"],
    mname: fetchCredentials["mname"],
    last_name: fetchCredentials["last_name"],
    date_of_birth: fetchCredentials["date_of_birth"],
    phone_number: fetchCredentials["phone_number"],
    phone_number_country: fetchCredentials["phone_number_country"],
    country: country,
    state: state,
    zip: zipcode,
    city: city,
    address_one: addressone,
    address_two: addresstwo,
    tax_id_number: Taxid,
    tax_id_country: tax_id_country,
    tax_id_state: taxState,
    pi_oauth:true
  };

  return (dispatch) => {
    API.request({
      method: "PUT",
      url: `${API_ENDPOINTS.PROFILE_UPDATE}`,
      data: latestUserData2,
      headers: {
        Authorization: `Bearer ${token}`,
        exchange_provider: "pi",
      },
    })
      .then((response) => {
        localStorage.setItem("userdata", JSON.stringify(response.data));
        dispatch(setupProfilestep2Success(response));
      })
      .catch((error) => {
        dispatch(responseFail(false));
      });
  };
}

export default function adminlogut(token) {
  return (dispatch) => {
    dispatch(LogOut());
  };
}

function setToken(email, password) {
  const encodedString = Buffer.from(`${email}:${password}`).toString("base64");
  localStorage.setItem("token", encodedString);
}

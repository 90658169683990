import moment from 'moment';

class Validator {
  static isValidEmail(value) {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    return reg.test(value);
  }

  static isValidName(value) {
    let reg = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
    return reg.test(value);
  }

  static isValidPhoneNumber(value) {
    let reg = /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    return reg.test(value);
  }

  static isValidCountryCode(value) {
    let reg = /^(\+?\d{1,3}|\d{1,4})$/;
    return reg.test(value);
  }

  static isValidBirth(value) {
    const age = moment().diff(moment(value), 'year');
    return age >= 18;
  }

  static isValidPassword(value) {
    if (
      this.hasUpperCase(value) &&
      this.hasNumber(value) &&
      this.hasLowerCase(value) &&
      this.hasSpecial(value)
    ) {
      return true;
    }
    return false;
  }

  static hasUpperCase(str) {
    return /[A-Z]/.test(str);
  }

  static hasLowerCase(str) {
    return /[a-z]/.test(str);
  }

  static hasNumber(str) {
    return /[0-9]/.test(str);
  }

  static hasSpecial(str) {
    return /[!@#$%^&*]/.test(str);
  }

  static isValidZip(str) {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(str);
  }

  static isValidAddress(str) {
    if (str && str.length > 3) {
      return true;
    }
    return false;
  }

  static isValidRoutingNumber(value) {
    let reg = /^[0-9]{9}$/;
    return reg.test(value);
  }

  static isValidBankAccountNumber(value) {
    let reg = /^[0-9]{9,20}$/;
    return reg.test(value);
  }

  static isValidCity(value) {
    let reg = /^[\s]*?[a-zA-Z]+([\s-][a-zA-Z]+)*[\s]*?$/;
    return reg.test(value);
  }
}

export {Validator};

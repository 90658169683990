
import {
  responseFail,
} from "../action/authAction";
import {
  getAgreementSuccess,
  acceptAgreementSuccess,
  uploadDocumentSuccess,
  socurePostSuccess,
  socurePostFail,
} from "../action/kycAction.js";
import { API } from "./api";
import {API_ENDPOINTS} from "./api-endpoint";

export function getAgreement(token) {
  return (dispatch) => API.request({
    method: 'GET',
    url: `${API_ENDPOINTS.CUSTOMER_AGREEMENT}`,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
  }).then(response => {
    // console.log(response);
    dispatch(getAgreementSuccess(response));
  }).catch((error) => {
    dispatch(responseFail(false));
  });
}

export function acceptAgreement(data, token) {
  return (dispatch) =>  API.request({
    method: 'PUT',
    url: `${API_ENDPOINTS.CUSTOMER_AGREEMENT}`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
  }).then(response => {
    // console.log(response);
    dispatch(acceptAgreementSuccess(response));
  }).catch((error) => {
    dispatch(responseFail(false));
  });
}

export function uploadDocs(data, token) {
  return (dispatch) => API.request({
    method: 'POST',
    url: `${API_ENDPOINTS.UPLOAD_DOCS}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
  }).then(response => {
    // console.log(response);
    dispatch(uploadDocumentSuccess(response));
  }).catch((error) => {
    dispatch(responseFail(false));
  });
}
export function postSocureData(data, token) {
  return (dispatch) => API.request({
    method: 'PUT',
    url: `${API_ENDPOINTS.PROFILE_UPDATE}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
  }).then(response => {
    dispatch(socurePostSuccess(response));
  }).catch((error) => {
    dispatch(socurePostFail(false));
  });
}

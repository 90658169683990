import * as React from 'react';
import { connect } from "react-redux";

import { MenuItem, TextField } from '@mui/material';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {postPaymentMethods} from "../../redux/services/paymentServices";
import { Validator } from "../../helper/Validator";
import { useNavigate } from 'react-router-dom';

const currencies1 = [
    {
        value: 'wire',
        label: 'Wire',
    },
];
const currencies2 = [
    {
        value: 'Saving',
        label: 'Saving',
    },
    {
        value: 'Checking',
        label: 'Checking',
    },
];

const BankAccountDetail = (props) => {
    const navigate = useNavigate();
    const [accountHolderName, setAccountHolderName] = React.useState();
    const [accountHolderNameErrors, setAccountHolderNameErrors] = React.useState([]);

    const [routingNumber, setRoutingNumber] = React.useState();
    const [routingNumberErrors, setRoutingNumberErrors] = React.useState([]);
    
    const [accountNumber, setAccountNumber] = React.useState();
    const [bankAccountNumberErrors, setBankAccountNumberErrors] = React.useState([]);

    const [accountType, setAccountType] = React.useState('Checking');

    const [transferType, setTransferType] = React.useState('wire');

    const [buttonDisabled, setButtonDisabled] =  React.useState(true);
    const {callPostPaymentMethods, token} = props;
    
    const handleAccountHolderNameChange = (event) =>{
        setAccountHolderName(event.target.value);
        _validateAccountHolderName(event.target.value, setAccountHolderNameErrors);
    }

    const _validateAccountHolderName = (value, setErrors) => {
        if (Validator.isValidName(value)) {
            setErrors([]);
        } else {
            setErrors([
            'Your name must be at least 1 character long.',
            'Your name must not contain numbers.',
            ]);
        }
    };

    const handleRoutingNumberChange = (event) => {
        const value = event.target.value;
        setRoutingNumber(value);
        _validateRoutingNumber(value, setRoutingNumberErrors);
    }

    const _validateRoutingNumber = (value, setErrors) => {
        if (Validator.isValidRoutingNumber(value)) {
            setErrors([]);
        } else {
        setErrors(['Your routing must be 9 digit long']);
        }
    }

    const handleAccountNumberChange = (event) =>{
        const value = event.target.value;
        setAccountNumber(event.target.value);
        _validatebankAccountNumber(value, setBankAccountNumberErrors);
    }

    const _validatebankAccountNumber = (value, setErrors) => {
        if (Validator.isValidBankAccountNumber(value)) {
            setErrors([]);
        } else {
        setErrors(['Please enter valid account number']);
        } 
    }
    
    const handleChange1 = (event) => {
        setTransferType(event.target.value);
    };


    const handleChange2 = (event) => {
        setAccountType(event.target.value);
    };

    React.useEffect(() => {
        if((accountHolderNameErrors && accountHolderNameErrors?.length>0) ||
        (routingNumberErrors && routingNumberErrors?.length>0) ||
        (bankAccountNumberErrors && bankAccountNumberErrors?.length>0) ){
            setButtonDisabled(true);
        } else {
            setButtonDisabled(false);
        }    
    }, [accountHolderNameErrors, routingNumberErrors, bankAccountNumberErrors])

    const submitForm = () => {
    if(buttonDisabled){
        return;
    }
    const request = {
        "bank_account_name": accountHolderName,
        "bank_account_number": accountNumber,
        "bank_account_type": accountType,
        "routing_number": routingNumber,
        "funds_transfer_type": transferType
      }
      console.log(request);
      callPostPaymentMethods(request, token);
    }
    return (
        <>
            <div className="login_wrapper">
                <Container maxWidth="lg">
                    <Box sx={{ bgcolor: '#FFFFFF', fontSize: "45px", color: "black",  padding:'10%',paddingTop:'5%', borderRadius:'20px'}}>
                        <p style={{ textAlign: 'center' }}>Bank Account Detail</p>
                        <div className="form-item help-text-field">
                            <TextField
                                sx={{mt:5}}
                                inputProps={{ style: { color: "#000000" } }}
                                id="outlined-disabled"
                                onChange={handleAccountHolderNameChange}
                                style={{ width: '100%', border: "1px solid #c483d1", borderRadius: '15px' }}
                                placeholder="Account Holder Name"
                                helperText={accountHolderNameErrors}
                                error={accountHolderNameErrors && accountHolderNameErrors.length>0}
                            />
                        </div>
                        <div className="form-item help-text-field">
                            <TextField
                                sx={{mt:5}}
                                inputProps={{ style: { color: "#000000" } }}
                                id="outlined-disabled"
                                onChange={handleRoutingNumberChange}
                                style={{ width: '100%', border: "1px solid #c483d1", borderRadius: '15px' }}
                                placeholder="Routing Number"
                                helperText={routingNumberErrors}
                                error={routingNumberErrors && routingNumberErrors?.length>0}
                            />
                        </div>
                        <div className="form-item help-text-field">
                            <TextField
                                sx={{mt:5}}
                                inputProps={{ style: { color: "#000000" } }}
                                id="outlined-disabled"
                                onChange={handleAccountNumberChange}
                                style={{ width: '100%', border: "1px solid #c483d1", borderRadius: '15px' }}
                                placeholder="Bank Account Number"
                                helperText={bankAccountNumberErrors}
                                error={bankAccountNumberErrors && bankAccountNumberErrors?.length>0}
                            />
                        </div>
                        <div className="form-item help-text-field">
                            <TextField
                                sx={{mt:5}}
                                id="outlined-select-currency"
                                className="form-item help-text-field"
                                select
                                value={transferType}
                                onChange={handleChange1}
                                style={{ border: "1px solid #c483d1", width: '100%', borderRadius: '15px' }}>
                                {currencies1.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div className="form-item help-text-field">
                            <TextField
                                sx={{mt:5}}
                                id="outlined-select-currency"
                                className="form-item help-text-field"
                                select
                                value={accountType}
                                onChange={handleChange2}
                                style={{ border: "1px solid #c483d1", width: '100%', borderRadius: '15px' }}>
                                {currencies2.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div className='flex flex-row justify-center w-1/6 gap-2 mx-auto'>
                            <Button sx={{mt:5}} variant="outlined" 
                             onClick={() => navigate("/disbursement-account")}>
                                Cancel
                            </Button>
                            <Button sx={{mt:5}} variant="contained"
                             disabled={buttonDisabled} 
                             onClick={submitForm}>
                                Send
                            </Button>
                        </div>
                    </Box>
                </Container>
            </div>
        </>
    );
}

const mapStatetoProps = (state) => {
    return {
        token:state.auth.token,
        wtiSuccessResponse: state.payment.wtiSuccessResponse,
    };
};
const mapDispatchtoProps = (dispatch) => {
    return {
        callPostPaymentMethods: function (data, token) {
            dispatch(postPaymentMethods(data, token));
        },
    };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(BankAccountDetail);
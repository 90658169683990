import { Stack, Skeleton } from "@mui/material";

export const SkeletonComponent = () => {
  return (
    <Stack spacing={1} className="mt-8">
      <Skeleton variant="rectangular" width={"100%"} height={60} />
      <Skeleton variant="rectangular" width={"100%"} height={60} />
      <Skeleton variant="rectangular" width={"100%"} height={60} />
      <Skeleton variant="rectangular" width={"100%"} height={60} />
      <Skeleton variant="rectangular" width={"100%"} height={60} />
      <Skeleton variant="rectangular" width={"100%"} height={60} />
      <Skeleton variant="rectangular" width={"100%"} height={60} />
      <Skeleton variant="rectangular" width={"100%"} height={60} />
      <Skeleton variant="rectangular" width={"100%"} height={60} />
      <Skeleton variant="rectangular" width={"100%"} height={60} />
      <Skeleton variant="rectangular" width={"100%"} height={60} />
      <Skeleton variant="rectangular" width={"100%"} height={60} />
      <Skeleton variant="rectangular" width={"100%"} height={60} />
      <Skeleton variant="rectangular" width={"100%"} height={60} />
      <Skeleton variant="rectangular" width={"100%"} height={60} />
      <Skeleton variant="rectangular" width={"100%"} height={60} />
    </Stack>
  );
};

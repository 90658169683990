import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import SideDrawer from "./component/SideDrawer";
import { CookieConsentBanner } from "./component/CookieConsentBanner";

const theme = (mode) =>
  createTheme({
    components: {
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: "#000000",
          },
        },
      },
    },
  });

function App() {
  const [themeConfig, setTheme] = useState("light");

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      console.log = function () {};
    }
  }, []);

  useEffect(() => {
    const themeConfig = window.localStorage.getItem("theme");
    if (themeConfig) {
      setTheme(themeConfig);
    }
  }, []);

  return (
    <>
      <ThemeProvider theme={theme(themeConfig)}>
        <Provider store={store}>
          <div id="app" className="d-flex flex-column h-100">
            <BrowserRouter>
              <SideDrawer />
              <CookieConsentBanner />
            </BrowserRouter>
          </div>
        </Provider>
      </ThemeProvider>
    </>
  );
}

export default App;


import {
  getMFASuccess,
  getMFAFail,
  helpSuccess,
  helpFail,
  sendInvitationResponse
} from '../action/settingAction';
import { API } from "./api";
import {API_ENDPOINTS} from "./api-endpoint";

export function getMFA(token) {
  return (dispatch) => API.request({
    method: 'GET',
    url: `${API_ENDPOINTS.MFA_SETTINGS}`,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
  }).then(response => {
    dispatch(getMFASuccess(response));
  }).catch((error) => {
    dispatch(getMFAFail(false));
  });
}
export const getHelp = (data, token) => {
  return (dispatch) =>  API.request({
    method: 'POST',
    url: `${API_ENDPOINTS.HELP}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
  }).then(response => {
    dispatch(helpSuccess(response));
  }).catch((error) => {
    dispatch(helpFail(error));
  }); 
}

export const sendInvite = (data, token) => {
  return (dispatch) =>  API.request({
    method: 'POST',
    url: `${API_ENDPOINTS.INVITATION}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
  }).then(response => {
    dispatch(sendInvitationResponse(response));
  }).catch((error) => {
    dispatch(sendInvitationResponse(error));
  }); 
}

import React from "react";

import { connect } from "react-redux";

import { createBrowserHistory } from "history";
export const browserHistory = createBrowserHistory();

function documentVerify(props) {
  return (
    <div className="login_wrapper">
		<div className="container">
			<div className="login_header">
				<div className="row">
					<div className="col-lg-6">
					<img className="logo-style" src="./images/logo.png" alt="CoinSpaze" />
					</div>
				</div>
			</div>

			<div className="doc_upload_sec">
				<div className="doc_upload_content">
					<div className="doc_upload_title">
						<h3>Drag or click here to upload</h3>
					</div>
					<div className="doc_upload_box">
						<form>
							<div className="form-group">
								<label>Front</label>
								<div id="drop_zone" className="drop-zone">
									<img src="images/front_shape.png" className="drop_front_img" alt="front_image"/>
									<div className="preview-container"></div>
								</div>
								<input id="file_input" accept="image/*" type="file" multiple="" name="file"/>
							</div>
							<div className="form-group">
								<label>Back</label>
								<div id="drop_zone1" className="drop-zone">
									<img src="images/back_shape.png" className="drop_back_img" alt="back_image"/>
									<div className="preview-container"></div>
								</div>
								<input id="file_input1" accept="image/*" type="file" multiple="" name="file"/>
							</div>
							<p>Please make sure that all information is within border of the scanner</p>
							<div className="login_btn">
								<button type="button">Next</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
  );
}

const mapStatetoProps = (state) => {
  return {
    token: state.auth.token,
    msg: state.auth.msg,
    status: state.auth.status,
    userdata: state.auth.userdata,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    LoginWithTempDetails: function (email, password) {
      //dispatch(getAgreement());
    },
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(documentVerify);

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getCASH, getPaymentMethods } from "../../redux/services/paymentServices";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/Box";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TuneIcon from "@mui/icons-material/Tune";
import { useNavigate, Navigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { Alert, Card, CardContent, Typography } from "@mui/material";

const Transfer = (props) => {
  const { cashBalanceResponse, requestCASH, callGetPaymentMethods, bankAccounts, status, token } = props;
  const [nextPage, setNextPage] = React.useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if(token !== ""){
      requestCASH(token);
      callGetPaymentMethods(token);
    }
  }, [requestCASH, callGetPaymentMethods, token]);

  React.useEffect(() => {
    if (nextPage !== null) {
      navigate(nextPage);
    }
  }, [nextPage, navigate]);
  const handleCloseUserMenu = (action) => {
    if (action !== "none") {
      setNextPage(action);
    }
  };
  const KycStatus = props.userdata?.kyc_status;
  return (
    <>
      {KycStatus !== "verified" ? (
        <Navigate to="../Home" />
      ) : (
        <div className="login_wrapper">
          <Container maxWidth="lg">
            {status === 400 && (
              <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                Please enter correct information.
              </Alert>
            )}
            {status === 422 && (
              <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                Your request could not be processed.
              </Alert>
            )}
            {status === 403 && (
              <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                You are not authorised to perform this action.
              </Alert>
            )}
            {status === 500 && (
              <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                Internal Server Error
              </Alert>
            )}
            <Box sx={{ p: "20px", mt: "1%" }} className="shadow-2xl">
              <p
                style={{
                  paddingTop: "25px",
                  paddingBottom: "45px",
                  textAlign: "center",
                  fontSize: "32px",
                }}
              >
                Transfer
              </p>
              <Divider />
              <div
                style={{ padding: "25px" }}
                className="flex flex-row justify-between items-center w-1/2 hover:bg-gray-100 hover:cursor-pointer"
                onClick={() => handleCloseUserMenu("/wire-transfer-instruction")}
              >
                <ListItemIcon sx={{ display: "contents" }}>
                  <img
                    style={{
                      width: "40px",
                      height: "40px",
                      float: "left",
                      marginLeft: "10%",
                    }}
                    src="/images/favicon.png"
                    alt=""
                  ></img>
                </ListItemIcon>
                <div> Transfer to coinspaze </div>
                <ListItemIcon
                  // href= {"/wire-transfer-instruction"}
                  sx={{ display: "contents" }}
                >
                  <ArrowForwardIosIcon
                    className=""
                    sx={{ marginRight: "10%", float: "right", marginTop: "15px" }}
                    fontSize="small"
                  />
                </ListItemIcon>
              </div>
              <Divider />
              <div
                style={{ padding: "25px" }}
                className="flex flex-row justify-between items-center w-1/2 hover:bg-gray-100 hover:cursor-pointer"
                onClick={() => handleCloseUserMenu("/disbursement-account")}
              >
                <ListItemIcon sx={{ display: "contents" }}>
                  <TuneIcon
                    sx={{
                      width: "40px",
                      height: "40px",
                      float: "left",
                      marginLeft: "10%",
                    }}
                    fontSize="small"
                  />
                </ListItemIcon>
                <div> Transfer to your bank </div>
                <ListItemIcon sx={{ display: "contents" }}>
                  <ArrowForwardIosIcon
                    className="copy_btn"
                    sx={{ marginRight: "10%", float: "right", marginTop: "15px" }}
                    fontSize="small"
                  />
                </ListItemIcon>
              </div>
              <Divider />
              {cashBalanceResponse?.length > 0 && (
                <div>
                  <p
                    style={{
                      paddingTop: "45px",
                      marginLeft: "15%",
                      textAlign: "left",
                      fontSize: "24px",
                    }}
                  >
                    Cash Balance
                  </p>
                  <p style={{ textAlign: "left", marginLeft: "20%" }}>Settled : $ {cashBalanceResponse[0].settled}</p>
                  <p style={{ textAlign: "left", marginLeft: "20%" }}>
                    Withdrawable : $ {cashBalanceResponse[0].disbursable}
                  </p>
                  <p style={{ textAlign: "left", marginLeft: "20%" }}>
                    Pending : $ {cashBalanceResponse[0].pending_transfer}
                  </p>
                </div>
              )}
              <Divider />
              {bankAccounts?.length > 0 && <div className="text-center text-2xl my-4 h1">Linked Bank Accounts</div>}
              <div className=" md:grid-rows-auto container mx-auto grid max-w-5xl justify-items-center p-0 opacity-100 gap-y-2 md:grid-cols-2 md:gap-y-8 md:gap-x-16 lg:grid-cols-3 lg:grid-rows-2 lg:gap-y-2 lg:gap-x-32">
                {bankAccounts &&
                  bankAccounts.map((item, index) => {
                    return (
                      <Card sx={{ minWidth: 275, border: 1 }} key={index}>
                        <CardContent>
                          <Typography sx={{bgcolor: 'gray', color:'white', px:1}} component={"span"}>
                            {item?.funds_transfer_type !== "credit_card" && item?.funds_transfer_type?.toUpperCase()}
                            {item?.funds_transfer_type === "credit_card" && "CREDIT CARD"}
                          </Typography>
                          <div>
                            <span className="font-semibold">Account Name:</span> {item.bank_account_name}
                          </div>
                          <div>
                            <span className="font-semibold">Account Number:</span> {"*****" + item.last4}
                          </div>
                          <div>
                            <span className="font-semibold">Bank Account Type:</span> {item.bank_account_type}
                          </div>
                        </CardContent>
                      </Card>
                    );
                  })}
              </div>
            </Box>
          </Container>
        </div>
      )}
    </>
  );
};
const mapStatetoProps = (state) => {
  return {
    token:state.auth.token, 
    cashBalanceResponse: state.payment.cashBalanceResponse,
    bankAccounts: state.payment.bankAccounts,
    userdata: state.auth.userdata,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    requestCASH: function (token) {
      dispatch(getCASH(token));
    },
    callGetPaymentMethods: function (token) {
      dispatch(getPaymentMethods(token));
    },
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(Transfer);

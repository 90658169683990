export const initialState = {
  helpResponse: null,
  helpResponseStatus: null,
  status: null,
  invitationResponseStatus: null,
  invitationResponse : null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
      case "GET_MFA_SUCCESS":
        return {
          ...state,
          status: action.payload.status,
          mfaSuccessResponse: action.payload.data,
          msg: "GET_MFA_SUCCESS"
        }
      case "GET_MFA_FAIL":
        return {
          ...state,
          status: action.payload.status,
          mfaFailResponse: action.payload.data,
          msg: "GET_MFA_FAIL"
        }     
        case "GET_HELP_SUCCESS":
          return {
            ...state,
            helpResponseStatus: action.payload.status,
            helpResponse: action.payload.data,
            msg: "GET_HELP_SUCCESS"
          }     
        case "GET_HELP_FAIL":
          return {
            ...state,
            helpResponseStatus: action.payload.status,
            helpResponse: action.payload.data,
            msg: "GET_HELP_FAIL"
          } 
        case "RESET_HELP_STATUS":
        return {
          state
        } 
        case "RESET_STATUS":
        return {
          state,
          msg: "RESET_STATUS"
        }
        case "SEND_INVITATION_RESPONSE" :
        return {
          ...state,
          invitationResponseStatus: action.payload.status,
          invitationResponse: action.payload
        } 
    default:
      return state;
  }
}

import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { BackButton } from "../BackButton";

function documentType(props) {
  return (
    <div className="login_wrapper">
      <div className="mt-32 mx-36">

        <div className="doc_sec">
          <div className="doc_content">
            <div className="">
              <h2>Choose your document type</h2>
              <p className="mt-8">
                We need to determine if the identity document is authentic and
                belongs you
              </p>
            </div>
            <div className="flex gap-36 justify-center mt-20">
            <div className="doc_type_box flx">
              <NavLink to="/upload-document?type=drivers_license">
                <img src="images/ID.png"  alt="drivers_license"/>
                <h4>Drivers License</h4>
              </NavLink>
            </div>
            <div className="doc_type_box">
              <NavLink to="/upload-document?type=other">
                <img src="images/ID.png" alt="other"/>
                <h4>Identity Card</h4>
              </NavLink>
            </div>
            <div className="doc_type_box">
              <NavLink to="/upload-document?type=utility-bill">
                <img src="images/ID.png" alt="other"/>
                <h4>Utility Bill</h4>
              </NavLink>
            </div>
            </div>
          </div>
        </div>
        <div className="mt-8 text-center">
        <BackButton path={"/"}/>
        </div>
      </div>
    </div>
  );
}


const mapStatetoProps = (state) => {
  return {
    token: state.auth.token,
    msg: state.auth.msg,
    status: state.auth.status,
    userdata: state.auth.userdata,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    LoginWithTempDetails: function () {
      //dispatch(getAgreement());
    },
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(documentType);

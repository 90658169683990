import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { createBrowserHistory } from "history";
import { getDetailedProfileNextStep, getIsSystemKycInprogress } from "../common/Utils";
import { getPricesHistory, requestSummary, getAccounts } from "../../redux/services/homeServices";
import { getProfile } from "../../redux/services/authServices";
import { getCASH } from "../../redux/services/paymentServices";
import { isNil, path, isEmpty } from "ramda";
import {
  Button,
  Container,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  Avatar,
  Skeleton,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { SkeletonComponent } from "../Skeleton";
import { setToken } from "../../redux/action/authAction";

export const browserHistory = createBrowserHistory();

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Home = (props) => {
  const navigate = useNavigate();
  const [currencyList, setCurrencyList] = useState([]);
  const {
    token,
    callRequestSummary,
    userPortfolioSummary,
    userPortfolioSummaryStatus,
    callGetAccounts,
    userAccounts,
    callPriceHistory,
    callGetProfile,
    requestCASH,
    userAccountsStatus,
    userdata,
    cashBalanceResponse,
    cashBalanceResponseStatus,
    callSetToken,
  } = props;
  const [summary, setSummary] = useState({});
  const [cashBalance, setCashBalance] = useState({});
  const [cryptoEntries, setCryptoEntries] = useState([]);
  const { user, getAccessTokenSilently } = useAuth0();
  const [jwtToken, setJwtToken] = useState(null);

  const [isAuthorizeForTrade, setIsAuthorizeForTrade] = useState();
  const [kycStatus, setKycStatus] = useState();
  const [isSystemKycInprogress, setIsSystemKycInprogress] = useState();
  useEffect(() => {
    if (userdata) {
      setIsSystemKycInprogress(getIsSystemKycInprogress(userdata));

      const getIsAuthorizeForTrade =
        userdata.kyc_status === "not_started" ||
        userdata.kyc_status === "draft" ||
        userdata.kyc_status === "pending" ||
        userdata.kyc_status === "require_action"
          ? false
          : true;

      setIsAuthorizeForTrade(getIsAuthorizeForTrade);
      setKycStatus(userdata.kyc_status);
    }
  }, [userdata]);

  useEffect(() => {
    if (userPortfolioSummary !== null) {
      setSummary(userPortfolioSummary);
    }
  }, [userPortfolioSummary]);

  useEffect(() => {
    if (userAccounts && userAccounts.crypto_entries) {
      setCryptoEntries(userAccounts.crypto_entries);
    }
  }, [userAccounts]);

  useEffect(() => {
    if (Object.keys(props.priceHistory).length !== 0) {
      setCurrencyList(props.priceHistory);
    }
  }, [props.priceHistory]);

  useEffect(() => {
    if (cashBalanceResponse && cashBalanceResponse?.length > 0) {
      setCashBalance(cashBalanceResponse[0]);
    }
  }, [cashBalanceResponse]);

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `coinspaze-api`,
        });
        callSetToken(accessToken);
        //localStorage.setItem("token", accessToken);
        //await callPriceHistory(accessToken);
        setJwtToken(accessToken);
        if (jwtToken) {
          callGetProfile(token);
          callPriceHistory(token);
          callRequestSummary(token);
          callGetAccounts(token);
          requestCASH(token);
        }
      } catch (e) {
        navigate("/");
      }
    };
    getUserMetadata();
  }, [
    getAccessTokenSilently,
    jwtToken,
    callGetAccounts,
    callGetProfile,
    callPriceHistory,
    callRequestSummary,
    requestCASH,
  ]);

  const trade = (coin) => {
    navigate(`/crypto/${coin}`);
  };

  const authorizeCoinSpaze = () => {
    let nextStep = getDetailedProfileNextStep(userdata);
    navigate(nextStep.navigateTo);
  };

  const formatMoney = (num) => {
    return `$${(Math.round(num * 100) / 100).toFixed(2)}`;
  };

  const generateList = () => {
    if (Object.keys(currencyList).length !== 0) {
      return Object.keys(currencyList).map((key, index) => {
        return (
          <Fragment key={index}>
            <ListItem
              sx={{ borderColor: "primary.borderColor" }}
              button={true}
              divider={true}
              onClick={() => trade(`${currencyList[key].currency}`)}
            >
              <ListItemAvatar>
                <Avatar src={`./images/coin-icons/${path([key, "icon_file_name"], currencyList)}.png`} size="40" />
              </ListItemAvatar>
              <ListItemText
                primary={`${currencyList[key].currency}`}
                secondary={`${path([key, "percentage_change"], currencyList)}`}
              />
              <ListItemText
                style={{ display: "flex", justifyContent: "flex-end" }}
                primary={`$${path([key, "current_price"], currencyList)}`}
              />
            </ListItem>
            <Divider />
          </Fragment>
        );
      });
    } else {
      return <SkeletonComponent />;
    }
  };
  const userPortfolio = () => {
    if (!isEmpty(cryptoEntries) && !isEmpty(currencyList)) {
      return Object.keys(cryptoEntries).map((key, index) => {
        const logo = `${cryptoEntries[key].name.toLowerCase()}-${cryptoEntries[key].type.toLowerCase()}-logo`;
        const type = cryptoEntries[key].type;
        const priceInfo = currencyList[type];

        return (
          <Fragment key={index}>
            <ListItem button={true} divider={true} onClick={() => trade(`${cryptoEntries[key].currency.code}`)}>
              <ListItemAvatar>
                <Avatar src={`./images/coin-icons/${logo}.png`} size="40" />
              </ListItemAvatar>
              <ListItemText primary={`${cryptoEntries[key].type}`} secondary={`$${priceInfo.current_price}`} />
              <ListItemText
                style={{ display: "flex", justifyContent: "flex-end" }}
                primary={path([key, "balance", "amount"], cryptoEntries)}
              />
            </ListItem>
            <Divider />
          </Fragment>
        );
      });
    } else if (userAccountsStatus === 200) {
      return;
    } else {
      return (
        <Stack spacing={1} className="mt-12">
          <Skeleton variant="rectangular" width={"100%"} height={60} />
          <Skeleton variant="rectangular" width={"100%"} height={60} />
          <Skeleton variant="rectangular" width={"100%"} height={60} />
          <Skeleton variant="rectangular" width={"100%"} height={60} />
        </Stack>
      );
    }
  };

  return (
    <>
      <Container maxWidth={"lg"} sx={{ marginTop: 6 }}>
        {isAuthorizeForTrade === false && isSystemKycInprogress === false && (
          <Alert
            severity="warning"
            action={
              <Button variant="contained" size="small" onClick={authorizeCoinSpaze}>
                Please Complete your KYC
              </Button>
            }
            sx={{ mt: 5, mb: 5 }}
          >
            Please complete your KYC
          </Alert>
        )}
        {isSystemKycInprogress && (
          <Alert severity="warning" sx={{ mt: 5, mb: 5 }}>
            {`Your Kyc check is ${kycStatus}. Please try after some time`}
          </Alert>
        )}
        <div>
          <div className="flex flex-col md:flex-row items-center md:gap-[30%]">
            <div>
              <Typography variant={"h6"}>Crypto Balance</Typography>
              {userPortfolioSummaryStatus >= 200 && userPortfolioSummaryStatus < 300 ? (
                <Typography variant={"h3"}>
                  {!isNil(path(["balance", "amount"], summary))
                    ? formatMoney(path(["balance", "amount"], summary))
                    : formatMoney(0)}
                </Typography>
              ) : userPortfolioSummaryStatus >= 400 && userPortfolioSummaryStatus < 600 ? (
                <Typography>{"Unable to Retrieve Crypto Balance"}</Typography>
              ) : (
                <Skeleton variant="rectangular" width={210} height={60} />
              )}
            </div>
            <div>
              <Typography variant={"h6"}>Cash Balance</Typography>
              {cashBalanceResponseStatus >= 200 && cashBalanceResponseStatus < 300 ? (
                cashBalanceResponse?.length > 0 ? (
                  !isNil(path(["disbursable"], cashBalance)) ? (
                    <Typography variant={"h3"}>
                      {!isNil(path(["disbursable"], cashBalance))
                        ? formatMoney(cashBalance.disbursable)
                        : formatMoney(0)}
                    </Typography>
                  ) : (
                    <Typography variant={"h3"}>{formatMoney(0)}</Typography>
                  ) // disbursable field not exist
                ) : (
                  <Typography variant={"h3"}>{formatMoney(0)}</Typography>
                ) //cash balance array length is zero
              ) : cashBalanceResponseStatus >= 400 && cashBalanceResponseStatus < 600 ? (
                <Typography>{"Unable to Retrieve Cash Balance"}</Typography>
              ) : (
                <Skeleton variant="rectangular" width={210} height={60} />
              )}
            </div>
          </div>

          <Stack spacing={4}>
            <List
              style={{ backgroundColor: "paper", borderRadius: "20px" }}
              // subheader={"Your Portfolio"}
            >
              {userPortfolio()}
            </List>
            <List style={{ backgroundColor: "paper", borderRadius: "20px" }}>{generateList()}</List>
          </Stack>
        </div>
      </Container>
    </>
  );
};

const mapStatetoProps = (state) => {
  return {
    token: state.auth.token,
    msg: state.auth.msg,
    status: state.auth.status,
    userdata: state.auth.userdata,
    priceHistory: state.home.priceHistory,
    userPortfolioSummary: state.home.userPortfolioSummary,
    userPortfolioSummaryStatus: state.home.userPortfolioSummaryStatus,
    userAccounts: state.home.userAccounts,
    userAccountsStatus: state.home.status,
    cashBalanceResponse: state.payment.cashBalanceResponse,
    cashBalanceResponseStatus: state.payment.cashBalanceResponseStatus,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    callPriceHistory: function (token) {
      dispatch(getPricesHistory(token));
    },
    callRequestSummary: function (token) {
      dispatch(requestSummary(token));
    },
    callGetAccounts: function (token) {
      dispatch(getAccounts(token));
    },
    callGetProfile: function (token) {
      dispatch(getProfile(token));
    },
    requestCASH: function (token) {
      dispatch(getCASH(token));
    },
    callSetToken: function(token) {
      dispatch(setToken(token));
    },
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(Home);

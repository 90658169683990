import { Box } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

const UserProfile = ({ userdata }) => {
  const { first_name, middle_name, last_name, email, address_one, address_two, city, state, country, phone_number, date_of_birth, zip } = userdata;

  return (
    <Box sx={{ p: "20px", mt: "1%" }} className="flex justify-center items-center shadow-2xl w-full md:w-1/2 lg:w-2/3">
      <div>
        <div className="flex justify-center text-2xl mb-4 font-bold">Profile</div>
        <TableContainer >
          <Table size="small" aria-label="a dense table">
            <TableBody>
              {first_name && (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      border: 0,
                    }}
                    className="font-semibold"
                  >
                    First Name
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      border: 0,
                    }}
                    className="text-[#eee]"
                  >
                    {first_name}
                  </TableCell>
                </TableRow>
              )}
              {middle_name && (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      border: 0,
                    }}
                    className="font-semibold"
                  >
                    Middle Name
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      border: 0,
                    }}
                    className="text-[#eee]"
                  >
                    {middle_name}
                  </TableCell>
                </TableRow>
              )}
              {last_name && (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      border: 0,
                    }}
                    className="font-semibold"
                  >
                    Last Name
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      border: 0,
                    }}
                    className="text-[#eee]"
                  >
                    {last_name}
                  </TableCell>
                </TableRow>
              )}
              {email && (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      border: 0,
                    }}
                    className="font-semibold"
                  >
                    Email
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      border: 0,
                    }}
                  >
                    {email}
                  </TableCell>
                </TableRow>
              )}
              {date_of_birth && (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      border: 0,
                    }}
                    className="font-semibold"
                  >
                    Date of Birth
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      border: 0,
                    }}
                  >
                    {moment(date_of_birth).format("DD-MM-YYYY")}
                  </TableCell>
                </TableRow>
              )}
              {phone_number && (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      border: 0,
                    }}
                    className="font-semibold"
                  >
                    Phone Number
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      border: 0,
                    }}
                  >
                    {phone_number}
                  </TableCell>
                </TableRow>
              )}
              {address_one && (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      border: 0,
                    }}
                    className="font-semibold"
                  >
                    Address
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      border: 0,
                    }}
                  >
                    {address_one +
                      ", " +
                      (address_two ? address_two + ", " : "") +
                      city +
                      ", " +
                      state +
                      ", " +
                      zip +
                      ", " +
                      country}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Box>
  );
};

const mapStatetoProps = (state) => {
  return {
    userdata: state.auth.userdata,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {};
};
export default connect(mapStatetoProps, mapDispatchtoProps)(UserProfile);

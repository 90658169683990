import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { uploadDocs } from "../../redux/services/kycServices";
import { Box } from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import { Alert, AlertTitle, Button, Checkbox, FormControlLabel, MenuItem, TextField } from "@mui/material";

import { resetKycState } from "../../redux/action/kycAction";
import { getProfile } from "../../redux/services/authServices";
import { getCustomDocument, generateUUID, getKycRequiredAction } from "../common/Utils";
import { validIdProofs } from "../common/Constants";

function UploadDocument(props) {
  const {
    token,
    callGetProfile,
    callReset,
    userdata,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentState = useSelector((state) => state.kyc);

  let [searchParams] = useSearchParams();

  const [isIdProof, setIsIdProof] = useState(false);
  const [isAddressProof, setIsAddressProof] = useState(false);
  const [docName, setDocName] = useState("default");
  const [docId, setDocId] = useState();
  const [documentType, setDocumentType] = useState();

  const [selectedFrontFile, setSelectedFrontFile] = useState();
  const [isFrontFilePicked, setIsFrontFilePicked] = useState(false);

  const [selectedBackFile, setSelectedBackFile] = useState();
  const [isBackFilePicked, setIsBackFilePicked] = useState(false);

  const [isActionRequire, setIsActionRequire] = useState(false);
  const [requireActionList, setRequireActionList] = useState();
  const [disabled, setDisable] = useState(false);

  useEffect(() => {
    if (searchParams.get("type") !== undefined) {
      setDocumentType(searchParams.get("type"));
      setDocId(generateUUID(10));
    }
  }, [searchParams]);

  useEffect(() => {
    let kycRequiredActionState = getKycRequiredAction(userdata);
    setIsActionRequire(kycRequiredActionState.isActionRequire);
    if (isActionRequire) {
      setRequireActionList(kycRequiredActionState.requireActionList);
    }
  }, [userdata, isActionRequire]);

  useEffect(() => {
    if (
      currentState.status === 200 &&
      currentState.msg === "REDIRECT_UPLOAD_DOCUMENTS_SUCCESS"
    ) {
      callGetProfile(token);
      callReset();
      if (isAddressProof) {
        navigate("/home");
      } else {
        navigate("/upload-address-document");
      }
    }
  }, [currentState, callGetProfile, callReset, isAddressProof, navigate]);

  function handleNext() {
    const formData = new FormData();

    formData.append("is_id_proof", isIdProof);
    formData.append("is_address_proof", isAddressProof);
    formData.append("document_name", docId);
    formData.append("document_type", documentType);
    formData.append("front_doc", selectedFrontFile);
    formData.append("back_doc", selectedBackFile);
    
    setDisable(true);
    dispatch(uploadDocs(formData, token));

  }

  const changeHandler = (event, type) => {
    if (event.length > 0 && type === "front_doc") {
      const custom_file = getCustomDocument(event[0], documentType, docId);
      setSelectedFrontFile(custom_file);
      setIsFrontFilePicked(true);
      //Patch need permanent BE fix
      if (documentType === "passport") {
        setSelectedBackFile(custom_file);
        setIsBackFilePicked(true);
      }
    }

    if (event.length > 0 && type === "back_doc") {
      const custom_file_2 = getCustomDocument(event[0], documentType, docId);
      setSelectedBackFile(custom_file_2);
      setIsBackFilePicked(true);
    }
  };

  const handleIsValidAddressProof = (event, type) => {
    setIsAddressProof(event.target.checked);
  };

  function handleChange(event) {
    const { value } = event.target;
    setDocName(value);
    setDocumentType(value);
    setIsIdProof(true);
  }

  return (
    <Box className="container md:px-auto md: mt-6">
      {isActionRequire && (
        <div className="mb-4 text-red">
          <Alert variant="outlined" severity="error">
            <AlertTitle>Error</AlertTitle>
            {requireActionList}
          </Alert>
        </div>
      )}
      <div className="flex flex-col items-center justify-center">
        <span className="text-lg md:text-2xl text-center">Upload Govt Issued Photo ID</span>
        <p className="text-xs md:text-base md:mt-8 text-center">
          We need to determine if the identity document is authentic and belongs you
        </p>
      </div>
      <div className="text-center mt-2 md:mt-10">
        <TextField
          id="outlined-select-currency"
          select
          label="Select"
          value={docName}
          onChange={handleChange}
          helperText="Please select your document type"
          size="small"
        >
          {validIdProofs.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className="flex flex-col md:flex md:flex-row md:justify-center w-full  md:mt-8 px-4 gap-6 mt-4">
        <Box className="md:basis-[48%]">
          <Box>
            <Box className="text-center md:mb-2 md:text-lg">Front</Box>
            <DropzoneArea
              onChange={(event) => changeHandler(event, "front_doc")}
              onDelete={() => setIsFrontFilePicked(false)}
              onAlert={(message, variant) => console.log(`${variant}: ${message}`)}
              acceptedFiles={["image/*"]}
              // onChange={}
              filesLimit={1}
            />
          </Box>
        </Box>
        <Box className="md:basis-[48%]">
          <Box>
            <Box className="text-center md:mb-2 md:text-lg">Back</Box>
            <DropzoneArea
              onChange={(event) => changeHandler(event, "back_doc")}
              onDelete={() => setIsBackFilePicked(false)}
              onAlert={(message, variant) => console.log(`${variant}: ${message}`)}
              acceptedFiles={["image/*"]}
              // onChange={changeHandler}
              filesLimit={1}
            />
          </Box>
        </Box>
      </div>
      <div className="text-center mt-2">
        <FormControlLabel
          control={<Checkbox checked={isAddressProof} onChange={handleIsValidAddressProof} />}
          label="Use my ID proof as the proof of address"
        />
      </div>
      <div className="text-center my-16">
        <Button
          color="primary"
          className="px-4 py-2"
          disabled={
            (documentType === "passport" && (!isFrontFilePicked || !isIdProof)) ||
            (documentType !== "passport" &&
              (!isFrontFilePicked || !isBackFilePicked || !isIdProof || docName === "default")) || 
              disabled
          }
          variant="contained"
          onClick={handleNext}
        >
          Upload
        </Button>
      </div>
    </Box>
  );
}

const mapStatetoProps = (state) => {
  return {
    token: state.auth.token,
    msg: state.auth.msg,
    status: state.auth.status,
    userdata: state.auth.userdata,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    uploadDocuments: function (email, password) {
      //dispatch(getAgreement());
    },
    callGetProfile: function (token) {
      dispatch(getProfile(token));
    },
    callReset: function () {
      dispatch(resetKycState());
    },
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(UploadDocument);

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { resetHomeState } from "../../redux/action/homeAction";
import { createBrowserHistory } from "history";
import { orderConfirm, sell } from "../../redux/services/homeServices";
import {
  Card,
  Box,
  CardActions,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  CardContent,
  TextField,
  Stack,
} from "@mui/material";

export const browserHistory = createBrowserHistory();
const currencies = [
  {
    value: "USD",
    label: "USD",
  },
];

const OrderPreview = (props) => {
  const [currencyList, setCurrencyList] = useState([]);
  const {
    token,
    userdata,
    orderPreviewDetails,
    callOrderConfirm,
    previewSellSuccessResponse,
    orderFailResponse,
    callSell,
    callReset,
    sellFailResponse,
  } = props;
  const [coinDetail, setCoinDetail] = useState();
  const [result, setResult] = useState([["Time", "Price"]]);
  const [orderCurrency, setOrderCurrency] = useState([...currencies]);
  let { coinId } = useParams();
  const [orderType, setOrderType] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleOrderType = (event, newAlignment) => {
    setOrderType(newAlignment);
  };

  useEffect(() => {
    if (orderPreviewDetails) {
      setOrderType("buy");
    } else {
      setOrderType("sell");
    }
  }, [orderType, orderPreviewDetails, previewSellSuccessResponse]);

  useEffect(() => {
    if (orderFailResponse) {
      setErrorMessage(orderFailResponse);
    }

    if (sellFailResponse) {
      setErrorMessage(sellFailResponse);
    }
  }, [orderFailResponse, sellFailResponse]);

  useEffect(() => {
    const tempOrderInCurrency = {
      value: coinId,
      label: coinId,
    };
    setOrderCurrency([...orderCurrency, tempOrderInCurrency]);
  }, [userdata, coinId]);

  useEffect(() => {
    if (Object.keys(props.priceHistory).length !== 0) {
      setCurrencyList(props.priceHistory);
      const tempCoinDetail = currencyList[coinId];
      setCoinDetail(tempCoinDetail);
    }
  }, [props.priceHistory, currencyList, coinDetail]);

  useEffect(() => {
    const chartData = [];
    if (coinDetail) {
      for (var i in coinDetail.price_history)
        chartData.push([
          new Date(coinDetail.price_history[i].time),
          coinDetail.price_history[i].price,
        ]);
      setResult([...result, ...chartData]);
    }
  }, [coinDetail]);

  const placeOrder = () => {
    if (orderPreviewDetails) {
      callOrderConfirm({ quote_id: orderPreviewDetails.quote_id}, token);
    }

    if (previewSellSuccessResponse) {
      callSell({ quote_id: previewSellSuccessResponse.quote_id}, token);
    }
  };

  const onCancel = () => {
    callReset();
  };

  return (
    <Box>
      {orderPreviewDetails && (
        <Card>
          <CardActions>
            <ToggleButtonGroup
              value={orderType}
              exclusive
              aria-label="order type"
              fullWidth={true}
            >
              <ToggleButton
                value="buy"
                size="large"
                aria-label="buy order"
                readOnly={true}
              >
                Buy
              </ToggleButton>
              <ToggleButton
                value="sell"
                size="large"
                aria-label="sell order"
                readOnly={true}
              >
                Sell
              </ToggleButton>
            </ToggleButtonGroup>
          </CardActions>
          <CardContent component={Stack} spacing={3}>
            {errorMessage && <Box size="medium">{errorMessage.message}</Box>}
            <TextField
              value={`Buy ${orderPreviewDetails.quantity.currency}`}
              id="outlined-required"
              label="Order Type"
              placeholder="Quantity"
              variant="outlined"
              readOnly={true}
              fullWidth={true}
            />
            <TextField
              type="number"
              InputProps={{
                inputProps: { min: 0.0 },
              }}
              value={orderPreviewDetails.quantity.amount}
              id="outlined-required"
              label="Quantity"
              placeholder="Quantity"
              variant="outlined"
              readOnly={true}
              fullWidth={true}
            />
            <TextField
              value={`$${orderPreviewDetails.unit_price.amount}`}
              id="outlined-required"
              label="Price"
              placeholder="Price"
              variant="outlined"
              readOnly={true}
              fullWidth={true}
            />
            <TextField
              InputProps={{
                inputProps: { min: 0.0 },
              }}
              value={`$${orderPreviewDetails.paid_fee.amount}`}
              label="CoinSpaze Fee"
              placeholder="CoinSpaze Fee"
              variant="outlined"
              readOnly={true}
              fullWidth={true}
            />
            <TextField
              value={`$${(
                Math.round(orderPreviewDetails.order_total.amount * 100) / 100
              ).toFixed(2)}`}
              id="outlined-required"
              label="Total"
              placeholder="Total"
              variant="outlined"
              readOnly={true}
              fullWidth={true}
            />
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              fullWidth={true}
              size="medium"
              onClick={placeOrder}
            >
              Place Order
            </Button>
          </CardActions>
          <CardActions>
            <Button
              variant="outlined"
              fullWidth={true}
              size="medium"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </CardActions>
        </Card>
      )}
      {previewSellSuccessResponse && (
        <Card>
          <CardActions>
            <ToggleButtonGroup
              value={orderType}
              exclusive
              onChange={handleOrderType}
              aria-label="order type"
              fullWidth={true}
            >
              <ToggleButton
                value="buy"
                size="large"
                aria-label="buy order"
                readOnly={true}
              >
                Buy
              </ToggleButton>
              <ToggleButton
                value="sell"
                size="large"
                aria-label="sell order"
                readOnly={true}
              >
                Sell
              </ToggleButton>
            </ToggleButtonGroup>
          </CardActions>
          <CardContent component={Stack} spacing={3}>
            {errorMessage && <Box size="medium">{errorMessage.message}</Box>}
            <TextField
              value={`Sell ${previewSellSuccessResponse.quantity.currency}`}
              id="outlined-required"
              label="Order Type"
              placeholder="Quantity"
              variant="outlined"
              readOnly={true}
            />
            <TextField
              type="number"
              InputProps={{
                inputProps: { min: 0.0 },
              }}
              value={previewSellSuccessResponse.quantity.amount}
              id="outlined-required"
              label="Quantity"
              placeholder="Quantity"
              variant="outlined"
              readOnly={true}
            />
            <TextField
              value={`$${previewSellSuccessResponse.unit_price.amount}`}
              id="outlined-required"
              label="Price"
              placeholder="Price"
              variant="outlined"
              readOnly={true}
            />
            <TextField
              InputProps={{
                inputProps: { min: 0.0 },
              }}
              value={`$${previewSellSuccessResponse.order_total.amount}`}
              label="Order Total"
              placeholder="Order Total"
              variant="outlined"
              readOnly={true}
            />
            <TextField
              InputProps={{
                inputProps: { min: 0.0 },
              }}
              value={`$${previewSellSuccessResponse.paid_fee.amount}`}
              label="CoinSpaze Fee"
              placeholder="CoinSpaze Fee"
              variant="outlined"
              readOnly={true}
            />
            <TextField
              value={`$${(
                Math.round(
                  previewSellSuccessResponse.paid_subtotal.amount * 100
                ) / 100
              ).toFixed(2)}`}
              id="outlined-required"
              label="Total"
              placeholder="Total"
              variant="outlined"
              readOnly={true}
            />
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              fullWidth={true}
              size="medium"
              onClick={placeOrder}
            >
              Place Order
            </Button>
          </CardActions>
          <CardActions>
            <Button
              variant="outlined"
              fullWidth={true}
              size="medium"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </CardActions>
        </Card>
      )}
    </Box>
  );
};

const mapStatetoProps = (state) => {
  return {
    token: state.auth.token,
    msg: state.auth.msg,
    status: state.auth.status,
    userdata: state.auth.userdata,
    priceHistory: state.home.priceHistory,
    userPortfolioSummary: state.home.userPortfolioSummary,
    orderPreviewDetails: state.home.orderPreviewDetails,
    previewSellSuccessResponse: state.home.previewSellSuccessResponse,
    orderFailResponse: state.home.orderFailResponse,
    sellFailResponse: state.home.sellFailResponse,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    callOrderConfirm: function (data, token) {
      dispatch(orderConfirm(data, token));
    },
    callSell: function (data, token) {
      dispatch(sell(data, token));
    },
    callReset: function () {
      dispatch(resetHomeState());
    },
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(OrderPreview);

import React, {useEffect, useState} from "react";

import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import {Box, Button, Divider, Grid, Typography, ImageList, ImageListItem, Snackbar} from "@mui/material";
import { getADDRESS } from "../../redux/services/paymentServices";
import {CoinSpazePdfFromReact, CoinSpazePdfFromReactDownload} from "./CoinSpazePdfFromReact";
import {createInvoice, uploadInvoice} from '../../redux/services/invoiceServices';
import {resetInvoiceState, openDialog} from '../../redux/action/invoiceAction';


const PreviewInvoice = (props) => {
  const {userdata, invoice, createInvoiceRequest, requestADDRESS, requestPostInvoice, requestUploadInvoice, postInvoiceResponse, uploadInvoiceResponse, token, addressSuccessResponse} = props;
  const [selectedCoinOptions, setSelectedCoinOptions] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  
  useEffect(() => {
    if(token !== ""){
      requestADDRESS(token);
    }
  }, []);

  useEffect(() => {
    if(addressSuccessResponse && invoice){
      let temp = addressSuccessResponse.filter( option => invoice.selectedCoinOptions.includes(option.currency_code));
      setSelectedCoinOptions(temp);
    }
   }, [invoice, addressSuccessResponse])

   const handleSubmit =  async (data) =>{
      await CoinSpazePdfFromReact('#invoice-container', "p", true)
      .then(
        () => {
            const formData = new FormData();
            var customeFile = new File([localStorage.getItem("invoice-pdf-blob")], 'invoice', { type: 'application/pdf' });
            formData.append("doc", customeFile);
            formData.append("invoice_id", data?.id);
            requestUploadInvoice(formData, token); 
        }
      );
   }

   const postInvoice = () => {
      const invoiceRequest =  {
        ...createInvoiceRequest,
        profile_id:userdata.id
      };
      requestPostInvoice(invoiceRequest, token);
   }

   useEffect(() => {
    if(postInvoiceResponse && postInvoiceResponse.status === 200 && uploadInvoiceResponse === undefined){
      handleSubmit(postInvoiceResponse.data);
    } else if (postInvoiceResponse && postInvoiceResponse.status !== 200){
      handleCancel();
    } else if (uploadInvoiceResponse && uploadInvoiceResponse.status === 200){
      navigate('/invoices'); 
    }
  }, [postInvoiceResponse, uploadInvoiceResponse, handleSubmit]);

  const handleCancel = () => {
    dispatch(openDialog(false));
  }
  
  const downlodPdf =  async () =>{
    await CoinSpazePdfFromReactDownload('#invoice-container', "p", true)
    .then(
      () => {
          console.log('Invoice Generated'); 
      }
    );
 }

    const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <>
      <Box container id='invoice-container' sx= {{px:2, mt:2, border:0, borderColor: 'grey.500', width:600, minHeight:841}}>
        <Box sx={{mb:1, height:'20%', border:0}}>
          <Grid container spacing={12}>
            <Grid item xs={6} sx={{border:0}}>
              <Typography variant="body2" sx={{fontSize:10, fontWeight:'bold'}}>
                {userdata?.first_name} {userdata?.last_name}
              </Typography>
              <Typography variant="body2" sx={{fontSize:10}}>
              {userdata?.address_one}
              </Typography>
              <Typography variant="body2" sx={{fontSize:10}}>
                {userdata?.city}, {userdata?.state} {userdata?.zip} {userdata?.country}
              </Typography>
            </Grid>
              <Grid item xs={6} sx={{border:0, pr:2}}>
                <Box sx={{justifyContent: 'flex-end', display:'flex', mb:1}}>
                  <Typography variant="body2" sx={{fontSize:10, fontWeight:'bold'}}>
                      Invoice: #{invoice.invoiceId}
                  </Typography>   
                </Box>
                <Box sx={{justifyContent: 'flex-end', display:'flex'}}>
                <Typography variant="body2" sx={{fontSize:10, fontWeight:'bold'}}>
                  Issue Date: {invoice.issueDate}
                </Typography>
                </Box>
                {/*<Box sx={{justifyContent: 'flex-end', display:'flex'}}>
                <Typography variant="body2" sx={{fontSize:10}}>
                  {invoice.issueDate}
                </Typography>
                </Box>*/}
            </Grid>
          </Grid>
          <Divider variant="fullWidth" sx={{mt:1,borderBottomWidth: '5px', borderColor: 'grey.700' }}></Divider>
        </Box>
        
        <Box sx= {{mb:1, minhheight:'60%', border:0}}>
          <Typography variant="subtitle1" sx={{fontWeight:'bold'}}>
              Invoice #{invoice.invoiceId} {invoice.invoiceTitle}
              <Divider variant="fullWidth" sx={{ mt:1, mb:1, borderBottomWidth: '1px', borderColor: 'grey.300' }}></Divider>
          </Typography>
          <Box sx={{display:"flex", justifyContent: 'space-between'}}>
            <Grid container spacing={1}>
              <Grid item xs={8} sx={{border:0, pr:2}}>
                  <Box sx={{justifyContent: 'flex-start', display:'flex', mb:1}}>
                    <Typography variant="body2" sx={{fontSize:10, fontWeight:'bold'}}>
                        Bill To
                    </Typography>   
                  </Box>
                  <Box sx={{justifyContent: 'flex-start', display:'flex'}}>
                    <Typography variant="body2" sx={{fontSize:10}}>
                      {invoice.name}
                    </Typography>
                  </Box>
                  <Box sx={{justifyContent: 'flex-start', display:'flex'}}>
                    <Typography variant="body2" sx={{fontSize:10}}>
                      {invoice.email}, {invoice.phone}
                    </Typography>
                  </Box>
                  <Box sx={{justifyContent: 'flex-start', display:'flex'}}>
                    <Typography variant="body2" sx={{fontSize:10}}>
                      {invoice.addressone}
                    </Typography>
                  </Box>
                  <Box sx={{justifyContent: 'flex-start', display:'flex'}}>
                  <Typography variant="body2" sx={{fontSize:10}}>
                    {invoice.city} {invoice.zipcode} {invoice.state} {(invoice.city || invoice.zipcode || invoice.state) && invoice.country}
                  </Typography>
                  </Box>
              </Grid>
              { (invoice.shippingAddressone || invoice.shippingCity || invoice.shippingZipcode || invoice.shippingState) && <Grid item xs={4} sx={{border:0, pr:2}}>
                  <Box sx={{justifyContent: 'flex-start', display:'flex', mb:1}}>
                    <Typography variant="body2" sx={{fontSize:10, fontWeight:'bold'}}>
                        Ship To
                    </Typography>   
                  </Box>
                  <Box sx={{justifyContent: 'flex-start', display:'flex'}}>
                  <Typography variant="body2" sx={{fontSize:10}}>
                    {invoice.shippingAddressone}
                  </Typography>
                  </Box>
                  <Box sx={{justifyContent: 'flex-start', display:'flex'}}>
                  <Typography variant="body2" sx={{fontSize:10}}>
                    {invoice.shippingCity} {invoice.shippingZipcode} {invoice.shippingState} 
                    {(invoice.shippingCity || invoice.shippingZipcode || invoice.shippingState) && invoice.shippingCountry}
                  </Typography>
                  </Box>
              </Grid>}
            </Grid>
          </Box>
          <Box sx={{display:"flex", justifyContent: 'space-between', mt:1}}>
              <Grid container spacing={1}>
                <Grid item xs={8} sx={{border:0, pr:2}}>
                    <Box sx={{justifyContent: 'flex-start', display:'flex', mb:1}}>
                      <Typography variant="body2" sx={{fontSize:10, fontWeight:'bold'}}>
                          Payment
                      </Typography>   
                    </Box>
                    <Box sx={{justifyContent: 'flex-start', display:'flex'}}>
                      <Typography variant="body2" sx={{fontSize:10}}>
                        Due Date: {invoice.dueDate}
                      </Typography>
                    </Box>
                    <Box sx={{justifyContent: 'flex-start', display:'flex'}}>
                      <Typography variant="body2" sx={{fontSize:10}}>
                        Amount Due: ${invoice.total}
                      </Typography>
                    </Box>
                </Grid>
              </Grid>  
          </Box>
        <Divider variant="fullWidth" sx={{ mt:3,borderBottomWidth: '1px', borderColor: 'grey.300' }}></Divider>
        <Box sx={{mr:4, mt:1}}>
          <Grid container spacing={12}>
            <Grid item xs={6}>
            <Typography variant="subtitle1" sx={{fontWeight:'bold', fontSize:10}}>
              Item
            </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1" sx={{fontWeight:'bold', fontSize:10}}>
                Quantity
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1" sx={{fontWeight:'bold', fontSize:10}}>
                Price
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1" sx={{fontWeight:'bold', fontSize:10}}>
                Amount
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{mr:4, mt:1}}>
          <Grid container spacing={12}>
            <Grid item xs={6}>
            <Typography variant="subtitle1" sx={{fontSize:10}}>
              {invoice.item}
            </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1" sx={{fontSize:10}}>
              {invoice.quantity}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1" sx={{fontSize:10}}>
                ${invoice.price}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1" sx={{fontSize:10}}>
                ${invoice.itemTotal}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{mr:4, mt:1}}>
          <Grid container spacing={12}>
            <Grid item xs={8}>
            <Typography variant="subtitle1" sx={{fontSize:10}}>
              Tax
            </Typography>
            </Grid>
            <Grid item xs={2}>
            <Typography variant="subtitle1" sx={{fontSize:10}}>
              {invoice.taxPct}%
            </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1" sx={{fontSize:10}}>
                ${invoice.taxTotal}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{mr:4, mt:1}}>
          <Grid container spacing={12}>
            <Grid item xs={10}>
            <Typography variant="subtitle1" sx={{fontSize:10}}>
              Shipping
            </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1" sx={{fontSize:10}}>
                ${invoice.shippingTotal}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{mr:6, mt:1}}>
          <Grid container spacing={12}>
            <Grid item xs={10}>
            <Typography variant="subtitle1">
              Total Due
            </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1">
                ${invoice.total}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        </Box>

        <Box sx= {{mb:0, minHeight:'15%', border:0}}>
          <Typography variant="subtitle1" sx={{mb:1, fontWeight:'bold'}}>
            Payment
          </Typography>
          {/*<Box sx={{display:'flex'}}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <ImageList sx={{width: 122, height: 60, justifyContent: 'center'}}>
                  <ImageListItem>
                    <img
                      src='./images/sample-qr-code.png'
                      alt='qr code'
                      loading="eager"
                    />
                  </ImageListItem>
                </ImageList>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2" sx={{fontSize:10, fontWeight:'bold'}}>
                  Pay with CoinSpaze
                </Typography>
                <Typography variant="subtitle2" sx={{fontSize:10}}>
                  To pay your invice go to https://app.coinspaze.com/invoice/xgermdj
                </Typography>
                <Typography variant="subtitle2" sx={{fontSize:10}}>
                  Or open the camera on your mobile device and place the QR code in the camera's view.
                </Typography>
              </Grid>
            </Grid>
          </Box>*/}
            <Box sx={{pl:2, pb:2}}>
              <Typography variant="subtitle2" sx={{mb:4}}>
                Transfer from external wallet
              </Typography>
            </Box>
            <Box sx={{px:6}}>
              <Grid container spacing={2}>
                {selectedCoinOptions && selectedCoinOptions.map((coin) => {
                return (
                  <Grid item xs={6} sx={{border:.5, borderColor:'grey.500', mr:1,mb:1, boxShadow:1}}>
                    <ImageList sx={{width: '50%', height: 16, justifyContent: 'center'}}>
                      <ImageListItem>
                        <Typography variant="subtitle1" sx={{fontSize:8, fontWeight:'bold'}}>
                          Coin: {coin.currency_code}
                        </Typography>
                        {/*<img
                          src={coin.qr_code}
                          alt={coin.currency_code}
                          loading="eager"
                        />*/}
                      </ImageListItem>
                    </ImageList>
                    <Typography variant="subtitle2" sx={{fontSize:8, fontWeight:'bold', mb:2}}>
                      {coin.address}
                    </Typography>
                  </Grid>
                  )})}
                </Grid>
            </Box>
        </Box>
        <Box sx={{height:'2%',justifyContent: 'flex-end', display:'flex', pb:2, border:0}}>
          <Typography variant="subtitle2" sx={{fontSize:10}}>
            Powered by CoinSpaze
          </Typography>  
        </Box>
      </Box>
      <Box className="flex flex-row justify-center mx-auto gap-5 mb-5">
        <Button variant="outlined" onClick={handleCancel} className="mx-auto">
          Cancel
        </Button>
        <Button variant="contained" onClick={downlodPdf} className="mx-auto">
          Download PDF
        </Button>
        <Button variant="contained" onClick={postInvoice} className="mx-auto">
          Send Invoice
        </Button>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message="Invoice sent successfully."
      />  
    </>
  );
}

PreviewInvoice.propTypes = {
  invoice: PropTypes.any,
};

const mapStatetoProps = (state) => {
  return {
    userdata: state.auth.userdata,
    token:state.auth.token,
    addressSuccessResponse: state.payment.addressSuccessResponse,
    postInvoiceResponse: state.invoice.createInvoiceResponse,
    uploadInvoiceResponse: state.invoiceUpload.uploadInvoiceResponse,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    requestADDRESS: function (token) {
      dispatch(getADDRESS(token));
    },
    requestPostInvoice: function (data, token) {
      dispatch(createInvoice(data, token));
    },
    requestUploadInvoice: function (data, token) {
      dispatch(uploadInvoice(data, token));
    },
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(PreviewInvoice);

import React, {useEffect} from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {resetWalletState} from "../../redux/action/walletAction";

const TransferSuccess = (props) => {
  const { callReset } = props;

  useEffect(() => {
    return () => {
      callReset()
    }
  }, [])

  return (
    <Box
      component="span"
      sx={{
        width: 300,
        height: 300,
        backgroundColor: "primary.secondary",
        "&:hover": {
          backgroundColor: "primary.secondary",
          opacity: [0.9, 0.8, 0.7],
        },
        p: 2,
        border: "1px dashed grey",
      }}
      className="flex flex-col justify-center items-center text-green-800"
    >
      <CheckCircleOutlineIcon
        sx={{
          fontSize: "100px",
        }}
      />
      <div>Sent Successfully!</div>
    </Box>
  );
};

const mapStatetoProps = (state) => {
  return {};
};

const mapDispatchtoProps = (dispatch) => {
  return {
    callReset: function () {
      dispatch(resetWalletState());
    },
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(TransferSuccess);

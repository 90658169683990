const GET_WTI_SUCCESS = "GET_WTI_SUCCESS";
const GET_WTI_FAIL = "GET_WTI_FAIL";

const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS";
const GET_ADDRESS_FAIL = "GET_ADDRESS_FAIL";

const GET_CASH_SUCCESS = "GET_CASH_SUCCESS";
const GET_CASH_FAIL = "GET_CASH_FAIL";

const POST_PAYMENT_METHODS_SUCCESS = "POST_PAYMENT_METHODS_SUCCESS";
const POST_PAYMENT_METHODS_FAIL = "POST_PAYMENT_METHODS_FAIL";

const WITHDRAWAL_SUCCESS = "WITHDRAWAL_SUCCESS";
const WITHDRAWAL_FAIL = "WITHDRAWAL_FAIL";

const GET_PAYMENT_METHODS_SUCCESS = "GET_PAYMENT_METHODS_SUCCESS";
const GET_PAYMENT_METHODS_FAIL = "GET_PAYMENT_METHODS_FAIL";

const RESET_WITHDRAWAL_STATUS = "RESET_WITHDRAWAL_STATUS";

//WTI
export function getWTISuccess(response) {
  return {
    type: GET_WTI_SUCCESS,
    payload: response
  }
}

export function getWTIFail(response) {
  return {
    type: GET_WTI_FAIL,
    payload: response
  }
}
//CRYPTO_ADDRESS
export function getADDRESSSuccess(response) {
  return {
    type: GET_ADDRESS_SUCCESS,
    payload: response
  }
}

export function getADDRESSFail(response) {
  return {
    type: GET_ADDRESS_FAIL,
    payload: response
  }
}
export function getCASHSuccess(response) {
  return {
    type: GET_CASH_SUCCESS,
    payload: response
  }
}

export function getCASHFail(response) {
  return {
    type: GET_CASH_FAIL,
    payload: response
  }
}

export function postPaymentMethodsSuccess(response) {
  return {
    type: POST_PAYMENT_METHODS_SUCCESS,
    payload: response
  }
}

export function postPaymentMethodsFail(response) {
  return {
    type: POST_PAYMENT_METHODS_FAIL,
    payload: response
  }
}
export function withdrawSuccess(response) {
  return {
    type: WITHDRAWAL_SUCCESS,
    payload: response
  }
}
export function withdrawFail(response) {
  return {
    type: WITHDRAWAL_FAIL,
    payload: response
  }
}

export function getPaymentMethodsSuccess(response) {
  return {
    type: GET_PAYMENT_METHODS_SUCCESS,
    payload: response
  }
}


export function getPaymentMethodsFail(response) {
  return {
    type: GET_PAYMENT_METHODS_FAIL,
    payload: response
  }
}
export function resetWithdrawalStatus () {
  return {
    type: RESET_WITHDRAWAL_STATUS,
    payload: ""
  }
}
import { createStore, applyMiddleware, combineReducers } from 'redux';
import authReducer from './reducer/authReducer';
import kycReducer from './reducer/kycReducer';
import homeReducer from './reducer/homeReducer';
import settingReducer from './reducer/settingReducer';
import paymentReducer from './reducer/paymentReducer';
import walletReducer from './reducer/walletReducer';
import invoiceReducer from './reducer/invoiceReducer';
import invoiceUploadReducer   from './reducer/invoiceUploadReducer';
const thunkMiddleware = require('redux-thunk').default;

const mainReducer = combineReducers(
    {
        auth: authReducer,
        kyc: kycReducer,
        home: homeReducer,
        setting: settingReducer,
        payment: paymentReducer,
        wallet: walletReducer,
        invoice: invoiceReducer,
        invoiceUpload: invoiceUploadReducer,
    }
);
const store = createStore(mainReducer, applyMiddleware(thunkMiddleware));
export default store;







// import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
// import authReducer from './reducer/authReducer';
// import kycReducer from './reducer/kycReducer';
// import homeReducer from './reducer/homeReducer';
// import settingReducer from './reducer/settingReducer';
// import paymentReducer from './reducer/paymentReducer';
// import walletReducer from './reducer/walletReducer';

// const thunkMiddleware = require('redux-thunk').default;
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const mainReducer = combineReducers(
//     {
//         auth: authReducer,
//         kyc: kycReducer,
//         home: homeReducer,
//         setting: settingReducer,
//         payment: paymentReducer,
//         wallet: walletReducer,
//     }
// );
// const store = createStore(mainReducer,composeEnhancers(applyMiddleware(thunkMiddleware)));
// export default store;
import {
  postSendSuccess,
  postSendFail,
  postReceiveSuccess,
  postReceiveFail
} from '../action/walletAction';
import { API } from "./api";
import {API_ENDPOINTS} from "./api-endpoint";

export function send(data, token) {
  return (dispatch) => API.request({
    method: 'POST',
    url: `${API_ENDPOINTS.SEND}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
  }).then(response => {
    dispatch(postSendSuccess(response));
  }).catch((error) => {
    dispatch(postSendFail(error));
  });
}

export function receive(data, token) {
  return (dispatch) => API.request({
    method: 'POST',
    url: `${API_ENDPOINTS.REQUEST}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
  }).then(response => {
    dispatch(postReceiveSuccess(response));
  }).catch((error) => {
    dispatch(postReceiveFail(error));
  });
}

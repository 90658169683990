
import {
  getWTISuccess,
  getWTIFail,
  getADDRESSSuccess,
  getADDRESSFail,
  getCASHSuccess,
  getCASHFail,
  postPaymentMethodsSuccess,
  postPaymentMethodsFail,
  withdrawSuccess,
  withdrawFail,
  getPaymentMethodsSuccess,
  getPaymentMethodsFail,
} from '../action/paymentAction';
import { API } from "./api";
import {API_ENDPOINTS} from "./api-endpoint";

export function getWTI(token) {
  return (dispatch) => API.request({
    method: 'GET',
    url: `${API_ENDPOINTS.WIRE_TRANSFER_INSTRUCTIONS}`,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
  }).then(response => {
    dispatch(getWTISuccess(response));
  }).catch((error) => {
    dispatch(getWTIFail(error));
  });
}

export function getADDRESS(token) {
  return (dispatch) => API.request({
    method: 'GET',
    url: `${API_ENDPOINTS.WALLET_ADDRESS}`,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
  }).then(response => {
    dispatch(getADDRESSSuccess(response));
  }).catch((error) => {
    dispatch(getADDRESSFail(error));
  });
}

export function getCASH(token) {
  return (dispatch) => API.request({
    method: 'GET',
    url: `${API_ENDPOINTS.CASH_BALANCE}`,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
  }).then(response => {
    dispatch(getCASHSuccess(response));
  }).catch((error) => {
    dispatch(getCASHFail(error));
  });
}

export function postPaymentMethods(data, token) {
  return (dispatch) => API.request({
    method: 'POST',
    url: `${API_ENDPOINTS.PAYMENT_METHODS}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
  }).then(response => {
    dispatch(postPaymentMethodsSuccess(response));
  }).catch((error) => {
    dispatch(postPaymentMethodsFail(error));
  });
}


export function getRequestWithdraw(data, token) {
  return (dispatch) => API.request({
    method: 'POST',
    url: `${API_ENDPOINTS.WITHDRAWL}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
  }).then(response => {
    dispatch(withdrawSuccess(response));
  }).catch((error) => {
    dispatch(withdrawFail(error));
  });
}

export function getPaymentMethods(token) {
  return (dispatch) => API.request({
    method: 'GET',
    url: `${API_ENDPOINTS.PAYMENT_METHODS}`,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
  }).then(response => {
    dispatch(getPaymentMethodsSuccess(response));
  }).catch((error) => {
    dispatch(getPaymentMethodsFail(false));
  });
}
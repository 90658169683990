import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getMFA } from "../../redux/services/settingsServices";

import { Box, Button, Card, CardContent, CircularProgress, Container } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router-dom";

const MFA = (props) => {
  const navigate = useNavigate();
  const { mfaSuccessResponse, requestMFA, token } = props;
  useEffect(() => {
    if(token !==""){
      requestMFA(token);
    }
  }, [requestMFA, token]);

  useEffect(() => {
    // console.log("mfaSuccessResponse-->", mfaSuccessResponse);
  }, [mfaSuccessResponse]);

  return (
    <>
      <div className="login_wrapper">
        <Container maxWidth="md">
          {mfaSuccessResponse ? (
            <Card sx={{ p: "20px", mt: "1%" }} className="shadow-xl">
              <p style={{ paddingBottom: "20px", paddingTop: "40px", textAlign: "center", fontSize: "32px" }}>
                Two-Factor Setup key
              </p>
              <CardContent style={{ display: "flex" }}>
                <img
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                  src={mfaSuccessResponse.mfa_media_link}
                  alt=""
                />
              </CardContent>
              <CardContent style={{ display: "flex" }}>
                <Box
                  component={"span"}
                  sx={{ color: "text.primary", marginLeft: "auto", marginRight: "auto" }}
                  gutterBottom
                >
                  {mfaSuccessResponse.mfa_secret}
                  <button
                    className="copy_btn1"
                    sx={{ marginLeft: "20px", width: "2em", height: "1em", float: "right" }}
                  >
                    <ContentCopyIcon
                      className="copy_btn"
                      onClick={() => navigator.clipboard.writeText(mfaSuccessResponse.mfa_secret)}
                      fontSize="small"
                    />
                  </button>
                  <div className="flex justify-center mt-3">
              <Button variant="contained" className="mx-auto" onClick={() => navigate("/home")}>
                Home
              </Button>
            </div>
                </Box>
              </CardContent>
            </Card>
          ) : (
            <div className="flex justify-center items-center mt-[30%]">
              <CircularProgress />
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

const mapStatetoProps = (state) => {
  return {
    token:state.auth.token,
    mfaSuccessResponse: state.setting.mfaSuccessResponse,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    requestMFA: function (token) {
      dispatch(getMFA(token));
    },
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(MFA);

import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { acceptAgreement } from "../../redux/services/kycServices";
import "./displayAgreement";
import { Link, useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import UndoIcon from "@mui/icons-material/Undo";

function DisplayAgreement(props) {
  const navigate = useNavigate();
  const { userdata, token } = props;

  const [agreementContent, setAgreementContent] = useState("");
  const agreement = useSelector((state) => state.auth.agreement);
  const [agree, setAgree] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (agreement !== null && agreement !== undefined) {
      console.log(agreement);
      if (agreement[0]) {
        setAgreementContent(agreement[0].content);
      } else {
        setError(true);
      }
    }
  }, [agreement]);

  const checkboxHandler = () => {
    setAgree(!agree);
  };

  if (props.msg === "AGREEMENT_ACCEPT_SUCCESS") {
    navigate("/upload-address-document");
  }
  if (error) {
    return (
      <div className="mt-32 flex flex-col gap-8">
        <h1 className="text-center">Oops! Agreement couldn't be generated</h1>
        <h3 className="text-center">This usually happens because of the incorrect details provided. </h3>
        <div className="text-center text-lg">
          <Link to={"/profile"}>
            <UndoIcon /> Go back and correct all details
          </Link>
        </div>
      </div>
    );
  }
  return (
    <Container maxWidth="xl">
      <div className="login_header">
        <div className="row">
          <div className="col-lg-6">
            <img className="logo-style" src="./images/logo.png" alt="logo" />
          </div>
        </div>

      </div>
      <Box sx={{ mb: 5 }}>
        <FormGroup>
          <div
            className="agreement-section"
            contentEditable="false"
            id="stringinput"
            dangerouslySetInnerHTML={{ __html: agreementContent }}
          ></div>
          <FormControlLabel control={<Checkbox onChange={checkboxHandler} />} label="Accept Agreement" />
          <Button
            disabled={!agree}
            onClick={() =>
              props.requestAcceptAgreement({
                decision: [{ agreement_id: agreement[0].agreement_id, is_accepted: true }],
              }, token)
            }
            variant="primary"
          >
            Next
          </Button>
        </FormGroup>
      </Box>

    </Container>
  );
}

const mapStatetoProps = (state) => {
  return {
    token:state.auth.token,
    agreement: state.auth.agreement,
    latestuserdata: state.auth.latestuserdata,
    userdata: state.auth.userdata,
    msg: state.kyc.msg,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    requestAcceptAgreement: function (data, token) {
      dispatch(acceptAgreement(data, token));
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(DisplayAgreement);

export const initialState = {
  priceHistory:{},
  userPortfolioSummaryStatus : null,
  userPortfolioSummary : null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case "REDIRECT_HOME":
      return {
        ...state,
        status: action.payload.status,
        priceHistory: action.payload.data,
        msg: "REDIRECT_HOME",
      };
      case "REDIRECT_HOME_WITH_PORTFOLIO_SUMMARY" : 
      return {
        ...state,
        userPortfolioSummaryStatus: action.payload.status,
        userPortfolioSummary: action.payload.data,
        msg: "REDIRECT_HOME_WITH_PORTFOLIO_SUMMARY"
      }
      case "REDIRECT_HOME_WITH_PORTFOLIO_SUMMARY_FAIL" : 
      return {
        ...state,
        userPortfolioSummaryStatus: action.payload.status,
        userPortfolioSummary: action.payload,
        msg: "REDIRECT_HOME_WITH_PORTFOLIO_SUMMARY_FAIL"
      }
      case "REDIRECT_HOME_WITH_ACCOUNTS":
      return {
        ...state,
        status: action.payload.status,
        userAccounts: action.payload.data,
        msg: "REDIRECT_HOME_WITH_PORTFOLIO_SUMMARY"
      }
      case "REDIRECT_HOME_WITH_ORDER_PREVIEW_SUCCESS":
      return {
        ...state,
        status: action.payload.status,
        orderPreviewDetails: action.payload.data,
        msg: "REDIRECT_HOME_WITH_ORDER_PREVIEW_SUCCESS"
      }
      case "REDIRECT_PREVIEW_BUY_FAIL":
      return {
        ...state,
        status: action.payload.status,
        previewBuyFailResponse: action.payload,
        msg: "REDIRECT_PREVIEW_BUY_FAIL"
      }
      case "REDIRECT_ORDER_SUCCESS":
      return {
        ...state,
        status: action.payload.status,
        orderSuccessResponse: action.payload.data,
        orderPreviewDetails:null,
        msg: "REDIRECT_ORDER_SUCCESS"
      }
      case "REDIRECT_ORDER_FAIL":
      return {
        ...state,
        status: action.payload.status,
        orderFailResponse: action.payload,
        msg: "REDIRECT_ORDER_FAIL"
      }
      case "REDIRECT_PREVIEW_SELL_SUCCESS":
        return {
          ...state,
          status: action.payload.status,
          previewSellSuccessResponse: action.payload.data,
          msg: "REDIRECT_PREVIEW_SELL_SUCCESS"
        }
      case "REDIRECT_PREVIEW_SELL_FAIL":
        return {
          ...state,
          status: action.payload.status,
          previewSellFailResponse: action.payload,
          msg: "REDIRECT_PREVIEW_SELL_FAIL"
        }
      case "REDIRECT_SELL_SUCCESS":
        return {
          ...state,
          status: action.payload.status,
          sellSuccessResponse: action.payload.data,
          previewSellSuccessResponse: null,
          msg: "REDIRECT_SELL_SUCCESS"
        }
      case "REDIRECT_SELL_FAIL":
        return {
          ...state,
          status: action.payload.status,
          sellFailResponse: action.payload,
          msg: "REDIRECT_SELL_FAIL"
        }
      case "GET_LATEST_TXN_SUCCESS": 
        return {
          ...state,
          status: action.payload.status,
          latestTransactions: action.payload,
          msg: "GET_LATEST_TXN_SUCCESS"
        }
      case "GET_LATEST_TXN_FAIL":
        return {
          ...state,
          status: action.payload.status,
          latestTransactionsFail: action.payload,
          msg: "GET_LATEST_TXN_FAIL"
        }      
      case "RESET_AND_REDIRECT_HOME":
        return {
          ...state,
          orderPreviewDetails: undefined,
          orderSuccessResponse: undefined,
          previewSellSuccessResponse: undefined,
          sellSuccessResponse: undefined,
          previewSellFailResponse: undefined,
          previewBuyFailResponse: undefined,
          orderFailResponse: undefined,
          sellFailResponse: undefined,
          msg: "REDIRECT_HOME"
        } 
    default:
      return state;
  }
}

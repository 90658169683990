import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Container from "@mui/material/Container";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';


import { requestLatestTransactions } from "../../redux/services/homeServices";


const headCells = [
  {
    id: 'type',
    numeric: false,
    disablePadding: true,
    label: 'Type',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: 'currency',
    numeric: false,
    disablePadding: false,
    label: 'Currency',
  },
  {
    id: 'transaction_date',
    numeric: false,
    disablePadding: false,
    label: 'Transaction Date',
  },
  {
    id: 'settle_on',
    numeric: false,
    disablePadding: false,
    label: 'Settled Date',
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow options={{
      rowStyle: {
        fontSize: 32,
      }
    }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            align="right"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const flattenTransaction = (transactions) => {
  return transactions.map(transaction =>
     {
      return {
        "type":transaction.type,
        "status":transaction.status,
        "amount": transaction.amount.amount,
        "currency": transaction.amount.currency,
        "transaction_date": transaction.transaction_date,
        "settled_on": transaction.settled_on
      }
    }
  )
}

const LatestTransactions = (props) => {
  const { getLatestTransactions, latestTransactions, token } = props;
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');


  useEffect(() => {
    if(token !== ""){
      getLatestTransactions(token);
    }
  }, [token]);

  useEffect(() => {
    // console.log("latestTransactions-->", latestTransactions);
    if (latestTransactions) {
      setTransactions(flattenTransaction(latestTransactions.data));
    }
  }, [latestTransactions]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // console.log('handleChangePage');
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = transactions.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
        <Container maxWidth={"lg"} sx={{backgroundColor:'primary.secondary'}}>
          {latestTransactions && (
            <>
            <TableContainer>
              <Table aria-label="transaction table" sx={{mt:10}}>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={transactions.length}
                  sx={{fontSize:"32px"}}
                />
                <TableBody>
                  {transactions
                  .slice().sort(getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((latestTransaction) => (
                    <TableRow key={latestTransaction.id} sx={{borderBottom:"10px", borderColor: 'primary.main'}}>
                      <TableCell component="th" scope="row" align="right">
                        {latestTransaction.type}
                      </TableCell>
                      <TableCell align="right">
                        {latestTransaction.status}
                      </TableCell>
                      <TableCell align="right">
                        {latestTransaction.amount}
                      </TableCell>
                      <TableCell align="right">
                        {latestTransaction.currency}
                      </TableCell>
                      <TableCell align="right">
                        {latestTransaction.transaction_date ? new Date(latestTransaction.transaction_date).toLocaleString('en-US'): '-'}
                      </TableCell>
                      <TableCell align="right">
                        {latestTransaction.settled_on ? new Date(latestTransaction.settled_on).toLocaleString('en-US'): '-'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={transactions.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ></TablePagination>
            </>
          )}
        </Container>
    </>
  );
};

const mapStatetoProps = (state) => {
  return {
    token: state.auth.token,
    latestTransactions: state.home.latestTransactions,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    getLatestTransactions: function (token) {
      dispatch(requestLatestTransactions(token));
    },
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(LatestTransactions);

const SEND_SUCCESS = "SEND_SUCCESS";
const SEND_FAIL = "SEND_FAIL";
const RECEIVE_SUCCESS = "RECEIVE_SUCCESS";
const RECEIVE_FAIL = "RECEIVE_FAIL";
const RESET_WALLET = "RESET_WALLET";

export function postSendSuccess(response) {
  return {
    type: SEND_SUCCESS,
    payload: response
  }
}

export function postSendFail(response) {
  return {
    type: SEND_FAIL,
    payload: response
  }
}

export function postReceiveSuccess(response) {
  return {
    type: RECEIVE_SUCCESS,
    payload: response
  }
}

export function postReceiveFail(response) {
  return {
    type: RECEIVE_FAIL,
    payload: response
  }
}

export function resetWalletState() {
  return {
    type: RESET_WALLET
  }
}

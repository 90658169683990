const REDIRECT_SHOW_AGREEMENT = "REDIRECT_SHOW_AGREEMENT";
const REDIRECT_UPLOAD_DOCUMENTS = "REDIRECT_UPLOAD_DOCUMENTS";
const REDIRECT_UPLOAD_DOCUMENTS_SUCCESS = "REDIRECT_UPLOAD_DOCUMENTS_SUCCESS";
const RESET_AND_REDIRECT_KYC = "RESET_AND_REDIRECT_KYC";
const SOCURE_POST_SUCCESS = "SOCURE_POST_SUCCESS"
const SOCURE_POST_FAILURE = "SOCURE_POST_FAILURE";
const AGREEMENT_ACCEPT_SUCCESS = "AGREEMENT_ACCEPT_SUCCESS";

export function getAgreementSuccess(response) {
  return {
    type: REDIRECT_SHOW_AGREEMENT,
    payload: response,
  }
}

export function acceptAgreementSuccess(response) {
  return {
    type: AGREEMENT_ACCEPT_SUCCESS,
    payload: response,
  }
}

export function uploadDocumentSuccess(response) {
  return {
    type: REDIRECT_UPLOAD_DOCUMENTS_SUCCESS,
    payload: response,
  }
}
export function socurePostSuccess(response) {
  return {
    type: SOCURE_POST_SUCCESS,
    payload: response,
  }
}
export function socurePostFail(response) {
  return {
    type: SOCURE_POST_FAILURE,
    payload: response,
  }
}

export function resetKycState() {
  return {
    type: RESET_AND_REDIRECT_KYC,
  }
}
export const BASE_URL =  process.env.REACT_APP_API_ENDPOINT;//"http://ec2-54-160-169-131.compute-1.amazonaws.com:8080";

export const API_ENDPOINTS = {
    // authentication-apis
    VERIFY_EMAIL: `${BASE_URL}/auth/verify-email`,
    VERIFY_EMAIL_CODE: `${BASE_URL}/auth/verify-email-code`,
    CREATE_CUSTOMER: `${BASE_URL}/auth/customer`,
    PROFILE_UPDATE: `${BASE_URL}/customer/profile`,
    UPLOAD_DOCS: `${BASE_URL}/customer/uploaded-documents`,
    UPLOAD_IMAGE: `${BASE_URL}/customer/uploaded-image`,
    LOGIN: `${BASE_URL}/auth/login`,
    MFA_SETTINGS: `${BASE_URL}/auth/app-based-mfa-qrcode`,
    TEMPORARY_PASSWORD: `${BASE_URL}/auth/verify-temporary-password-temp`,
    VERIFY_CLIENT_CUSTOMER: `${BASE_URL}/auth/verify-client-customer`,
  
    RESET_PASSWORD: `${BASE_URL}/auth/reset-password`,
    SET_PASSWORD: `${BASE_URL}/auth/set-password`,
  
    CUSTOMER_AGREEMENT: `${BASE_URL}/customer/agreement`,
    OAUTH_REQUEST: `${BASE_URL}/oauth/request`,
  
    // wallet
    WALLET_SUMMARY: `${BASE_URL}/wallet/summary`,
    WALLET_ACCOUNTS: `${BASE_URL}/wallet/accounts`,
    SEND: `${BASE_URL}/wallet/send`,
    REQUEST: `${BASE_URL}/wallet/request`,
    REQUEST_MULTIPLE: `${BASE_URL}/contacts/split`,
    WALLET_ADDRESS: `${BASE_URL}/wallet/address`,
    WALLET_QR_CODE: `${BASE_URL}/wallet/qr-code`,
  
    // market
    MARKET_PRICES: `${BASE_URL}/market/prices`,
    PRICE_HISTORY: `${BASE_URL}/market/price-history`,
    EXCHANGE_RATES: `${BASE_URL}/market/exchange-rates`,
  
    // trade
    PREVIEW_BUY: `${BASE_URL}/trade/preview-buy`,
    BUY: `${BASE_URL}/trade/buy`,
    PREVIEW_SELL: `${BASE_URL}/trade/preview-sell`,
    SELL: `${BASE_URL}/trade/sell`,
  
    // contacts
    CONTACTS: `${BASE_URL}/contacts`,
  
    // transactions
    LATEST_TRANSACTIONS: `${BASE_URL}/wallet/latest-transactions`,
    ANALYSTICS: `${BASE_URL}/wallet/transactions`,
  
    // payment methods
    PAYMENT_METHODS: `${BASE_URL}/accounts/funds-transfer-methods`,
    WIRE_TRANSFER_INSTRUCTIONS: `${BASE_URL}/accounts/wire-transfer-instructions`,
    TRANSFERS: `${BASE_URL}/accounts/transfers`,
    CASH_BALANCE: `${BASE_URL}/accounts/cash-balance`,
    WITHDRAWL: `${BASE_URL}/accounts/withdraw`,
  
    // notifications
    NOTIFICATIONS: `${BASE_URL}/accounts/notification`,
  
    // preferences
    PREFERENCES: `${BASE_URL}/customer/preference`,
    // help
    HELP: `${BASE_URL}/help`,
  
    //KYC
    KYC: `${BASE_URL}/customer/create-kyc`,
  
    INVITATION: `${BASE_URL}/customer/invitations`,
  
    //OAUTH Navigate
    OAUTH_Navigate_WEB: `${BASE_URL}/oauth/callback-state`,

    //Invoice
    INVOICE: `${BASE_URL}/invoice`,
    INVOICE_FILE : `${BASE_URL}/invoice/file`,
  };

export function createInvoiceRequestFunction(rawData){
    console.log('rawData', rawData);
    
    const request = {
    "crypto_payment_options": parseCoinAddress(rawData.cryptoPaymentOptions),
    "due_date": rawData.apiDueDate,
    "invoice_date": (new Date()).toISOString(),
    "invoice_number": rawData.invoiceId,
    "items": [
      {
        "amount": rawData.itemTotal,
        "description": rawData.item,
        "id": 0,
        "price": rawData.price,
        "quantity": rawData.quantity
      }
    ],
    "mailing_address": {
      "address1": rawData.addressone,
      "address2": rawData.addresstwo,
      "city": rawData.city,
      "country": rawData.country,
      "is_po_box": true,
      "state": rawData.state,
      "zip": rawData.zipcode,
    },
    "memo": "string",
    "options": "string",
    "profile_id": 0,
    "recipient": {
      "attention_to": "string",
      "email": rawData.email,
      //"id": 0,
      "mailing_address": {
        "address1": rawData.addressone,
        "address2": rawData.addresstwo,
        "city": rawData.city,
        "country": rawData.country,
        "is_po_box": true,
        "state": rawData.state,
        "zip": rawData.zipcode,
      },
      "name": rawData.name,
      "shipping_address": {
        "address1": rawData.shippingAddressone,
        "address2": rawData.shippingAddresstwo,
        "city": rawData.shippingCity,
        "country": rawData.shippingCountry,
        "is_po_box": true,
        "state": rawData.shippingState,
        "zip": rawData.shippingZipcode,
      }
    },
    "shipping_address": {
      "address1": rawData.shippingAddressone,
      "address2": rawData.shippingAddresstwo,
      "city": rawData.shippingCity,
      "country": rawData.shippingCountry,
      "county": "string",
      "is_po_box": true,
      "state": rawData.shippingState,
      "zip": rawData.shippingZipcode,
      "zip_ext": "string"
    },
    "shipping_fee": rawData.shippingTotal,
    "status": "UNKNOWN",
    "subtotal": rawData.itemTotal,
    "tax_amount": rawData.taxTotal,
    "tax_percentage": rawData.taxPct,
    "terms": "string",
    "title": rawData.invoiceTitle,
    "total": rawData.total,
    "wire_payment_option": {
      "account_number": "string",
      "bank_account_name": "string",
      "bank_name": "string",
      "id": 0,
      "notes": "string",
      "routing_number": "string",
      "swift_code": "string"
    }
  }
  return request;
  }

  function parseCoinAddress(selectedCoinOptions){

    const parsedCoinAddress = selectedCoinOptions.map(coin => {
        return {
            address: coin.address,
            currency_symbol: coin.currency_code,
            qr_code_url: coin.qr_code
        }
    })

    return parsedCoinAddress;
  }
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Transfer from "../Trade/Transfer";
import TransferSuccess from "../Trade/TransferSuccess";
import { connect } from "react-redux";

function TransferDialog(props) {
  const { onClose, selectedValue, open, rcvSccRes, sendSccRes} = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  
  const handleListItemClick = (value) => {
    onClose(value);
  };

  useEffect(() => {
    // console.log(rcvSccRes);
  }, [rcvSccRes])

  return (
    <Dialog onClose={handleClose} open={open}>
      { !rcvSccRes && !sendSccRes  && <Transfer/>}
      { (rcvSccRes || sendSccRes) && <TransferSuccess/>}
    </Dialog>
  );
}

TransferDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStatetoProps = (state) => {
  return {
    rcvSccRes: state.wallet.receiveSuccessResponse,
    sendSccRes: state.wallet.sendSuccessResponse,
  };
};

export default connect(mapStatetoProps, null)(TransferDialog)

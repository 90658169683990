import * as React from "react";
import { connect } from "react-redux";
import { Box, Button, Container, MenuItem, Snackbar, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getHelp } from "../../redux/services/settingsServices";
import { resetStatus } from "../../redux/action/settingAction";

import MuiAlert from "@mui/material/Alert";
import { Alert } from "@mui/material";

const helpOptions = [
  {
    value: "Suggestion",
    label: "Suggestion",
  },
  {
    value: "Gratitude",
    label: "Gratitude",
  },
  {
    value: "Question",
    label: "Question",
  },
  {
    value: "Complaint",
    label: "Complaint",
  },
];

const AlertBar = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Help = (props) => {
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const navigate = useNavigate();
  const { callgetHelp, status, callResetStatus, token } = props;
  const validationSchema = Yup.object().shape({
    typeOfHelp: Yup.string().required("Type is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    message: Yup.string().required("Message is required"),
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    data = {
      body: data.message,
      from: data.email,
      theme: data.typeOfHelp,
    };
    callgetHelp(JSON.stringify(data), token);
  };

  React.useEffect(() => {
    setValue("email", props?.userdata?.email);
  }, []);

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  React.useEffect(() => {
    if (status === 200) {
      setOpenSnackBar((prev) => true);
      callResetStatus();
      setTimeout(() => {
        navigate("/home");
      }, [3000]);
    }
  }, [status]);

  return (
    <>
      <div className="login_wrapper login_wrapper-bottom-margin">
        <Container maxWidth="md">
          <Box component="form" className="coinspaze-form shadow-2xl" sx={{ p: "20px", mt: "5%" }}>
            {status === 400 && (
              <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                Please enter correct information.
              </Alert>
            )}
            {status === 422 && (
              <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                Your request could not be processed.
              </Alert>
            )}
            {status === 403 && (
              <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                You are not authorised to perform this action.
              </Alert>
            )}
            {status === 500 && (
              <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                Internal Server Error
              </Alert>
            )}
            <p style={{ textAlign: "center", paddingTop: "40px", fontSize: "32px" }}>Get Help</p>
            <div className="flex flex-col gap-4">
              <Controller
                name="typeOfHelp"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Select Type"
                    variant="outlined"
                    className={"w-full"}
                    select
                    defaultValue=""
                    error={Boolean(errors.typeOfHelp)}
                    helperText={errors.typeOfHelp?.message}
                    InputLabelProps={{ required: true }}
                    size="small"
                  >
                    {helpOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Email"
                    variant="outlined"
                    label="Your Email"
                    className={"w-full"}
                    error={errors.email}
                    helperText={errors.email?.message}
                    InputLabelProps={{ required: true }}
                    size="small"
                  />
                )}
              />
              <Controller
                name="message"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={4}
                    placeholder="Message"
                    variant="outlined"
                    label="Your Message"
                    className={"w-full"}
                    error={errors.message}
                    helperText={errors.message?.message}
                    InputLabelProps={{ required: true }}
                    size="small"
                  />
                )}
              />
            </div>
            <Box className="flex flex-row justify-center mx-auto gap-2 w-2/6 mt-3">
              <Button variant="outlined" onClick={() => navigate("/home")} className="mx-auto">
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSubmit(onSubmit)} className="mx-auto">
                Send
              </Button>
            </Box>
          </Box>
          <Snackbar
            open={openSnackBar}
            autoHideDuration={3000}
            onClose={handleCloseSnackBar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <AlertBar onClose={handleCloseSnackBar} severity="success" sx={{ width: "100%" }}>
              Your request is submitted. Someone from Coinspaze will contact you soon.
            </AlertBar>
          </Snackbar>
        </Container>
      </div>
    </>
  );
};

const mapStatetoProps = (state) => {
  return {
    token: state.auth.token,
    userdata: state.auth.userdata,
    status: state.setting.helpResponseStatus,
    helpResponse: state.setting.helpResponse,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    callgetHelp: function (data, token) {
      dispatch(getHelp(data, token));
    },
    callResetStatus: function () {
      dispatch(resetStatus());
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Help);

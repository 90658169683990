export const initialState = {
  //createInvoiceResponse:undefined,
  //invoiceListResponse:undefined,
  uploadInvoiceResponse:undefined,
  //isOpen:false
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
      case "UPLOAD_INVOICE":{
        return {
          uploadInvoiceResponse:action.payload,
        }
      }
      case "RESET_AND_REDIRECT_HOME":
        return {
          isOpen:false
        } 
    default:
      return state;
  }
}

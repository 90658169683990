import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { checkuserbyemail } from "../redux/services/authServices";
import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";

function SplashScreen(props) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [userEmail, setUserEmail ] = useState();

  const verificationStatus = useSelector(
    (state) => state.auth.useverificationstatus
  );
  const verificationStatusCode = useSelector(
    (state) => state.auth.status
  );

  const navigationStatusChange = (verificationStatus) => {
    if (verificationStatus === true) {
      navigate("/");
    }
  };

  const parseVerification = (email, hmac, client_id) => {
    if (hmac == null || client_id == null) {
      return;
    }
    dispatch(checkuserbyemail(email, hmac, client_id));
  };

  useEffect(() => {
    navigationStatusChange(verificationStatus);
  }, [verificationStatus]);

  useEffect(() => {
    console.log('verificationStatusCode-->',verificationStatusCode);
    if(verificationStatusCode === 409){
      navigate("/");
    }
  }, [verificationStatusCode])

  useEffect(() => {
    const email = searchParams.get("email");
    if (email) {
      setUserEmail(email);
      parseVerification(
        email,
        searchParams.get("x-coinspaze-hmac"),
        searchParams.get("x-coinspaze-client-id")
      );
    }
  }, [searchParams]);

  useEffect(() => {
    // console.log("isAuthenticated-->", isAuthenticated);
    console.log("process.env-->", process.env);

    if (isAuthenticated) {
      navigate("/home");
    } else if (location.pathname === "/" && location.search === "") {
      let optionlParam = {};

      if(userEmail){
        optionlParam = {"login_hint":userEmail};
      }
      loginWithRedirect(optionlParam);
    }
  });

  return (
    <Box></Box>
  );
}

const mapStatetoProps = (state) => {
  return {
    useverificationstatus: state.auth.useverificationstatus,
    statusCode: state.auth.status,
    msg: state.auth.msg,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(SplashScreen);

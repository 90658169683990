import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Login } from "../../redux/services/authServices";
import phoneimg from "../public/images/phone_img.png";

function Register(props) {
  const [emailerrors, setemailErrors] = useState("");
  const [pwderrors, setpwdErrors] = useState("");
  function handleChange(event) {
    /*  <Button variant="primary" onClick={()=>props.Login(email,password)}>Register</Button>*/
    // console.log("event called");
    event.preventDefault();
    const { name, value } = event.target;
    // console.log(name);
    switch (name) {
      case "email":
        if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          setemailErrors("Enter a valid email address");
        } else {
          setemailErrors("");
        }
        break;
      case "password":
        if (
          !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)
        ) {
          setpwdErrors(
            "Password should contains atleast 8 charaters and containing uppercase,lowercase and numbers"
          );
        } else {
          setpwdErrors("");
        }
        break;
      default:
        break;
    }
  }
  return (
    <div className="login_wrapper">
      <div className="container">
        <div className="login_header">
          <div className="row">
            <div className="col-lg-6">
              <img className="logo-style" src="./images/logo.png" alt="CoinSpaze" />
            </div>
            <div className="col-lg-6 text-right">
              <p>
                Already Account? <a href="/login">Login</a>
              </p>
            </div>
          </div>
        </div>

        <div className="login_sec">
          <div className="row">
            <div className="col-md-5 text-center">
              <img src={phoneimg} alt="logo"/>
            </div>
            <div className="col-md-7">
              <div className="login_form">
                <h3>Sign Up</h3>
                <form>
                  <p>{props.msg}</p>
                  <div className="form-group">
                    <Form.Label>Email</Form.Label>
                    <div className="input_border">
                      <Form.Control
                        type="text"
                        name="email"
                        defaultValue={props.username}
                        onChange={handleChange}
                      />
                    </div>
                    {emailerrors && (
                      <h7 style={{ color: "#fff" }}>{emailerrors}</h7>
                    )}
                  </div>
                  <div className="form-group">
                    <Form.Label>Password</Form.Label>
                    <div className="input_border">
                      <Form.Control
                        type="password"
                        name="password"
                        defaultValue={props.password}
                        onChange={handleChange}
                      />
                    </div>
                    {pwderrors && (
                      <h7 style={{ color: "#fff" }}>{pwderrors}</h7>
                    )}
                  </div>
                  <div className="forgot_link">
                    <p>
                      <a href="/login">Login</a>
                    </p>
                  </div>
                  <div className="login_btn">
                    <Button variant="primary">Register</Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStatetoProps = (state) => {
  // console.log(state);
  return {
    email: state.auth.email,
    msg: state.auth.msg,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    adminLogin: function (username, password) {
      dispatch(Login(username, password));
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Register);

import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

import { connect, useDispatch } from "react-redux";
import STATES from "../../config/states";
import { setupProfilestep2Service } from "../../redux/services/authServices";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../BackButton";

import Script from "react-load-script";
import { Button, MenuItem, Select } from "@mui/material";
import { clearMsgAction } from "../../redux/action/authAction";

function SetupProfilestep2(props) {
  const {userdata, token} = props;
  
  const [state, setState] = useState(userdata["state"]);
  const [stateerrors, setStateErrors] = useState("");

  const [taxState, setTaxState] = useState(userdata["tax_id_state"]??"");
  const [taxStateErrors, setTaxStateErrors] = useState("");
  const [taxCountry] = useState('US');

  const [zipcode, setZipcode] = useState(userdata["zip"]);
  const [zipcodeerrors, setZipcodeErrors] = useState("");
  const [Taxid, setTaxid] = useState("");
  const [TaxIdErrors, setTaxIdErrors] = useState("");

  const [tAndC, setTAndC] = useState("");
  const [tAndCErrors, setTAndCErrors] = useState("");

  const [city, setCity] = useState(userdata["city"]);
  const [CityErrors, setCityErrors] = useState("");

  const [addressone, setAddressone] = useState(userdata["address_one"]);
  const [addressoneErrors, setAddressoneErrors] = useState("");
  const [addresstwo, setAddresstwo] = useState(userdata["address_two"]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const autocompleteRef = React.useRef(null);

  const handlePlaceSelect = () => {
    const addressObject = autocompleteRef.current.getPlace();


    let addressOne = getAddressOne(addressObject)
    addressOne ? setAddressone(addressOne) : setAddressoneErrors("Enter street name")

    // let addressTwo = getAddressByType(addressObject, "administrative_area_level_2")?.long_name;
    // addressTwo ? setAddresstwo(addressTwo) : setAddresstwo("");

    let city = getAddressByType(addressObject, "locality");
    city ? setCity(city.long_name) : setCityErrors("Enter City Name") && setCity("");

    let code = getAddressByType(addressObject, "postal_code");
    code ? setZipcode(code.long_name) : (setZipcodeErrors("Enter your ZipCode") && setZipcode(""));

    let state = getAddressByType(addressObject, "administrative_area_level_1");
    state ? setState(state.short_name) : setStateErrors("Enter State Name");

  };

  const getAddressOne = (addressObject) => {
    let streetNumber = getAddressByType(addressObject, "street_number");
    streetNumber ? streetNumber = streetNumber.long_name : streetNumber = "";

    let streetName = getAddressByType(addressObject, "route");
    streetName ? streetName = streetName.long_name : streetName = "";

    return streetNumber + " " + streetName;
  }

  const getAddressByType = (address, type) => {
    if (!type)
      return null;
    if (!address || !address.address_components || address.address_components.length === 0)
      return null;

    type = type.toLowerCase();

    for (let comp of address.address_components) {
      if (!comp.types || comp.types.length === 0)
        continue;

      if (comp.types.findIndex(x => x.toLowerCase() === type) > -1)
        return comp;
    }
    return null;
  }

  const handleScriptLoad = () => {
    const options = {
      componentRestrictions: { country: ["usa"] },
      libraries: ["places"],
      fields: ["address_components"]
    };

    /*global google*/
    autocompleteRef.current = new google.maps.places.Autocomplete(
      document.getElementById("mainInput"),
      options
    );

    autocompleteRef.current.addListener("place_changed", handlePlaceSelect);
  };

  useEffect(() => {
    dispatch(clearMsgAction());
  }, [])


  // if (props.msg === 'REDIRECT_SHOW_AGREEMENT') {
  //   dispatch(getAgreement())
  //     .then(() => navigate("/show-agreement"));
  // }


    if(props.msg === "REDIRECT_TO_SOCURE_FLOW"){
      navigate("/socure")
    }


  function handleChange(event) {
    const { name, value } = event.target;
    switch (name) {
      case "state":
        if (value === "") {
          setStateErrors("Kindly select state!");
          setState("");
        } else {
          setStateErrors("");
          setState(value);
        }
        break;
      case "taxState":
        if (value === "") {
          setTaxStateErrors("Kindly select Tax State!");
          setTaxState("");
        } else {
          setTaxStateErrors("");
          setTaxState(value);
        }
        break;
      case "taxid":
        if (value.length !== 9) {
          setTaxIdErrors("Kindly Enter Valid Tax ID (example 123456789)");
          setTaxid("");
        } else {
          setTaxIdErrors("");
          setTaxid(value);
        }
        break;
      case "tandc":
        if (!event.target.checked) {
          setTAndCErrors("Kindly accept terms and conditions!!");
          setTAndC("");
        } else {
          setTAndCErrors("");
          setTAndC(value);
        }
        break;
      case "city":
        // console.log(value);
        if (value === "") {
          setCityErrors("Kindly enter city!");
          setCity("");
        } else {
          setCityErrors("");
          setCity(value);
        }
        break;
      case "addresstwo":
        setAddresstwo(value);
        break;
      case "addressone":
        if (value === "") {
          setAddressoneErrors("Kindly enter address one!");
          setAddressone("");
        } else {
          setAddressoneErrors("");
          setAddressone(value);
        }
        break;
      case "zipcode":
        if (/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)) {
          setZipcodeErrors("");
          setZipcode(value);
        } else {
          setZipcodeErrors("Enter 5 digit zipcode!!");
          setZipcode(value);
        }
        break;
      default:
        // setDisable(false)
        break;
    }
  }
  return (
    <div className="login_wrapper mt-20 ">
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&libraries=places`}
        onLoad={handleScriptLoad}
      />
      <div className="container">
        <div className="own_sec">
          <div className="own_form pt-12">
            <form>
              <div className="row step1">
                <div className="form-group col-md-6">
                  <label>Address One (*)</label>
                  <div className="input_border">
                    <Form.Control
                      id="mainInput"
                      type="text"
                      name="addressone"
                      required
                      placeholder="Enter Address One!"
                      defaultValue={props.userdata["address_one"]}
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={addressone}
                    />
                  </div>

                  {addressoneErrors && (
                    <span style={{ color: "#ff0f0f" }}>{addressoneErrors}</span>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <label>Address Two</label>
                  <div className="input_border">
                    <Form.Control
                      type="text"
                      name="addresstwo"
                      required
                      onChange={handleChange}
                      onBlur={handleChange}
                      defaultValue={props.userdata["address_two"]}
                      value={addresstwo}
                    />
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <label>City (*)</label>
                  <div className="input_border">
                    <Form.Control
                      type="text"
                      name="city"
                      required
                      placeholder="Enter city"
                      onChange={handleChange}
                      onBlur={handleChange}
                      defaultValue={props.userdata["city"]}
                      value={city}
                    />
                  </div>
                  {CityErrors && (
                    <span style={{ color: "#ff0f0f" }}>{CityErrors}</span>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <label>Zip/Post Code (*)</label>
                  <div className="input_border">
                    <Form.Control
                      maxLength={5}
                      type="number"
                      onChange={handleChange}
                      onBlur={handleChange}
                      name="zipcode"
                      required
                      placeholder="Enter 5 digit zipcode"
                      defaultValue={props.userdata["zip"]}
                      value={zipcode}
                    />
                  </div>
                  {zipcodeerrors && (
                    <div style={{ color: "#ff0f0f" }}>{zipcodeerrors}</div>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <label>State</label>
                  <div className="input_border">
                    {/* <select
                      style={{ width: "100%" }}
                      name="state"
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      value={state}
                    >
                      {STATES.map((obj) => {
                        return (
                          <option
                            value={obj.abbreviation}
                            disabled={obj.name === "New York" ? true : false}
                            selected={obj.abbreviation === props.userdata.state}
                          >
                            {obj.name}
                          </option>
                        );
                      })}
                    </select> */}
                    <Form.Control
                      type="text"
                      onChange={handleChange}
                      onBlur={handleChange}
                      name="state"
                      required
                      placeholder="Enter State"
                      // defaultValue={props.userdata["zip"]}
                      value={state}
                    />
                  </div>
                  {stateerrors && (
                    <span style={{ color: "#ff0f0f" }}>{stateerrors}</span>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <label>Country</label>
                  <div className="input_border">
                    <Form.Control
                      type="text"
                      name="country"
                      required
                      defaultValue={taxCountry}
                      readOnly
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <label>Tax ID (*)</label>
                  <div className="input_border">
                    <Form.Control
                      type="text"
                      name="taxid"
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      placeholder="Enter Tax ID (example 12-3456789)"
                    />
                  </div>
                  {TaxIdErrors && (
                    <span style={{ color: "#ff0f0f" }}>{TaxIdErrors}</span>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <label>Tax Country</label>
                  <div className="input_border">
                    <Form.Control
                      type="text"
                      name="taxc"
                      required
                      defaultValue={taxCountry}
                      readOnly
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <label>Tax State (*)</label>
                  <div className="input_border">
                    <Select
                      style={{ width: "100%" }}
                      name="taxState"
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      label="Tax State"
                      value={taxState || ""}
                    >
                      {STATES.map((obj) => {
                        return (
                          <MenuItem
                            value={obj.abbreviation}
                            // disabled={obj.name === "New York" ? true : false}
                            selected={
                              obj.abbreviation === props.userdata.tax_id_state
                            }
                          >
                            {obj.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                  {taxStateErrors && (
                    <span style={{ color: "#ff0f0f" }}>{taxStateErrors}</span>
                  )}
                </div>
                <div className="form-group col-md-12">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="customCheckbox2"
                      name="tandc"
                      className="custom-control-input"
                      onChange={handleChange}
                      onBlur={handleChange}
                    />

                    <label style={{ width: "100%" }} className="custom-control-label" for="customCheckbox2">
                      <span> Accept Aravali's </span>
                      <a href="https://coinspaze.com/terms-and-conditions" target="_blank" rel="noreferrer">
                         Terms & Conditions
                      </a>
                      <span> and </span>
                      <a href="https://coinspaze.com/privacy-policy" target="_blank" rel="noreferrer">
                        Privacy Policy
                      </a>
                    </label>
                    {tAndCErrors && (
                      <span style={{ color: "#ff0f0f" }}>{tAndCErrors}</span>
                    )}
                  </div>
                </div>
                <div className="flex justify-start gap-8">
                  <BackButton path={"/profile"} />
                  <Button
                    className="px-4 py-2 uppercase"
                    variant="contained"
                    color="primary"
                    disabled={
                      !zipcode || !tAndC || !city || !addressone || !Taxid || !state || !taxState
                    }
                    onClick={() =>
                      props.saveProfileStep2(
                        taxCountry,
                        state,
                        zipcode,
                        city,
                        addressone,
                        addresstwo,
                        Taxid,
                        taxCountry,
                        taxState,
                        token
                      )
                    }
                  >
                    Next
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStatetoProps = (state) => {
  return {
    token:state.auth.token,
    msg: state.auth.msg,
    userdata: state.auth.userdata,
    latestuserdata: state.auth.latestuserdata,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    saveProfileStep2: function (
      country,
      state,
      zipcode,
      city,
      addressone,
      addresstwo,
      Taxid,
      tax_id_country,
      taxState,
      token,
    ) {
      // console.log(
      //   country,
      //   state,
      //   zipcode,
      //   city,
      //   addressone,
      //   addresstwo,
      //   Taxid,
      //   tax_id_country,
      //   taxState
      // );
      dispatch(
        setupProfilestep2Service(
          country,
          state,
          zipcode,
          city,
          addressone,
          addresstwo,
          Taxid,
          tax_id_country,
          taxState,
          token
        )
      );
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(SetupProfilestep2);
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';

const agreement = "To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account. What this means for you: When you open an account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We will require your driver’s license or other identifying documentation.";
 
const PatriotAgreement = () => {

    const navigate = useNavigate();

    return (
        <Container maxWidth="md">
        <Box sx={{ mt: 10 }}>
            <FormGroup>
            <Box sx={{ mb: 10, textAlign: 'center', fontWeight: 'bold'}}>
                IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT
            </Box>  
            <div
                className="agreement-section"
                contentEditable="false"
                id="stringinput"
                dangerouslySetInnerHTML={{ __html: agreement }}
            ></div>
            <Typography component='div' sx={{textAlign: 'center', mt:5}}>
                <Button
                    onClick={() => navigate("/show-agreement")}
                    variant="primary">
                    Next
                </Button>
            </Typography>
            </FormGroup>
        </Box>
        </Container>
    )
}
const mapStatetoProps = (state) => {
    return {
    };
};
const mapDispatchtoProps = (dispatch) => {
    return {
    };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(PatriotAgreement);
import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { updatepassword } from "../../redux/services/authServices";
import phoneimg from "../public/images/phone_img.png";
import { createBrowserHistory } from "history";
import { Navigate } from "react-router-dom";
export const browserHistory = createBrowserHistory();
function ResetpwdContainer(props) {
  const [password, setPassword] = useState("");
  const [cpassword, setcPassword] = useState("");
  const [cpwderrors, setcpwdErrors] = useState("");
  const [pwderrors, setpwdErrors] = useState("");

  /*if(props.confirmpassword !== props.password)
        {
           return{
            msg:"password doen't match, please check"
           }
       }*/

  function handleChange(event) {
    // console.log("event called");
    event.preventDefault();
    const { name, value } = event.target;
    switch (name) {
      case "password":
        if (value === "") {
          setpwdErrors("Password should not blank!!");
          setPassword("");
        } else {
          setpwdErrors("");
          setPassword(value);
        }
        break;
      case "cpassword":
        // console.log(password);
        if (value === "") {
          setcpwdErrors("Confirm password should not blank!!");
          setcPassword("");
        } else if (value !== password) {
          setcpwdErrors("password and confirm password not matching!!");
          setcPassword("");
        } else {
          setcpwdErrors("");
          setcPassword(value);
        }
        break;
      default:
        break;
    }
  }

  //console.log(props.token);
  if (props.status === 200 && props.msg !== "Kindly reset your password!!") {
    // console.log("here");
    // console.log(props.data);
    return (
      <Navigate
          to={{ pathname: "/profile", state: { token: props.token } }}
        />
    );
  }

  return (
    <div className="login_wrapper">
      <div className="container">
        <div className="login_header">
          <div className="row">
            <div className="col-lg-6">
              <img className="logo-style" src="./images/logo.png" alt="logo" />
            </div>
            <div className="col-lg-6 text-right">
              <p>
                Don’t have an account? <a href="/register">Create Account</a>
              </p>
            </div>
          </div>
        </div>

        <div className="login_sec">
          <div className="row">
            <div className="col-md-5 text-center">
              <img src={phoneimg} alt="phoneimg"/>
            </div>
            <div className="col-md-7">
              <div className="login_form">
                <h5 style={{ color: "#fff" }}>{props.msg}</h5>
                <h3>Reset Password</h3>
                <form>
                  <div className="form-group">
                    <Form.Label>Password</Form.Label>
                    <div className="input_border">
                      <Form.Control
                        name="password"
                        type="password"
                        defaultValue={props.password}
                        onBlur={handleChange}
                        onChange={handleChange}
                        required
                      />
                    </div>{" "}
                    {pwderrors && (
                      <h7 style={{ color: "#fff" }}>{pwderrors}</h7>
                    )}
                  </div>
                  <div className="form-group">
                    <Form.Label>Confirm Password</Form.Label>
                    <div className="input_border">
                      <Form.Control
                        name="cpassword"
                        type="password"
                        defaultValue={props.confirmpassword}
                        onBlur={handleChange}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    {cpwderrors && (
                      <h7 style={{ color: "#fff" }}>{cpwderrors}</h7>
                    )}
                  </div>

                  <div className="login_btn">
                    <Button
                      disabled={!cpassword || !password}
                      variant="primary"
                      onClick={() =>
                        props.updatepassword(
                          password,
                          props.location.state.token
                        )
                      }
                    >
                      Reset Password
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    msg: state.auth.msg,
    data: state.auth.data,
    status: state.auth.status,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    updatepassword: function (password, token) {
      dispatch(updatepassword(password, token));
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(ResetpwdContainer);

import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Container from "@mui/material/Container";

import {Button} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import { invoiceList } from "../../redux/services/invoiceServices";
import { resetInvoiceState } from '../../redux/action/invoiceAction';
import { useAuth0 } from "@auth0/auth0-react";
import { Alert } from "@mui/material";
import moment from 'moment';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { IconButton } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const headCells = [
  {
    id: "date",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Customer",
  },
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Title",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: true,
    label: "Amount",
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow
        options={{
          rowStyle: {
            fontSize: 32,
          },
        }}
      >
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            align="right"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const flattenInvoice = (invoiceList) => {
  return invoiceList?.map((invoice) => {
    return {
      id: invoice.id,
      date: invoice.invoice_date,
      name: invoice.recipient.name,
      invoice_number: invoice.invoice_number,
      title: invoice.title,
      amount: invoice.total,
      status: invoice.status,
      download_link: invoice.download_link,
    };
  });
};

const Invoices = (props) => {
  const { logout } = useAuth0();
  const { invoiceListResponse, createInvoiceStatus, uploadInvoiceStatus, token } = props;
  const [invoices, setInvoices] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(invoiceList(token));
    dispatch(resetInvoiceState());
  }, []);

  useEffect(() => {
    if (invoiceListResponse) {
      setInvoices(flattenInvoice(invoiceListResponse.data));
    }
  }, [invoiceListResponse]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = invoices.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleCloseUserMenu = (action) => {
    navigate(action);
  }
  return (
    <>
      <Container sx={{ backgroundColor: "primary.secondary" }}>
        {props.status === 500 && (
          <Alert className="mt-4 max-w-sm mx-auto" severity="error">
            Internal Server Error
          </Alert>
        )}
        {(createInvoiceStatus === 200 && uploadInvoiceStatus === undefined) && (
            <Alert className="mt-4 max-w-sm mx-auto" severity="success">
              Invoice successfully created.
            </Alert>
          )}
          { (uploadInvoiceStatus === 200) && (
            <Alert className="mt-4 max-w-sm mx-auto" severity="success">
              Invoice sent successfully.
            </Alert>
          )}
          { (createInvoiceStatus === 400 || uploadInvoiceStatus === 400) && (
            <Alert className="mt-4 max-w-sm mx-auto" severity="error">
              Please enter correct information.
            </Alert>
          )}
          { (createInvoiceStatus === 422 || uploadInvoiceStatus === 422) && (
            <Alert className="mt-4 max-w-sm mx-auto" severity="error">
              Your request could not be processed.
            </Alert>
          )}
          {(createInvoiceStatus === 403 || uploadInvoiceStatus === 403) && (
            <Alert className="mt-4 max-w-sm mx-auto" severity="error">
              You are not authorised to perform this action.
            </Alert>
          )}
          {(createInvoiceStatus === 500 || uploadInvoiceStatus === 500) && (
            <Alert className="mt-4 max-w-sm mx-auto" severity="error">
              Internal Server Error
            </Alert>
          )}
        <div className="flex justify-end mb-2 gap-4">
          <Button variant="contained" color={"primary"} style={{textTransform: 'none'}} onClick={() => handleCloseUserMenu("/create-invoice")}>
            Create an invoice
          </Button>
        </div>
        <Box className="shadow-2xl" sx={{ p:0, mt: "5%", minHeight:'600', textAlign: "center" }}>

          <Container maxWidth={"lg"} sx={{backgroundColor:'primary.secondary'}}>
          {invoices && (
            <>
            <TableContainer>
              <Table aria-label="invoice table" sx={{mt:10}}>
                <EnhancedTableHead />
                <TableBody>
                  {invoices
                  .slice().sort(getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((invoice) => (
                    <TableRow key={invoice.id} sx={{borderBottom:"10px", borderColor: 'primary.main'}}>
                      <TableCell component="th" scope="row" align="right">
                        {moment(invoice.date).format('MMM DD, YYYY')}
                      </TableCell>
                      <TableCell component="th" scope="row" align="right">
                        {invoice.name}
                      </TableCell>
                      <TableCell component="th" scope="row" align="right">
                        {invoice.invoice_number}
                      </TableCell>
                      <TableCell component="th" scope="row" align="right">
                        {invoice.title}
                      </TableCell>
                      {/*<TableCell component="th" scope="row" align="right">
                        {invoice.status}
                      </TableCell>*/}
                      <TableCell component="th" scope="row" align="right">
                        {invoice.amount}
                      </TableCell>
                      <TableCell component="th" scope="row" align="right">
                        <a href={invoice.download_link} download>
                          <CloudDownloadIcon />
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
            rowsPerPageOptions={[5, 25, 50, 100]}
            component="div"
            count={invoices.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ></TablePagination>
            </>
          )}
        </Container>  
        
        </Box>
        
      </Container>
    </>
  );
};

const mapStatetoProps = (state) => {
  return {
    invoiceListResponse: state.invoice.invoiceListResponse,
    createInvoiceStatus: state.invoice.createInvoiceResponse?.status,
    uploadInvoiceStatus: state.invoiceUpload.uploadInvoiceResponse?.status,
    status: state.home.status,
    token: state.auth.token,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    getInvoiceList: function (token) {
      dispatch(invoiceList(token));
    },
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(Invoices);

import React, { useState, useRef } from "react";
import { CircularProgress } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { getAgreement, postSocureData } from "../../redux/services/kycServices";
import { useNavigate } from "react-router-dom";
const Socure = (props) => {
  const [loading, setLoading] = useState(false);
  const [sdkInitiated, setSdkInitiated] = useState(false);
  const sessionIdRef = useRef("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { socureStatus, callPostSocureData, msg, userdata, token } = props;
  function onProgress(progress) {
    setLoading(false);
  }

  function onSuccess(response) {
    if (response.status === "DOCUMENTS_UPLOADED") {
      const updatedUserData = {
        ...userdata,
        socure_document_uploaded: {
          event_id: response?.eventId,
          status: response?.status,
          document_uuid: response?.documentUuid,
          verification_level: response?.verificationLevel,
          mobile_number: response?.mobileNumber,
          key: response?.key,
          reference_id: response?.referenceId,
          verify_result: {
            reference_id: "66680a42-2d4d-408f-aa64-f40c8dd752d0",
            document_verification: {
              reason_codes: ["I831", "I803", "I836"],
              document_type: {
                type: "Drivers License",
                country: "US",
                state: "CA",
              },
              decision: {
                name: "lenient",
                value: "accept",
              },
            },
          },
        },
        socure_device_id: sessionIdRef.current,
      };
      clearSession();
      callPostSocureData(updatedUserData, token);
    }
  }

  function onError(error) {
    console.log(error);
    console.log("error");
    navigate("/home");
  }

  var config = {
    onProgress: onProgress, //callback method for reading the progress status
    onSuccess: onSuccess, //callback method to read the success response
    onError: onError, //callback method to read the error response
    qrCodeNeeded: true,
    dataMandatory: true,
  };

  function start() {
    setLoading(true);
    if (sdkInitiated) {
      clearSession();
    }
    console.log(process.env.REACT_APP_SOCURE_KEY)
    // eslint-disable-next-line no-undef
    SocureInitializer.init(process.env.REACT_APP_SOCURE_KEY).then((lib) => {
      lib.init(process.env.REACT_APP_SOCURE_KEY, "#websdk", config).then(function () {
        lib.start(1).then(
          function (response) {
            setSdkInitiated(true);
            setLoading(false);
          },
          //TODO: handle error 
          function (error) {
            console.log(error);
            // error
          }
        );
      });
    });
  }

  function clearSession() {
    // Socure?.cleanup();
    // Socure?.reset();
    sessionStorage.removeItem("documentVerificationToken");
    sessionStorage.removeItem("publicApiKey");
    localStorage.removeItem("devicer_id");
  }

  const launchSocureFlow = () => {
    const script = document.createElement("script");
    script.src = "https://websdk.socure.com/bundle.js";
    script.defer = true;
    script.onload = () => {
      start();
    };
    document.body.appendChild(script);
  };

  React.useEffect(() => {
    launchSocureFlow();
  }, []);

  React.useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = "https://js.dvnfo.com/devicer.min.js";
    script1.defer = true;
    script1.onload = function () {
      var deviceFPOptions = {
        publicKey: process.env.REACT_APP_SOCURE_KEY,
        userConsent: true,
        context: "signup",
      };
      // eslint-disable-next-line no-undef
      devicer.run(deviceFPOptions, function (response) {
        sessionIdRef.current = response?.sessionId;
      });
    };
    document.body.appendChild(script1);
  }, []);

  if (msg === "SOCURE_POST_SUCCESS" && socureStatus === 201) {
    dispatch(getAgreement(token)).then(() => navigate("/patriot-agreement"));
  }

  return (
    <div className="h-screen">
      <div className="flex justify-center items-center my-auto h-screen">{loading && <CircularProgress />}</div>
      <div id="websdk"></div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    socureStatus: state.kyc.socureStatus,
    msg: state.kyc.msg,
    userdata: state.auth.userdata,
    token:state.auth.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callPostSocureData: function (data, token) {
      dispatch(postSocureData(data, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Socure);

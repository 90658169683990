import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { createBrowserHistory } from "history";
import {
  getPricesHistory,
  requestSummary,
  getAccounts,
  orderPreview,
  previewSell,
} from "../../redux/services/homeServices";
import { getDetailedProfileNextStep, getIsSystemKycInprogress} from "../common/Utils";
import { resetHomeState } from "../../redux/action/homeAction";
import {
  Stack,
  Card,
  Box,
  CardActions,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  MenuItem,
  CardContent,
  TextField,
} from "@mui/material";
export const browserHistory = createBrowserHistory();

const currencies = [
  {
    value: "USD",
    label: "USD",
  },
];

const Order = (props) => {
  const [currencyList, setCurrencyList] = useState([]);
  const {
    token,
    userdata,
    priceHistory,
    orderPreviewDetails,
    callOrderPreview,
    callPreviewSell,
    callPriceHistory,
    previewSellFailResponse,
    previewBuyFailResponse,
  } = props;
  const [coinDetail, setCoinDetail] = useState();
  const [result, setResult] = useState([["Time", "Price"]]);
  const [orderCurrency, setOrderCurrency] = useState([...currencies]);
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [amount, setAmount] = useState(0.0);
  let { coinId } = useParams();
  const [orderType, setOrderType] = useState("buy");
  const [label, setLabel] = useState("Buy In");
  const [errorMessage, setErrorMessage] = useState();
  const [isAuthorizeForTrade, setIsAuthorizeForTrade] = useState(false);
  const [kycStatus, setKycStatus] = useState();
  const navigate = useNavigate();
  const tempOrderInCurrency = {
    value: coinId,
    label: coinId,
  };
  //setOrderCurrency([...orderCurrency, tempOrderInCurrency]);
  
  const [isSystemKycInprogress, setIsSystemKycInprogress] = useState(true);

  const handleOrderType = (event, value) => {
    setOrderType(value);
    if (value === "buy") {
      setLabel("Buy In");
      setOrderCurrency([...currencies]);
    } else {
      setLabel("Sell In");
      setOrderCurrency([...currencies, tempOrderInCurrency]);
    }
  };

  useEffect(() => {
    if (Object.keys(priceHistory).length === 0) {
      callPriceHistory(token);
    }
  }, [priceHistory, callPriceHistory]);

  useEffect(() => {
    setIsSystemKycInprogress(getIsSystemKycInprogress(userdata));
    
    const i =
      userdata.kyc_status === "not_started" ||
      userdata.kyc_status === "draft" ||
      userdata.kyc_status === "pending" ||
      userdata.kyc_status === "require_action"
        ? false
        : true;

    setIsAuthorizeForTrade(i);
    setKycStatus(userdata.kyc_status);

    //TODO support to buy in quantity.
    
  }, [userdata, coinId]);

  useEffect(() => {
    if (Object.keys(props.priceHistory).length !== 0) {
      setCurrencyList(props.priceHistory);
      const tempCoinDetail = currencyList[coinId];
      setCoinDetail(tempCoinDetail);
    }
  }, [props.priceHistory, currencyList, coinDetail, coinId]);

  useEffect(() => {
    const chartData = [];
    if (coinDetail) {
      for (var i in coinDetail.price_history)
        chartData.push([
          new Date(coinDetail.price_history[i].time),
          coinDetail.price_history[i].price,
        ]);
      setResult([...result, ...chartData]);
      // console.log("coinDetail", result);
    }
  }, [coinDetail, result]);

  useEffect(() => {
    if (previewBuyFailResponse) {
      setErrorMessage(previewBuyFailResponse);
    } else if (previewSellFailResponse) {
      setErrorMessage(previewSellFailResponse);
    }
  }, [previewBuyFailResponse, previewSellFailResponse]);

  const handleChange = (event) => {
    // console.log(event.target.value);
    setSelectedCurrency(event.target.value);
  };

  const handleChangeAmount = (event) => {
    setAmount(event.target.value);
  };

  const placeOrder = () => {
    // console.log("orderType-->", orderType);
    if (orderType === "buy") {
      // console.log("call buy-->");
      const request = {
        currency: coinId,
        total: amount,
        quoted_currency: "USD",
      };
      callOrderPreview(request, token);
    } else {
      // console.log("call sell-->", selectedCurrency);
      const request = {
        currency: coinId,
        total: selectedCurrency === "USD" ? amount : null,
        quantity: selectedCurrency !== "USD" ? amount : null,
        quoted_currency: selectedCurrency,
      };
      callPreviewSell(request, token);
    }
  };

  const authorizeCoinSpaze = () => {
    let nextStep = getDetailedProfileNextStep(userdata);
    navigate(nextStep.navigateTo);
  };

  useEffect(() => {
    // console.log("orderPreviewDetails-->", orderPreviewDetails);
  }, [orderPreviewDetails]);

  return (
    <Box>
      <Card>
        <CardActions>
          <ToggleButtonGroup
            value={orderType}
            exclusive
            onChange={handleOrderType}
            aria-label="order type"
            fullWidth={true}
          >
            <ToggleButton value="buy" size="large" aria-label="buy order">
              Buy
            </ToggleButton>
            <ToggleButton value="sell" size="large" aria-label="sell order">
              Sell
            </ToggleButton>
          </ToggleButtonGroup>
        </CardActions>
        {isAuthorizeForTrade && (
          <CardContent component={Stack} spacing={2}>
            {errorMessage && <Box size="medium">{errorMessage.message}</Box>}
            <TextField
              fullWidth={true}
              id="outlined-select-currency"
              className="form-item help-text-field"
              value={selectedCurrency}
              select
              label={label}
              onChange={handleChange}
              color="secondary"
            >
              {orderCurrency.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth={true}
              type="number"
              required
              InputProps={{
                inputProps: { min: 0.0 },
              }}
              id="outlined-required"
              label="Amount"
              placeholder="Amount"
              onChange={handleChangeAmount}
              variant="outlined"
            />
          </CardContent>
        )}
        {isAuthorizeForTrade && (
          <CardActions>
            <Button
              variant="contained"
              fullWidth={true}
              size="medium"
              onClick={placeOrder}
            >
              Preview
            </Button>
          </CardActions>
        )}
        {!isAuthorizeForTrade && !isSystemKycInprogress && (
          <CardActions>
            <Button
              variant="contained"
              fullWidth={true}
              size="medium"
              onClick={authorizeCoinSpaze}
            >
              Complete profile to start trading
            </Button>
          </CardActions>
        )}
        { isSystemKycInprogress && (
          <Box size="medium">
            {`Your Kyc check is ${kycStatus}. Please try after some time`}
          </Box>
        )}
      </Card>
    </Box>
  );
};

const mapStatetoProps = (state) => {
  return {
    token: state.auth.token,
    msg: state.auth.msg,
    status: state.auth.status,
    userdata: state.auth.userdata,
    priceHistory: state.home.priceHistory,
    userPortfolioSummary: state.home.userPortfolioSummary,
    userAccounts: state.home.userAccounts,
    orderPreviewDetails: state.home.orderPreviewDetails,
    previewSellFailResponse: state.home.previewSellFailResponse,
    previewBuyFailResponse: state.home.previewBuyFailResponse,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    callPriceHistory: function (token) {
      dispatch(getPricesHistory(token));
    },
    callRequestSummary: function (token) {
      dispatch(requestSummary(token));
    },
    callGetAccounts: function () {
      dispatch(getAccounts());
    },
    callOrderPreview: function (request, token) {
      dispatch(resetHomeState());
      dispatch(orderPreview(request, token));
    },
    callPreviewSell: function (request, token) {
      dispatch(resetHomeState());
      dispatch(previewSell(request, token));
    },
    callReset: function () {
      dispatch(resetHomeState());
    },
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(Order);

export const initialState = {
  createInvoiceResponse:{},
  invoiceListResponse:{},
  isOpen:false
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
      case "CREATE_INVOICE":
      return {
        createInvoiceResponse:action.payload,
      }
      case "INVOICE_LIST":
        return {
          invoiceListResponse:action.payload,
        }
      case "OPEN_INVOICE_DIALOG":{
        return {
          isOpen:action.payload
        } 
      }          
      case "RESET_AND_REDIRECT_HOME":
        return {
          isOpen:false
        } 
    default:
      return state;
  }
}

import * as React from "react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Alert, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getPaymentMethods } from "../../redux/services/paymentServices";
import { connect } from "react-redux";
import { Card, CardContent, Typography } from "@mui/material";

function Profile(props) {
  const { callGetPaymentMethods, bankAccounts, status, token } = props;
  const [nextPage, setNextPage] = React.useState(null);
  const navigate = useNavigate();
  React.useEffect(() => {
    if (nextPage !== null) {
      navigate(nextPage);
    }
  }, [nextPage, navigate]);
  const handleCloseUserMenu = (action) => {
    // console.log(action);
    if (action !== "none") {
      setNextPage(action);
    }
  };

  React.useEffect(() => {
    if(token !== ""){
      callGetPaymentMethods(token);
    }
  }, [callGetPaymentMethods, token]);

  const handleNavigation = (remote_id) => {
    if (remote_id) {
      navigate("/amount", { state: { remote_id } });
    }
  };

  return (
    <>
      <div className="login_wrapper">
        <Container maxWidth="lg">
        {status === 400 && (
              <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                Please enter correct information.
              </Alert>
            )}
            {status === 422 && (
              <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                Your request could not be processed.
              </Alert>
            )}
            {status === 403 && (
              <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                You are not authorised to perform this action.
              </Alert>
            )}
            {status === 500 && (
              <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                Internal Server Error
              </Alert>
            )}
          <Box sx={{ p: "20px", mt: "1%" }} className="shadow-2xl">
            <p style={{ paddingTop: "10px", paddingBottom: "10px", textAlign: "center", fontSize: "32px" }}>
              Disbursement Accounts
            </p>
            {bankAccounts?.length > 0 && (
              <div className="text-center text-2xl my-4 h1">Choose an account to transfer the money to</div>
            )}
            <div className=" md:grid-rows-auto container mx-auto grid max-w-5xl justify-items-center p-0 opacity-100 gap-y-2 md:grid-cols-2 md:gap-y-8 md:gap-x-16 lg:grid-cols-3 lg:grid-rows-2 lg:gap-y-2 lg:gap-x-32">
              {bankAccounts &&
                bankAccounts.filter((item, index) => item.funds_transfer_type !== "credit_card").map((item, index) => {
                  return (
                    <Card
                      sx={{ minWidth: 275, border: 1 }}
                      key={index}
                      className="hover:cursor-pointer hover:bg-gray-100 hover:shadow-lg"
                      onClick={() => handleNavigation(item.remote_id)}
                    >
                      <CardContent>
                        <Box sx={{justifyContent:"flex-start", display:'flex'}}>
                          <Typography sx={{bgcolor: 'gray', color:'white', px:1}} component={"span"}>
                            {item?.funds_transfer_type?.toUpperCase()}
                          </Typography>
                        </Box>
                        <div>
                          <span className="font-semibold">Account Name:</span> {item.bank_account_name}
                        </div>
                        <div>
                          <span className="font-semibold">Account Number:</span>{" "}
                          {"*****" + item.last4}
                        </div>
                        <div>
                          <span className="font-semibold">Bank Account Type:</span> {item.bank_account_type}
                        </div>
                      </CardContent>
                    </Card>
                  );
                })}
            </div>
            <div className="flex justify-center mb-2 gap-4 mt-4">
              <Button variant="outlined" color={"primary"} onClick={() => handleCloseUserMenu("/transfer")}>
                Back
              </Button>
              <Button variant="contained" color={"primary"} onClick={() => handleCloseUserMenu("/bank-account-detail")}>
                Add New
              </Button>
            </div>
          </Box>
        </Container>
      </div>
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    token:state.auth.token,
    bankAccounts: state.payment.bankAccounts,
    status: state.payment.status,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    callGetPaymentMethods: function (token) {
      dispatch(getPaymentMethods(token));
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Profile);

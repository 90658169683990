import { jsPDF } from "jspdf";

const CoinSpazePdfFromReact = async (target, orientation, resize) => {
    await new Promise(resolve => {
        if (resize) {
            document.querySelector(target).style.width =
            orientation === "p" ? "600px" : "841px";
            document.querySelector(target).style.minHeight =
            orientation === "p" ? "841px" : "595px";
        }

        let pdf = new jsPDF(orientation, "pt", "a4");
        pdf.html(document.querySelector(target), {
            callback: (doc) => {
                let outputFile = doc.output();  
                localStorage.setItem("invoice-pdf-blob",outputFile);
                setTimeout(resolve, 3000);
        }})
        
    });
}

export const CoinSpazePdfFromReactDownload = async (target, orientation, resize) => {
    await new Promise(resolve => {
        if (resize) {
            document.querySelector(target).style.width =
            orientation === "p" ? "600px" : "841px";
            document.querySelector(target).style.minHeight =
            orientation === "p" ? "841px" : "595px";
        }

        let pdf = new jsPDF(orientation, "pt", "a4");
        pdf.html(document.querySelector(target), {
            callback: (doc) => {
                doc.save('Invoice.pdf');  
                setTimeout(resolve, 3000);
        }})
        
    });
}

export { CoinSpazePdfFromReact };
import React from "react";

import { connect } from "react-redux";

import { createBrowserHistory } from "history";
export const browserHistory = createBrowserHistory();

function uploadSuccess(props) {
  return (
    <div className="login_wrapper">
		<div className="container">
			<div className="login_header">
				<div className="row">
					<div className="col-lg-6">
            <img className="logo-style" src="./images/logo.png" alt="logo" />
					</div>
				</div>
			</div>
		</div>
		<div>
			Success
		</div>
	</div>
  );
}

const mapStatetoProps = (state) => {
  return {
    token: state.auth.token,
    msg: state.auth.msg,
    status: state.auth.status,
    userdata: state.auth.userdata,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    LoginWithTempDetails: function (email, password) {
      //dispatch(getAgreement());
    },
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(uploadSuccess);

const REDIRECT_HOME = "REDIRECT_HOME";
const REDIRECT_HOME_WITH_PORTFOLIO_SUMMARY = "REDIRECT_HOME_WITH_PORTFOLIO_SUMMARY";
const REDIRECT_HOME_WITH_ACCOUNTS = "REDIRECT_HOME_WITH_ACCOUNTS";
const REDIRECT_HOME_WITH_ORDER_PREVIEW_SUCCESS = "REDIRECT_HOME_WITH_ORDER_PREVIEW_SUCCESS";
const REDIRECT_PREVIEW_BUY_FAIL = "REDIRECT_PREVIEW_BUY_FAIL";
const REDIRECT_ORDER_SUCCESS = "REDIRECT_ORDER_SUCCESS";
const REDIRECT_ORDER_FAIL = "REDIRECT_ORDER_FAIL";
const REDIRECT_PREVIEW_SELL_SUCCESS = "REDIRECT_PREVIEW_SELL_SUCCESS";
const REDIRECT_PREVIEW_SELL_FAIL = "REDIRECT_PREVIEW_SELL_FAIL";
const REDIRECT_SELL_SUCCESS = "REDIRECT_SELL_SUCCESS";
const REDIRECT_SELL_FAIL = "REDIRECT_SELL_FAIL";
const RESET_AND_REDIRECT_HOME = "RESET_AND_REDIRECT_HOME";
const GET_LATEST_TXN_SUCCESS = "GET_LATEST_TXN_SUCCESS";
const GET_LATEST_TXN_FAIL = "GET_LATEST_TXN_FAIL";
const REDIRECT_HOME_WITH_PORTFOLIO_SUMMARY_FAIL = "REDIRECT_HOME_WITH_PORTFOLIO_SUMMARY_FAIL";

export function getPriceHistorySuccess(response) {
  return {
    type: REDIRECT_HOME,
    payload: response,
  }
}

export function getPortfolioSummarySuccess(response) {
  return {
    type: REDIRECT_HOME_WITH_PORTFOLIO_SUMMARY,
    payload: response,
  }
}
export function getPortfolioSummaryFail(response) {
  return {
    type: REDIRECT_HOME_WITH_PORTFOLIO_SUMMARY_FAIL,
    payload: response,
  }
}

export function getAccountSuccess(response) {
  return {
    type: REDIRECT_HOME_WITH_ACCOUNTS,
    payload: response
  }
}

export function orderPreviewSuccess(response) {
  return {
    type: REDIRECT_HOME_WITH_ORDER_PREVIEW_SUCCESS,
    payload: response
  }
}

export function orderPreviewFail(response) {
  return {
    type: REDIRECT_PREVIEW_BUY_FAIL,
    payload: response
  }
}

export function orderConfirmSuccess(response) {
  return {
    type: REDIRECT_ORDER_SUCCESS,
    payload: response
  }
}

export function orderConfirmFail(response) {
  return {
    type: REDIRECT_ORDER_FAIL,
    payload: response
  }
}

export function previewSellSuccess(response) {
  return {
    type: REDIRECT_PREVIEW_SELL_SUCCESS,
    payload: response
  }
}

export function previewSellFail(response) {
  return {
    type: REDIRECT_PREVIEW_SELL_FAIL,
    payload: response
  }
}

export function sellSuccess(response) {
  return {
    type: REDIRECT_SELL_SUCCESS,
    payload: response
  }
}

export function sellFail(response) {
  return {
    type: REDIRECT_SELL_FAIL,
    payload: response
  }
}

export function getLatestTransactionsSuccess(response) {
  return {
    type: GET_LATEST_TXN_SUCCESS,
    payload: response
  }
}

export function getLatestTransactionsFail(response) {
  return {
    type: GET_LATEST_TXN_FAIL,
    payload: response
  }
}

export function resetHomeState() {
  return {
    type: RESET_AND_REDIRECT_HOME,
  }
}


import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setupProfilestep1Service } from "../../redux/services/authServices";
import moment from "moment";
import { Navigate, useNavigate } from "react-router-dom";
import COUNTRIES from "../../config/countries";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Alert, AlertTitle, TextField, MenuItem, List, ListItem, ListItemText } from "@mui/material";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";

import "react-datepicker/dist/react-datepicker.css";
import { BackButton } from "../BackButton";
import { getKycRequiredAction } from '../common/Utils';

function SetupProfilestep1(props) {
  const navigate = useNavigate();
  if (!props.userdata) {
    navigate("/login");
  }

  const { userdata, token } = props;
  const [fname, setFname] = useState(userdata["first_name"]);
  const [mname, setMname] = useState("");
  const [lname, setLname] = useState(userdata["last_name"]);
  
  const [phone, setPhone] = useState(userdata["phone_number"]);
  const [phoneNumberCountry, setPhoneNumberCountry] = useState(
    props.userdata["phone_number_country"]?? ''
  );
  const [dob, setDob] = useState(userdata["date_of_birth"]);

  const [fnameerrors, setfnameErrors] = useState(false);
  const [lnameerrors, setlnameErrors] = useState(false);
  const [doberrors, setdobErrors] = useState(false);
  const [phoneerrors, setphoneErrors] = useState(false);

  const [isActionRequire, setIsActionRequire] = useState(false);
  const [requireActionList, setRequireActionList] = useState();

  const eighteenYearsAgo = new Date((new Date().setFullYear(
    new Date().getFullYear() - 18
  ))).toISOString().split("T")[0];

  function isValidBirth(event) {
    const age = moment().diff(moment(event.target.value), "year");

    if (age >= 18) {
      setdobErrors(false);
      setDob(event.target.value);
    } else {
      setdobErrors("Age can not be less than 18 years");
      setDob("");
    }
  }
  function handlemousewheel(event) {
    return false;
  }
  function handleChange(event) {
    const { name, value } = event.target;
    switch (name) {
      case "fname":
        let reg =
          /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-_]+$/u;

        if (reg.test(value)) {
          setfnameErrors(false);
          setFname(value);
        } else {
          setfnameErrors("Enter First Name! Allow only alpha/space/dot !!");
          setFname();
        }
        break;
      case "dob":
        if (value === "") {
          setdobErrors("Select BirthDate, Age should above 18 years!!");
          setDob("");
        } else {
          setdobErrors(false);
          setDob(value);
        }
        break;
      case "phone":
        if (/(^\d{10}$)|(^\d{10}-\d{9}$)/.test(value)) {
          setphoneErrors("");
          setPhone(value);
        } else {
          setphoneErrors("Enter 10 digit without special characters!");
          setPhone();
        }
        break;
      case "lname":
        if (value === "") {
          setlnameErrors("Enter Last Name!");
          setLname("");
        } else {
          setlnameErrors(false);
          setLname(value);
        }
        break;
      case "mname":
        setMname(value);
        break;
      case "phoneNumberCountry":
        setPhoneNumberCountry(value);
        break;
      default:
        // setDisable(false)
        break;
    }
  }

  useEffect(() => {
    let kycRequiredActionState = getKycRequiredAction(userdata);
    setIsActionRequire(kycRequiredActionState.isActionRequire);
    if(isActionRequire){
      setRequireActionList(kycRequiredActionState.requireActionList);
    }
  }, [userdata, isActionRequire])

  if (props.userdata !== "" && props.msg === "Fill More Details!!") {
    return  <Navigate to={{ pathname: "/kyc" }} />;
  }

  const requireAction = () => {
    if (requireActionList && requireActionList.length>0) {
      return Object.keys(requireActionList).map((key, index) => {
        return (
            <ListItem>
              <ListItemText
                primary={`${requireActionList[key]}`}
              />
            </ListItem>
        );
      });
    }
  };

  return (
    <div className="login_wrapper" >
      <Container className="relative">
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "48ch" },
          }}
          autoComplete="off"
          className="md:pt-32"
        > 
        { isActionRequire && <div className = "mb-4 text-red">
          <Alert variant="outlined" severity="error">
            <AlertTitle>Error</AlertTitle>
            <List style={{ backgroundColor: "paper", borderRadius: "20px" }}>
              {requireAction()}
            </List>
          </Alert>
          </div>}
          <Grid container spacing={1} className="pt-16">
            <Grid item>
              <TextField
                required
                id="outlined-required"
                label="First Name"
                placeholder="First Name"
                name="fname"
                defaultValue={props.userdata["first_name"]}
                onChange={handleChange}
                error={fnameerrors}
                helperText={fnameerrors}
              />
            </Grid>
            <Grid item>
              <TextField
                id="Middle Name"
                label="Middle Name"
                placeholder="Middle Name"
                name="mname"
                defaultValue={props.userdata["middle_name"]}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item>
              <TextField
                id="Last Name"
                label="Last Name"
                placeholder="Last Name"
                name="lname"
                defaultValue={props.userdata["last_name"]}
                onChange={handleChange}
                error={lnameerrors}
                helperText={lnameerrors}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <TextField
                id="dob"
                name="dob"
                label="Enter your Date of Birth"
                type="date"
                placeholder="Enter your Date of Birth"
                onChange={isValidBirth}
                onBlur={isValidBirth}
                selected={dob}
                error={doberrors}
                helperText={doberrors}
                sx={{ width: 220 }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{inputProps: { max: eighteenYearsAgo} }}
                defaultValue={dob}
              />
            </Grid>
          </Grid>
          <Box className="mt-4">Phone Number</Box>
          <Box className="flex justify-start align-top w-[220px] ">
            <Box className="p-2">
              <Select
                name="phoneNumberCountry"
                value={phoneNumberCountry}
                onChange={handleChange}
                onBlur={handleChange}
                required
                label="Country Code"
              >
                {COUNTRIES.map((obj, index) => {
                  return (
                    <MenuItem value={obj.name} key={obj.dial_code + Math.random() + index}>
                      ({obj.dial_code}) {obj.code}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>

            <Box className="pr-4">
              <TextField
                type="number"
                name="phone"
                defaultValue={props.userdata["phone_number"]}
                onChange={handleChange}
                onBlur={handleChange}
                required
                maxLength={9}
                onWheel={handlemousewheel}
                label="Phone Number"
                error={phoneerrors}
                helperText={phoneerrors}
                className=" w-[300px]"
              />
            </Box>
          </Box>
          <div className="flex justify-center gap-8">
             <BackButton path={"/"} /> 
            <Button
              color="primary"
              className="px-4 py-2 uppercase"
              variant="contained"
              disabled={!fname || !lname || !dob || !phone}
              onClick={() =>
                props.saveProfileStep1(
                  fname,
                  mname,
                  lname,
                  dob,
                  phone,
                  phoneNumberCountry,
                  token
                )
              }
            >
              Next
            </Button>

          </div>
        </Box>
      </Container>
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    token: state.auth.token,
    msg: state.auth.msg,
    userdata: state.auth.userdata,
    latestuserdata: state.auth.latestuserdata,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    saveProfileStep1: function (
      fname,
      mname,
      lname,
      dob,
      phone,
      phoneNumberCountry,
      token,
    ) {
      // console.log(mname);
      dispatch(
        setupProfilestep1Service(
          fname,
          mname,
          lname,
          dob,
          phone,
          phoneNumberCountry,
          token
        )
      );
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(SetupProfilestep1);
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Chart } from "react-google-charts";
import { createBrowserHistory } from "history";
import {
  getPricesHistory,
  requestSummary,
  getAccounts,
} from "../../redux/services/homeServices";
import Order from "./Order";
import { append } from "ramda";
import OrderPreview from "./OrderPreview";
import OrderSuccess from "./OrderConfirm";
import {
  Box,
  Container,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
  Avatar,
} from "@mui/material";
export const browserHistory = createBrowserHistory();

const options = {
  title: "Price History",
  curveType: "function",
  chartArea: { width: "80%", height: "80%" },
  legend: {
    position: "bottom",
  },
};

const Crypto = (props) => {
  const [currencyList, setCurrencyList] = useState([]);
  const {
    token,
    priceHistory,
    orderPreviewDetails,
    orderSuccessResponse,
    previewSellSuccessResponse,
    sellSuccessResponse,
  } = props;
  const [coinDetail, setCoinDetail] = useState();
  const [result, setResult] = useState([["Time", "Price"]]);
  let { coinId } = useParams();

  useEffect(() => {
    if (Object.keys(priceHistory).length === 0 && token !== "") {
      props.callPriceHistory(token);
    }
  }, [token]);

 
  useEffect(() => {
    if (Object.keys(props.priceHistory).length !== 0) {
      setCurrencyList(props.priceHistory);
      const tempCoinDetail = currencyList[coinId];
      setCoinDetail(tempCoinDetail);
    }
  }, [props.priceHistory, currencyList, coinDetail]);

  useEffect(() => {
    if (coinDetail) {
      const chartData = coinDetail.price_history.reduce((previous, current) => {
        return append([new Date(current.time), current.price], previous);
      }, []);

      setResult([...result, ...chartData]);
      // console.log("coinDetail", result);
    }
  }, [coinDetail]);

  useEffect(() => {
    // console.log("orderPreviewDetails-->", orderPreviewDetails);
  }, [orderPreviewDetails]);

  return (
    <>
      <Container sx={{ marginTop: 12 }}>
        <Box>
          <Grid container>
            <Grid item lg={8} xs={12}>
              <Paper>
                {coinDetail && (
                  <ListItem key={coinDetail.currency}>
                    <ListItemAvatar>
                      <Avatar
                        src={`./../images/coin-icons/${coinDetail.icon_file_name}.png`}
                        size="40"
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={<Typography>{coinDetail.currency}</Typography>}
                      secondary={
                        <Typography>${coinDetail.current_price}</Typography>
                      }
                    />
                  </ListItem>
                )}
              </Paper>
              <Paper>
                {result.length > 1 && (
                  <Chart
                    chartType="LineChart"
                    width="100%"
                    height="400px"
                    data={result}
                    options={options}
                  />
                )}
              </Paper>
            </Grid>
            <Grid item lg={4} xs={12} sx={{m:"auto"}}>
              {orderPreviewDetails === undefined &&
                orderSuccessResponse === undefined &&
                previewSellSuccessResponse === undefined &&
                sellSuccessResponse === undefined && <Order />}
              {(orderPreviewDetails || previewSellSuccessResponse) && (
                <OrderPreview />
              )}
              {(orderSuccessResponse || sellSuccessResponse) && (
                <OrderSuccess />
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    msg: state.auth.msg,
    status: state.auth.status,
    userdata: state.auth.userdata,
    priceHistory: state.home.priceHistory,
    userPortfolioSummary: state.home.userPortfolioSummary,
    userAccounts: state.home.userAccounts,
    orderPreviewDetails: state.home.orderPreviewDetails,
    orderSuccessResponse: state.home.orderSuccessResponse,
    orderFailResponse: state.home.orderFailResponse,
    previewSellSuccessResponse: state.home.previewSellSuccessResponse,
    sellSuccessResponse: state.home.sellSuccessResponse,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callPriceHistory: function (token) {
      dispatch(getPricesHistory(token));
    },
    callRequestSummary: function (token) {
      dispatch(requestSummary(token));
    },
    callGetAccounts: function (token) {
      dispatch(getAccounts(token));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Crypto);

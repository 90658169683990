import React, { useEffect } from "react";

import { connect } from "react-redux";

import { getWTI } from "../../redux/services/paymentServices";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";

const Profile = (props) => {
  const { wtiSuccessResponse, requestWTI, token } = props;

  useEffect(() => {
    if(token !== ""){
      requestWTI(token);
    }
  }, [token]);

  useEffect(() => {
    // console.log("wtiSuccessResponse-->", wtiSuccessResponse);
  }, [wtiSuccessResponse]);

  return (
    <>
      <div className="login_wrapper">
        <Container maxWidth="lg">
          <Box sx={{ p: "20px", mt: "1%" }} className="shadow-xl">
            <p style={{ textAlign: "center", fontSize: "32px" }}>Wire Transfer Instruction</p>
            {wtiSuccessResponse && (
              <Button key={"Withdrawable Cash"} tabIndex={1} sx={{ textAlign: "center" }}>
                <table style={{ marginLeft: "auto", marginRight: "auto", width: "70%" }}>
                  <tbody>
                    <tr>
                      <td style={{ border: "1px solid gray " }}>Account Number</td>
                      <td style={{ border: "1px solid gray" }}>{wtiSuccessResponse.account_number}</td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid gray" }}>Bank Phone</td>
                      <td style={{ border: "1px solid gray" }}>{wtiSuccessResponse.bank_address}</td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid gray" }}>Bank Address</td>
                      <td style={{ border: "1px solid gray" }}>{wtiSuccessResponse.bank_phone}</td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid gray" }}>Beneficiary Address</td>
                      <td style={{ border: "1px solid gray" }}>{wtiSuccessResponse.beneficiary_address}</td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid gray" }}>Credit To</td>
                      <td style={{ border: "1px solid gray" }}>{wtiSuccessResponse.credit_to}</td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid gray" }}>Depository Bank Name</td>
                      <td style={{ border: "1px solid gray" }}>{wtiSuccessResponse.depository_bank_name}</td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid gray" }}>Reference</td>
                      <td style={{ border: "1px solid gray" }}>{wtiSuccessResponse.reference}</td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid gray" }}>Routing Number</td>
                      <td style={{ border: "1px solid gray" }}>{wtiSuccessResponse.routing_number}</td>
                    </tr>
                  </tbody>
                </table>
              </Button>
            )}
            <div
              className="d-flex"
              style={{ padding: "30px", margin: "40px", alignItems: "center", border: "1px solid gray white" }}
            >
              <InfoIcon sx={{ width: "20px", height: "20px", marginRight: "70px" }} fontSize="small" />
              <div style={{ textAlign: "left" }}>
                <p>All incoming wires are subjected to following fee:</p>
                <p style={{ marginLeft: "10px" }}>Domestic:$15 pr transaction</p>
                <p style={{ marginLeft: "10px" }}>International:$35 per transaction</p>
                <p style={{ marginLeft: "10px" }}>Recalls:$50 per transaction</p>
              </div>
            </div>
          </Box>
        </Container>
      </div>
    </>
  );
};

const mapStatetoProps = (state) => {
  return {
    token: state.auth.token,
    wtiSuccessResponse: state.payment.wtiSuccessResponse,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    requestWTI: function (token) {
      dispatch(getWTI(token));
    },
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(Profile);

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import PreviewInvoice from "./PreviewInvoice";
import { connect } from "react-redux";

function InvoiceDialog(props) {
  const { onClose, selectedValue, open, rcvSccRes, invoice, createInvoiceRequest } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  useEffect(() => {
    // console.log(rcvSccRes);
  }, [rcvSccRes])

  return (
    <Dialog onClose={handleClose} open={open}>
      <PreviewInvoice invoice={invoice} createInvoiceRequest={createInvoiceRequest}/>
    </Dialog>
  );
}

InvoiceDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  selectedValue: PropTypes.string,
  invoice: PropTypes.any,
  createInvoiceRequest:PropTypes.any,
};

const mapStatetoProps = (state) => {
  return {
    rcvSccRes: state.wallet.receiveSuccessResponse,
  };
};

export default connect(mapStatetoProps, null)(InvoiceDialog)

export const initialState = {
  sendSuccessResponse:null,
  sendFailResponse:null,
  receiveSuccessResponse:null,
  receiveFailResponse:null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
      case "SEND_SUCCESS":
        return {
          ...state,
          status: action.payload.status,
          sendSuccessResponse: action.payload.data,
          msg: "SEND_SUCCESS"
        }
      case "SEND_FAIL":
        return {
          ...state,
          status: action.payload.status,
          sendFailResponse: action.payload,
          msg: "SEND_FAIL"
        }
      case "RECEIVE_SUCCESS":
        return {
          ...state,
          status: action.payload.status,
          receiveSuccessResponse: action.payload.data,
          receiveFailResponse: null,
          msg: "RECEIVE_SUCCESS"
        }
      case "RECEIVE_FAIL":
        return {
          ...state,
          status: action.payload.status,
          receiveFailResponse: action.payload,
          receiveSuccessResponse: null,
          msg: "RECEIVE_FAIL"
        }
      case "RESET_WALLET":
        return initialState;
    default:
      return state;
  }
}

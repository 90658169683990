const GET_MFA_SUCCESS = "GET_MFA_SUCCESS";
const GET_MFA_FAIL = "GET_MFA_FAIL";
const GET_HELP_SUCCESS = "GET_HELP_SUCCESS";
const GET_HELP_FAIL = "GET_HELP_FAIL";
const RESET_STATUS = "RESET_STATUS";
const RESET_HELP_STATUS = "RESET_HELP_STATUS";
const SEND_INVITATION_RESPONSE = "SEND_INVITATION_RESPONSE";

export function getMFASuccess(response) {
  // console.log('Action getMFASuccess-->',response);
  return {
    type: GET_MFA_SUCCESS,
    payload: response,
  };
}

export function getMFAFail(response) {
  // console.log('Action getMFAFail-->',response);
  return {
    type: GET_MFA_FAIL,
    payload: response,
  };
}
export const helpSuccess = (response) => {
  return {
    type: GET_HELP_SUCCESS,
    payload: response,
  };
};
export const helpFail = (response) => {
  return {
    type: GET_HELP_FAIL,
    payload: response,
  };
};

export const sendInvitationResponse = (response) => {
  return {
    type: SEND_INVITATION_RESPONSE,
    payload: response,
  };
}

export const resetStatus = () => {
  return {
    type: RESET_STATUS,
    payload: "",
  };
};

const CREATE_INVOICE = "CREATE_INVOICE";
const INVOICE_LIST = "INVOICE_LIST";
const UPLOAD_INVOICE = "UPLOAD_INVOICE";
const RESET_AND_REDIRECT_HOME = "RESET_AND_REDIRECT_HOME";
const OPEN_INVOICE_DIALOG = "OPEN_INVOICE_DIALOG";

export function createInvoiceResponse(response){
  return {
    type: CREATE_INVOICE,
    payload: response
  }
}

export function getInvoiceListResponse(response){
  return {
    type: INVOICE_LIST,
    payload: response
  }
}

export function uploadInvoiceResponse(response){
  return {
    type: UPLOAD_INVOICE,
    payload: response
  }
}

export function resetInvoiceState() {
  return {
    type: RESET_AND_REDIRECT_HOME,
  }
}

export function openDialog(payload){
  return {
    type: OPEN_INVOICE_DIALOG,
    payload
  }
}
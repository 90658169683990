var initialState = {
  isLoggedIn: false,
  token: "",
  emailId: "",
  userId: "",
  msg: "",
  agreement:{},
  loginUserDetails: {},
  userdata: JSON.parse(localStorage.getItem("userdata")),
  latestuserdata: JSON.parse(localStorage.getItem("latestuserdata")),
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case "REDIRECT_TO_LOGIN":
      return {
        ...state,
        status: action.payload.status,
        useverificationstatus: action.useverificationstatus,
        msg: "Kindly login with email id and temporary password!!",
      };
    case "REDIRECT_TO_Register":
      return {
        ...state,
        useverificationstatus: action.useverificationstatus,
        msg: "You dont have account with provided emaild id, Kindly register!!",
      };
    case "LOGIN_USER":
      return {
        ...state,
        msg: action.payload,
        isLoggedIn: action.isLoggedIn,
        loginUserDetails: action.loginUserDetails,
      };
    case "SET_CURRENT_USER":
      // console.log("step#2" + action.payload);
      return {
        ...state,
        loginUserDetails: action.payload,
        isLoggedIn: true,
      };
    case "REGISTER_USER":
      return {
        ...state,
        allUsers: "",
        msg: "",
      };
    case "REDIRECT_TO_UPDATE_PWD":
      return {
        ...state,
        token: action.payload,
        msg: action.msg,
      };
    case "SETUP_PROFILE_STEP1":
      return {
        ...state,
        token: action.payload,
        msg: action.msg,
      };
    case "ERROR":
      return {
        ...state,
        token: action.payload,
        msg: action.msg,
      };
    case "DB_ERROR":
      return {
        ...state,
        allUsers: JSON.parse(action.payload),
        msg: JSON.parse(action.payload).message,
      };
    case "REDIRECT_TO_RESET_PWD":
      return {
        ...state,
        token: action.payload.data.token,
        status: action.payload.status,
        msg: "Kindly reset your password!!",
      };
    case "REDIRECT_TO_KYC_STEP_1":
      return {
        ...state,
        status: action.payload.status,
        userdata: action.payload.data,
        msg: "Complete Your Profile!!",
      };
    case "REDIRECT_TO_KYC_STEP_2":
      return {
        ...state,
        status: 200,
        latestuserdata: action.payload,
        msg: "Fill More Details!!",
      };
    case "CLEAR_MSG" :
      return {
        ...state,
        msg: ""
      }
    case "REDIRECT_TO_KYC_STEP_3":
      return {
        ...state,
        status: action.payload.status,
        latestuserdata: action.payload,
        userdata: action.payload.data,
        msg: "REDIRECT_TO_SOCURE_FLOW",
      };
    case "REDIRECT_SHOW_AGREEMENT":
      return {
        ...state,
        status: action.payload.status,
        agreement: action.payload.data,
        msg: "REDIRECT_SHOW_AGREEMENT",
      };
    case "SET_TOKEN":
    return {
      ...state,
      token:action.payload,
    };
    case "API_ERROR":
      // console.log(action);
      return {
        ...state,
        msg: action.message,
        data: "",
        status: action.payload.status,
      };
    default:
      return state;
  }
}

import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Faq() {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>How to invite people?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <a href='https://youtube.com/shorts/Dcerp9VVLiI?feature=share' target="_blank">How to invite?</a>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><b>Why was my account closed?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We are sorry but we can't approve your account at this point. Our compliance team reviews each and every account creation request and some of them do not pass their criteria due to the risk associated with AML, CIP, and additional Compliance and Fraud checks.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><b>How to withdraw?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div>1. You can pay another friend of yours on CoinSpaze. The transfer of BTC to another user CoinSpaze customer will be free.</div>
          <div>2. You can transfer it to your other BTC wallet outside CoinSpaze. <a href='https://youtu.be/7bDmgLCT31A' target="_blank">See the video </a></div>
          <div>3. You can sell your BTC and then withdraw cash. At present, we only offer wire transfers to withdraw and wire transfers are subject to a $15 fee. We are adding ACH transfer which will be much more cost-effective as compared to wire transfers.</div>

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><b>How to upload additional documents?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div>Please upload the documents on CoinSpaze app.</div> 
          <div>You can use these instructions to upload the docs: <a href='https://youtu.be/kELVEhHnfgg' target="_blank">See the video </a></div>  
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><b>When do I get my reward credit?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div>If you are eligible for the reward, your account will be credited once your account is opened.</div> 
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><b>How long does it take to transfer?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div>All the internal transfers to other CoinSpaze users are instantaneous. The external transfer, if submitted during business hours, should get settled in a few minutes.</div>
          <div> If submitted outside business hours, it'd settle the next business day. We are adding support for the instant external transfer soon.</div> 
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><b>Why did I see a message mentioning unprocessable entity when I was trying to sign-up?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div>Sorry you are having trouble signing up. In most of the cases it would mean that we are unable to approve your account at this point. Contact us if you have further question.</div> 
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><b>How long does it take to get approved?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p>It takes anywhere between a few minutes to a few days. </p>
          <p>Our compliance team reviews each and every account creation request and some of them do not pass their criteria due to the risk associated with AML, CIP, and additional Compliance and Fraud checks.</p>
          <p> Thank you for being patient with us. We will let you know if anything is needed from your side.</p> 
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><b>Why haven't I been paid for my referrals?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div>Your account will be credited for each of the referrals you made provided they get approved by CoinSpaze. The referral and signup should happen while the reward program is active to be eligible for the referral and sign-up reward.</div> 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><b>How to Setup “Two Factor Authentication”?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div><a href='https://drive.google.com/file/d/13UeZCqqExMZIEoFSEPqDwZ_OGCI8xQog/view?usp=sharing' target="_blank">Two Factor Authentication</a></div> 
          </Typography>
        </AccordionDetails>
      </Accordion>
      
    </div>
  );
}
export const coinToLogo = new Map();

coinToLogo.set('btc', 'bitcoin-btc-logo.png');
coinToLogo.set('ltc', 'litecoin-ltc-logo.png');
coinToLogo.set('doge', 'dogecoin-doge-logo.png');
coinToLogo.set('eth', 'ethereum-eth-logo.png');

export const acceptedPaymentCoins = [
    { key: 'BTC', label: 'BTC', avatar:'/images/coin-icons/bitcoin-btc-logo.png', defaultAccepted:true },
    { key: 'ETH', label: 'ETH', avatar:'/images/coin-icons/ethereum-eth-logo.png', defaultAccepted:true },
    { key: 'LTC', label: 'LTC', avatar:'/images/coin-icons/litecoin-ltc-logo.png', defaultAccepted:true },
];
var initialState = {
  isLoggedIn: false,
  token: "",
  emailId: "",
  userId: "",
  msg: "",
  agreement:{},
  loginUserDetails: {},
  userdata: JSON.parse(localStorage.getItem("userdata")),
  latestuserdata: JSON.parse(localStorage.getItem("latestuserdata")),
  socureStatus: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case "AGREEMENT_ACCEPT_SUCCESS":
      return {
        ...state,
        status: action.payload.status,
        msg: "AGREEMENT_ACCEPT_SUCCESS",
      };
      case "REDIRECT_UPLOAD_DOCUMENTS_SUCCESS":
      return {
        ...state,
        status: action.payload.status,
        msg: "REDIRECT_UPLOAD_DOCUMENTS_SUCCESS",
      };
      case "SOCURE_POST_SUCCESS":
      return {
        ...state,
          socureStatus: action.payload.status,
        msg: "SOCURE_POST_SUCCESS",
      };
      case "SOCURE_POST_FAILURE":
      return {
        ...state,
        socureStatus: action.payload.status,
        msg: "SOCURE_POST_FAILURE",
      };
      case "RESET_AND_REDIRECT_KYC":
        return {
          ...state,
          status: undefined,
          msg: undefined
        }; 
    default:
      return state;
  }
}

import * as React from "react";
import { connect } from "react-redux";
import { Box, Button, Container, Snackbar, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { sendInvite } from "../../redux/services/settingsServices";
import { resetStatus } from "../../redux/action/settingAction";

import MuiAlert from "@mui/material/Alert";
import { Alert } from "@mui/material";

const AlertBar = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Invite = (props) => {
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const navigate = useNavigate();
  const { callSendInvite, status, callResetStatus, token, invitationResponse } = props;
  const [disabled, setDisabled] = React.useState(false);
  
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    data = {
      to_email: data.email,
    };
    setDisabled(true);
    callSendInvite(JSON.stringify(data), token);
  };

  React.useEffect(() => {
    setValue("email", '');
  }, []);

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  React.useEffect(() => {
    if (invitationResponse?.status === 201) {
      setOpenSnackBar((prev) => true);
      setTimeout(() => {
        navigate("/home");
      }, [3000]);
    }
  }, [invitationResponse]);

  React.useEffect(() => {
    return () => {
      callResetStatus();
    }
  }, []);

  return (
    <>
      <div className="login_wrapper login_wrapper-bottom-margin">
        <Container maxWidth="md">
          <Box component="form" className="coinspaze-form shadow-2xl" sx={{ p: "20px", mt: "5%" }}>
            {status === 400 && (
              <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                {invitationResponse?.message}
              </Alert>
            )}
            {status === 422 && (
              <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                Your request could not be processed.
              </Alert>
            )}
            {status === 403 && (
              <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                You are not authorised to perform this action.
              </Alert>
            )}
            {status === 409 && (
              <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                An account already exists for the destination email.
              </Alert>
            )}
            {status === 500 && (
              <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                Internal Server Error
              </Alert>
            )}
            <p style={{ textAlign: "center", paddingTop: "40px", fontSize: "32px" }}>Send Invitation</p>
            <div className="flex flex-col gap-4">
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({field}) => (
                  <TextField
                    {...field}
                    placeholder="Email"
                    variant="outlined"
                    label="Email"
                    className={"w-full"}
                    error={errors.email}
                    helperText={errors.email?.message}
                    InputLabelProps={{ required: true }}
                    size="small"
                  />
                )}
              />
            </div>
            <Box className="flex flex-row justify-center mx-auto gap-2 w-2/6 mt-3">
              <Button variant="outlined" onClick={() => navigate("/home")} className="mx-auto">
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSubmit(onSubmit)} disable={disabled} className="mx-auto">
                Send
              </Button>
            </Box>
          </Box>
          <Snackbar
            open={openSnackBar}
            autoHideDuration={3000}
            onClose={handleCloseSnackBar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <AlertBar onClose={handleCloseSnackBar} severity="success" sx={{ width: "100%" }}>
              Invitation sent successfully.
            </AlertBar>
          </Snackbar>
        </Container>
      </div>
    </>
  );
};

const mapStatetoProps = (state) => {
  return {
    token: state.auth.token,
    userdata: state.auth.userdata,
    status: state.setting.invitationResponseStatus,
    invitationResponse: state.setting.invitationResponse,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    callSendInvite: function (data, token) {
      dispatch(sendInvite(data, token));
    },
    callResetStatus: function () {
      dispatch(resetStatus());
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Invite);


import {
  responseFail,
} from "../action/authAction";
import {
  getPriceHistorySuccess,
  getPortfolioSummarySuccess,
  getAccountSuccess,
  orderPreviewSuccess,
  orderPreviewFail,
  orderConfirmSuccess,
  orderConfirmFail,
  previewSellSuccess,
  previewSellFail,
  sellSuccess,
  sellFail,
  getLatestTransactionsSuccess,
  getLatestTransactionsFail,
  getPortfolioSummaryFail
} from '../action/homeAction.js';
import { API } from "./api";
import {API_ENDPOINTS} from "./api-endpoint";

export function getPricesHistory(token) {
  return (dispatch) => API.request({
    method: 'GET',
    url: `${API_ENDPOINTS.PRICE_HISTORY}`,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
  }).then(response => {
    dispatch(getPriceHistorySuccess(response));
  }).catch((error) => {
    dispatch(responseFail(false));
  });
}

export function requestSummary(token) {
  return (dispatch) => API.request({
    method: 'GET',
    url: `${API_ENDPOINTS.WALLET_SUMMARY}`,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
  }).then(response => {
    dispatch(getPortfolioSummarySuccess(response));
  }).catch((error) => {
    dispatch(getPortfolioSummaryFail(error));
  });
}

export function getAccounts(token) {
  return (dispatch) => API.request({
    method: 'GET',
    url: `${API_ENDPOINTS.WALLET_ACCOUNTS}`,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
  }).then(response => {
    dispatch(getAccountSuccess(response));
  }).catch((error) => {
    dispatch(responseFail(false));
  });
}

export function orderPreview(data, token) {
  return (dispatch) => API.request({
    method: 'POST',
    url: `${API_ENDPOINTS.PREVIEW_BUY}`,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
    data:data,
  }).then(response => {
    dispatch(orderPreviewSuccess(response));
  }).catch((error) => {
    dispatch(orderPreviewFail(error));
  });
}

export function orderConfirm(data, token) {
  return (dispatch) => API.request({
    method: 'POST',
    url: `${API_ENDPOINTS.BUY}`,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
    data: data,
  }).then(response => {
    dispatch(orderConfirmSuccess(response));
  }).catch((error) => {
    dispatch(orderConfirmFail(error));
  });
}

export function previewSell(data, token) {
  return (dispatch) => API.request({
    method: 'POST',
    url: `${API_ENDPOINTS.PREVIEW_SELL}`,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
    data:data,
  }).then(response => {
    dispatch(previewSellSuccess(response));
  }).catch((error) => {
    dispatch(previewSellFail(error));
  });
}

export function sell(data, token) {
  return (dispatch) => API.request({
    method: 'POST',
    url: `${API_ENDPOINTS.SELL}`,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
    data: data,
  }).then(response => {
    dispatch(sellSuccess(response));
  }).catch((error) => {
    dispatch(sellFail(error));
  });
}

export function requestLatestTransactions(token) {
  return (dispatch) => API.request({
    method: 'GET',
    url: `${API_ENDPOINTS.LATEST_TRANSACTIONS}`,
    headers: {
      Authorization: `Bearer ${token}`,
      exchange_provider: 'pi',
    },
  }).then(response => {
    dispatch(getLatestTransactionsSuccess(response));
  }).catch((error) => {
    dispatch(getLatestTransactionsFail(error));
  });
}

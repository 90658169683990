import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getADDRESS } from "../../redux/services/paymentServices";
import { coinToLogo } from "../../config/constants";
import { Box, Button, Snackbar } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import WarningIcon from "@mui/icons-material/Warning";
import { Container, Divider, List, ListItem, ListItemAvatar, ListItemText, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";

const error_text = {
  color: "red",
};

const CryptoAddress = (props) => {
  const { addressSuccessResponse, requestADDRESS, token } = props;
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if(token !== ""){
      requestADDRESS(token);
    }
  }, [token]);

  const handleClick = (address) => {
    navigator.clipboard.writeText(address);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const generateList = () => {
    if (addressSuccessResponse) {
      return addressSuccessResponse.map((entry, index) => {
        return (
          <Fragment entry={index}>
            <ListItem sx={{ borderColor: "primary.borderColor" }} button={true} divider={true}>
              <ListItemAvatar>
                <Avatar src={`./images/coin-icons/${coinToLogo.get(entry?.currency_code?.toLowerCase())}`} size="40" />
              </ListItemAvatar>
              <ListItemText primary={`${entry.currency_code}`} />
              <ListItemText
                sx={{ display: "flex", justifyContent: "flex-end", marginRight: "10px" }}
                primary={`${entry.address}`}
              />
              <ContentCopyIcon
                className="copy_btn"
                sx={{ float: "right" }}
                fontSize="small"
                onClick={() => handleClick(entry.address)}
              />
            </ListItem>
            <Divider />
          </Fragment>
        );
      });
    } else {
      return (
        <ListItem>
          <WarningIcon sx={{ color: "red" }} />
          <ListItemText primaryTypographyProps={{ style: error_text }} primary="No Data Found" />
        </ListItem>
      );
    }
  };

  return (
    <>
      <Container maxWidth="md">
        {
          <Box sx={{ p: "20px", m: "1%" }} className="shadow-xl">
            <p style={{ paddingTop: "45px", paddingBottom: "45px", textAlign: "center", fontSize: "32px" }}>
              Crypto Address
            </p>
            <List style={{ backgroundColor: "paper", borderRadius: "20px" }}>{generateList()}</List>
            <div className="flex justify-center my-1">
              <Button variant="contained" className="mx-auto" onClick={() => navigate("/home")}>
                Home
              </Button>
            </div>
          </Box>
        }
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} message="Address copied" />
      </Container>
    </>
  );
};

const mapStatetoProps = (state) => {
  return {
    token:state.auth.token,
    addressSuccessResponse: state.payment.addressSuccessResponse,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    requestADDRESS: function (token) {
      dispatch(getADDRESS(token));
    },
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(CryptoAddress);

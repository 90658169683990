import * as React from "react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Button, Snackbar } from "@mui/material";
import ListItemIcon from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAuth0 } from "@auth0/auth0-react";
import { Alert, CircularProgress, TextField } from "@mui/material";
import { getCASH, getRequestWithdraw } from "../../redux/services/paymentServices";
import MuiAlert from "@mui/material/Alert";
import { resetWithdrawalStatus } from "../../redux/action/paymentAction";

const AlertBar = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Profile(props) {
  const { withdrawalStatus, cashBalanceResponse, requestCASH, requestWithdraw, requestResetWithdrawalStatus, token } = props;
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { remote_id } = state;
  const { logout } = useAuth0();

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .transform((value) => (isNaN(value) ? 0 : value))
      .required("Amount is required")
      .positive(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = (data) => {
    data = {
      description: "",
      from_account_number: "",
      fund_transfer_method_id: remote_id,
      money: {
        amount: data.amount,
        currency: "USD",
      },
      to: "",
    };
    requestWithdraw(JSON.stringify(data), token);
  };

  React.useEffect(() => {
    if(token !== ""){
      requestCASH(token);
    }
  }, [requestCASH, token]);

  if (withdrawalStatus === 401) {
    logout();
  }

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  React.useEffect(() => {
    if (withdrawalStatus === 201) {
      setOpenSnackBar((prev) => true);
      requestResetWithdrawalStatus();
      setTimeout(() => {
        navigate("/transfer");
      }, [3000]);
    }
  }, [withdrawalStatus]);
  return (
    <>
      <div className="login_wrapper">
        <Container maxWidth="lg">
          <Box sx={{ p: "20px", mt: "1%" }} className="shadow-2xl">
            {withdrawalStatus === 400 && (
              <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                Please enter correct information.
              </Alert>
            )}
            {withdrawalStatus === 422 && (
              <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                Your request could not be processed.
              </Alert>
            )}
            {withdrawalStatus === 403 && (
              <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                You are not authorised to perform this action.
              </Alert>
            )}
            {withdrawalStatus === 500 && (
              <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                Internal Server Error
              </Alert>
            )}
            <p style={{ textAlign: "center", fontSize: "32px" }}>Amount</p>
            {cashBalanceResponse?.length > 0 ? (
              <p style={{ textAlign: "center", fontSize: "24px", color: "#9faBB1" }}>
                Withdrawable Cash {cashBalanceResponse[0]?.disbursable}
              </p>
            ) : (
              <CircularProgress className="text-center" />
            )}
            <div className="flex justify-center ">
              <Controller
                name="amount"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    label="Amount"
                    variant="outlined"
                    className={"w-1/4"}
                    defaultValue={null}
                    error={errors.amount}
                    helperText={errors.amount?.message}
                    InputLabelProps={{ required: true }}
                    size="small"
                  />
                )}
              />
            </div>
            <div
              className="d-flex"
              style={{
                alignItems: "center",
                padding: "30px",
                marginRight: "100px",
                marginLeft: "100px",
                fontSize: "25px",
                border: "1px solid white",
                borderRadius: "20px",
              }}
            >
              <ListItemIcon sx={{ display: "contents" }}>
                <InfoIcon sx={{ width: "24px", height: "24px", marginRight: "70px" }} fontSize="small" />
              </ListItemIcon>
              <div style={{ textAlign: "left", fontSize: "16px" }}>
                <p>Wire Transfer fee:$15 per transaction </p>
                <p>Wire Recalls: $50 per transaction</p>
                <p className="mt-5">
                  Money is not recoverable; Similar to using cashier's check to get the final funds to a recipient
                </p>
              </div>
            </div>
            <div className="text-center">
              <Button
                variant="contained"
                color={"primary"}
                onClick={handleSubmit(onSubmit)}
                className="mx-auto text-center"
              >
                Send
              </Button>
            </div>
          </Box>
          <Snackbar
            open={openSnackBar}
            autoHideDuration={3000}
            onClose={handleCloseSnackBar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <AlertBar onClose={handleCloseSnackBar} severity="success" sx={{ width: "100%" }}>
              Your transfer request has been received.
            </AlertBar>
          </Snackbar>
        </Container>
      </div>
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    token:state.auth.token,
    cashBalanceResponse: state.payment.cashBalanceResponse,
    withdrawalStatus: state.payment.withdrawalStatus,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    requestCASH: function (token) {
      dispatch(getCASH(token));
    },
    requestWithdraw: function (data, token) {
      dispatch(getRequestWithdraw(data, token));
    },
    requestResetWithdrawalStatus: function () {
      dispatch(resetWithdrawalStatus());
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Profile);

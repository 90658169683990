import React, {useEffect, useInsertionEffect, useState} from "react";
import { connect, useDispatch } from "react-redux";
import { Box, Button, Container, TextField, Typography, Grid, Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useNavigate } from "react-router-dom";
import { useForm, Controller, set } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getHelp } from "../../redux/services/settingsServices";
import { createInvoice } from "../../redux/services/invoiceServices";
import { Alert } from "@mui/material";
import {acceptedPaymentCoins} from '../../config/constants';
import InvoiceDialog from "./InvoiceDialog";
import { format } from 'date-fns'
import Script from "react-load-script";
import dayjs from 'dayjs';
import { createInvoiceRequestFunction } from "./InvoiceHelper";
import { getADDRESS } from '../../redux/services/paymentServices';
import { openDialog, resetInvoiceState } from '../../redux/action/invoiceAction';


function getCryptoPaymentOptions(addressSuccessResponse, selectedCoinOptions){
  if(addressSuccessResponse && selectedCoinOptions){
    return addressSuccessResponse.filter( option => selectedCoinOptions.includes(option.currency_code));
    
  }
  return [];
}

const CreateInvoice = (props) => {
  const { createInvoiceStatus, uploadInvoiceStatus, callRequestAddress, token, addressSuccessResponse, isOpen} = props;
  const navigate = useNavigate();
  const [itemTotal, setItemTotal] = useState("0.00");
  const [taxTotal, setTaxTotal] = useState("0.00");
  const [shippingTotal, setShippingTotal] = useState("0.00");
  const [total, setTotal] = useState("0.00");
  const [previewInvoice, setPreviewInvoice] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});

  const [addressone, setAddressone] = useState("");
  const [addresstwo, setAddresstwo] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [state, setState] = useState();
  const [country] = useState('US');

  const [shippingAddressone, setShippingAddressone] = useState("");
  const [shippingAddresstwo, setShippingAddresstwo] = useState("");
  const [shippingCity, setShippingCity] = useState("");
  const [shippingZipcode, setShippingZipcode] = useState("");
  const [shippingState, setShippingState] = useState();
  const [shippingCountry] = useState('US');

  const [dueDate, setDueDate] = useState(dayjs());
  const [apiDueDate, setApiDueDate] = useState(dayjs());
  const [serviceDate, setServiceDate] = useState(dayjs());

  const autocompleteRef = React.useRef(null);
  const autocompleteRefShippingAddress = React.useRef(null);
  const [userWalletAddress, setUserWalletAddress] = React.useState({});
  const [createInvoiceRequest, setCreateInvoiceRequest] = React.useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if(token)
    callRequestAddress(token);
  }, [token])

  useEffect(() => {
    setUserWalletAddress(addressSuccessResponse);
  }, [addressSuccessResponse])

  const handlePlaceSelect = () => {
    const addressObject = autocompleteRef.current.getPlace();
    let addressOne = getAddressOne(addressObject)
    setAddressone(addressOne);

    let city = getAddressByType(addressObject, "locality");
    setCity(city.long_name);

    let code = getAddressByType(addressObject, "postal_code");
    setZipcode(code.long_name);

    let state = getAddressByType(addressObject, "administrative_area_level_1");
    setState(state.short_name);
  };

  const handleShippingPlaceSelect = () => {
    const addressObject = autocompleteRefShippingAddress.current.getPlace();
    
    let addressOne = getAddressOne(addressObject)
    setShippingAddressone(addressOne);

    let city = getAddressByType(addressObject, "locality");
    setShippingCity(city.long_name);

    let code = getAddressByType(addressObject, "postal_code");
    setShippingZipcode(code.long_name);

    let state = getAddressByType(addressObject, "administrative_area_level_1");
    setShippingState(state.short_name);
  };

  const getAddressOne = (addressObject) => {
    let streetNumber = getAddressByType(addressObject, "street_number");
    streetNumber ? streetNumber = streetNumber.long_name : streetNumber = "";

    let streetName = getAddressByType(addressObject, "route");
    streetName ? streetName = streetName.long_name : streetName = "";

    return streetNumber + " " + streetName;
  }

  const getAddressByType = (address, type) => {
    if (!type)
      return null;
    if (!address || !address.address_components || address.address_components.length === 0)
      return null;

    type = type.toLowerCase();

    for (let comp of address.address_components) {
      if (!comp.types || comp.types.length === 0)
        continue;

      if (comp.types.findIndex(x => x.toLowerCase() === type) > -1)
        return comp;
    }
    return null;
  }

  const handleDueDateChange = (newValue) => {
    setApiDueDate(new Date(newValue).toISOString());
    setDueDate(newValue);
  };

  const handleServiceDateChange = (newValue) => {
    setServiceDate(newValue);
  };

  const [selectedCoinOptions] = useState([]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("email is required"),
    phone: Yup.string().required("Phone is required"),
    attention: Yup.string(),
    addressone: Yup.string(),
    addresstwo: Yup.string(),
    city: Yup.string(),
    zipcode: Yup.string(),
    state: Yup.string(),
    country: Yup.string(),
    shippingAddressone: Yup.string(),
    shippingAddresstwo: Yup.string(),
    shippingCity: Yup.string(),
    shippingZipcode: Yup.string(),
    shippingState: Yup.string(),
    shippingCountry: Yup.string(),
    invoiceTitle: Yup.string(),
    invoiceId: Yup.string(),
    message: Yup.string(),
    serviceDate: Yup.string(),
    dueDate:Yup.string(),
    item:Yup.string().required('Item is required.'),
    quantity:Yup.number().required('Quantity is required.'),
    price:Yup.number().required('Price is required.'),
    itemTotal:Yup.number().min(0).required("Should be 0 or more"),
    tax:Yup.string(),
    taxPct:Yup.number().min(0).required("Should be 0 or more"),
    taxTotal:Yup.number().min(0),
    shipping:Yup.number().min(0),
    shippingTotal:Yup.number().min(0),
    total:Yup.number().min(0),
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name:'',
      email:'',
      phone:'',
      attention:'',
      addressone: addressone,
      addresstwo: addresstwo,
      city:city,
      zipcode: zipcode,
      state: state,
      country: country,
      shippingAddressone: shippingAddressone,
      shippingAddresstwo: shippingAddresstwo,
      shippingCity:shippingCity,
      shippingZipcode: shippingZipcode,
      shippingState: shippingState,
      shippingCountry: shippingCountry,
      invoiceTitle:'',
      invoiceId: '000001',
      quantity:0,
      price:0,
      item:'',
      itemTotal:itemTotal,
      tax:'',
      taxPct:0,
      taxTotal:taxTotal,
      shipping:0.00,
      shippingTotal:shippingTotal,
      total: total,
      issueDate: format(new Date(), 'MMM dd, yyyy'),
      dueDate,
      serviceDate,
    }
  });

  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "addressone":
          setAddressone(value);
          if(value===''){
            setAddresstwo('');
            setCity('');
            setZipcode('');
            setState('');
          }
        break;
      case "addresstwo":
        setAddresstwo(value);
        break;
        case "city":
          setCity(value);
        break;
      case "zipcode":
          setZipcode(value);
        break;
      case "state":
        setState(value);
      break;
      default:
        break;
    }
  }

  const handleShippingAddressChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
        case "shippingAddressone":
          setShippingAddressone(value);
          if(value===''){
            setShippingAddresstwo('');
            setShippingCity('');
            setShippingZipcode('');
            setShippingState('');
          }
          break;
        case "shippingAddresstwo":
          setShippingAddresstwo(value);
          break;
        case "shippingCity":
          setShippingCity(value);
          break;
        case "shippingZipcode":
          setShippingZipcode(value);
        break;
        case "shippingState":
          setShippingState(value);
          break;
      default:
        break;
    }
  }

  const handleScriptLoad = () => {
    const options = {
      componentRestrictions: { country: ["usa"] },
      libraries: ["places"],
      fields: ["address_components"]
    };

    /*global google*/
    autocompleteRef.current = new google.maps.places.Autocomplete(
      document.getElementById("mainInput"),
      options
    );

    autocompleteRefShippingAddress.current = new google.maps.places.Autocomplete(
      document.getElementById("shippingAddressMainInput"),
      options
    );

    autocompleteRef.current.addListener("place_changed", handlePlaceSelect);
    autocompleteRefShippingAddress.current.addListener("place_changed", handleShippingPlaceSelect);
  };

  const handleAcceptedPaymentCoinChange = (changedCoin) => {
    if(!changedCoin.target.checked){
      const index = selectedCoinOptions.indexOf(changedCoin.target.id);
    if (index > -1) { 
      selectedCoinOptions.splice(index, 1);
    }
    } else {
      selectedCoinOptions.push(changedCoin.target.id);
    }
  }

  const onSubmit = (data) => {
    const cryptoPaymentOptions = getCryptoPaymentOptions(addressSuccessResponse, selectedCoinOptions);
    let previewData = {
      ...data, 
      itemTotal,
      taxTotal, 
      shippingTotal,
      total:(Number(itemTotal)+Number(taxTotal)+Number(shippingTotal)).toFixed(2), 
      selectedCoinOptions,
      addressone,
      addresstwo,
      city,
      zipcode,
      state,
      country,
      shippingAddressone,
      shippingAddresstwo,
      shippingCity,
      shippingZipcode,
      shippingState,
      shippingCountry,
      cryptoPaymentOptions:cryptoPaymentOptions,
      apiDueDate,
      dueDate: format(new Date(apiDueDate), 'MMM dd, yyyy'),
    };
    
    const createInvoiceRequest = createInvoiceRequestFunction(previewData);
    setInvoiceData(previewData);
    setCreateInvoiceRequest(createInvoiceRequest);
    dispatch(openDialog(true));
    setPreviewInvoice(true); 
  };

  useEffect(() => {
    if(watch('quantity') || watch('price')){
      if(watch('quantity')<0 || watch('price')<0){
        if(watch('quantity')<0){
          setValue('quantity',0.00);
        }
        if(watch('price')){
          setValue('price',0.00);
        }
        setItemTotal(0.00);
      } else {
        const tempItemTotal = (Math.trunc(getValues('quantity')*getValues('price')*100)/100).toFixed(2);
        setItemTotal(tempItemTotal);
      }
    }
    
    if(watch('taxPct')){
      if(watch('taxPct')<0){
        setValue('taxPct',0.00);
        setTaxTotal(0.00);
      } else {
        setTaxTotal((Math.trunc(itemTotal*getValues('taxPct')*100)/10000).toFixed(2));
      }
      
    }

    if(watch('shipping')){
      if(watch('shipping')<0){
        setValue('shipping',0.00);
        setShippingTotal(0.00);
      } else {
        setShippingTotal((Math.trunc(getValues('shipping')*100)/100).toFixed(2));
      }
    }
  }, [watch('quantity'), watch('price'), watch('taxTotal'), watch('shipping'), watch('taxPct')])
  
  const handleClose = () => {
    setPreviewInvoice(false);
  }

  useEffect(() => {
    if(isOpen !== undefined){
      setPreviewInvoice(isOpen);
    }
  }, [isOpen])

  useEffect( () => () => dispatch(resetInvoiceState()), [] );


  return (
    <>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&libraries=places`}
        onLoad={handleScriptLoad}/>
      <div className="login_wrapper login_wrapper-bottom-margin">
        <Container maxWidth="md">
          <Box component="form" className="shadow-2xl" sx={{ p: "20px", mt: "5%" }}>
              { (createInvoiceStatus === 400 || uploadInvoiceStatus === 400) && (
                <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                  Please enter correct information.
                </Alert>
              )}
              { (createInvoiceStatus === 422 || uploadInvoiceStatus === 422) && (
                <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                  Your request could not be processed.
                </Alert>
              )}
              {(createInvoiceStatus === 403 || uploadInvoiceStatus === 403) && (
                <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                  You are not authorised to perform this action.
                </Alert>
              )}
              {(createInvoiceStatus === 500 || uploadInvoiceStatus === 500) && (
                <Alert className="mt-4 max-w-sm mx-auto" severity="error">
                  Internal Server Error
                </Alert>
              )}
              <p style={{ textAlign: "center", paddingTop: "40px", fontSize: "32px" }}>Invoice Details</p>
              <Typography variant="subtitle1" sx={{mb:1, fontWeight:'bold'}}>
                Customer Details
              </Typography>
              <Box sx= {{mb:2, pr:5, py:2, mx:5, my:2, border:0, borderColor: 'grey.500' }}>
                <div className="flex flex-col gap-4">
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Customer"
                        variant="outlined"
                        label="Customer Name"
                        className={"w-full"}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        InputLabelProps={{ required: true }}
                        size="small"
                      />
                    )}
                  />
                  <Controller
                    name="email"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Email"
                        variant="outlined"
                        label="Email"
                        className={"w-full"}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        InputLabelProps={{ required: true }}
                        size="small"
                      />
                    )}
                  />
                  <Controller
                    name="phone"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Phone Number"
                        variant="outlined"
                        label="Phone"
                        className={"w-full"}
                        error={!!errors.phone}
                        helperText={errors.phone?.message}
                        InputLabelProps={{ required: true }}
                        size="small"
                      />
                    )}
                  />
                  <Controller
                    name="attention"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Attention"
                        variant="outlined"
                        label="Attention"
                        className={"w-full"}
                        error={!!errors.attention}
                        helperText={errors.attention?.message}
                        InputLabelProps={{ required: false }}
                        size="small"
                      />
                    )}
                  />
                </div>
              </Box>
              
              <Typography variant="subtitle1" sx={{mb:1, fontWeight:'bold'}}>
                Address
              </Typography>
              <Box sx= {{mb:2, pr:5, py:2, mx:5, my:2, border:0, borderColor: 'grey.500' }}>
                <Typography variant="subtitle2" sx={{mb:1, fontWeight:'bold'}}>
                  Billing Address
                </Typography>      
                <div className="flex flex-col gap-4">
                  <div className="flex flex-row gap-2">    
                    <Controller
                      name="addressone"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="Address One"
                          id="mainInput"
                          variant="outlined"
                          label="Address One"
                          className={"w-full"}
                          error={!!errors.addressone}
                          helperText={errors.addressone?.message}
                          InputLabelProps={{ required: false}}
                          size="small"
                          onChange={handleAddressChange}
                          onBlur={handleAddressChange}
                          value={addressone}
                        />
                      )}
                    />
                    <Controller
                      name="addresstwo"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="Address Two"
                          variant="outlined"
                          label="Address Two"
                          className={"w-full"}
                          error={!!errors.addresstwo}
                          helperText={errors.addresstwo?.message}
                          InputLabelProps={{ required: false }}
                          size="small"
                          onChange={handleAddressChange}
                          onBlur={handleAddressChange}
                          value={addresstwo}
                        />
                      )}
                    />
                  </div>
                  <div className="flex flex-row gap-2">  
                  <Controller
                    name="city"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="City"
                        variant="outlined"
                        label="City"
                        className={"w-full"}
                        error={!!errors.city}
                        helperText={errors.city?.message}
                        InputLabelProps={{ required: false }}
                        size="small"
                        onChange={handleAddressChange}
                        onBlur={handleAddressChange}
                        value={city}
                      />
                    )}
                  />
                  <Controller
                    name="zipcode"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Zip Code"
                        variant="outlined"
                        label="Zip Code"
                        className={"w-full"}
                        error={!!errors.zipcode}
                        helperText={errors.zipcode?.message}
                        InputLabelProps={{ required: false }}
                        size="small"
                        onChange={handleAddressChange}
                        onBlur={handleAddressChange}
                        value={zipcode}
                      />
                    )}
                  /> 
                  <Controller
                    name="state"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="State"
                        variant="outlined"
                        className={"w-full"}
                        error={!!errors.state}
                        helperText={errors.state?.message}
                        InputLabelProps={{ required: false }}
                        size="small"
                        onChange={handleAddressChange}
                        onBlur={handleAddressChange}
                        value={state}
                      />
                    )}
                  />  
                   <Controller
                    name="country"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Country"
                        variant="outlined"
                        label="Country"
                        className={"w-full"}
                        error={!!errors.country}
                        helperText={errors.country?.message}
                        InputLabelProps={{ required: false }}
                        size="small"
                        onChange={handleAddressChange}
                        onBlur={handleAddressChange}
                        value={country}
                        readOnly
                        disabled
                      />
                    )}
                  />
                  </div>
                </div>
                <Typography variant="subtitle2" sx={{my:2, fontWeight:'bold'}}>
                  Shipping Address
                </Typography>      
                <div className="flex flex-col gap-4">
                  <div className="flex flex-row gap-2">    
                    <Controller
                      name="shippingAddressone"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="Address One"
                          id="shippingAddressMainInput"
                          variant="outlined"
                          label="Address One"
                          className={"w-full"}
                          error={!!errors.shippingAddressone}
                          helperText={errors.shippingAddressone?.message}
                          InputLabelProps={{ required: false}}
                          size="small"
                          onChange={handleShippingAddressChange}
                          onBlur={handleShippingAddressChange}
                          value={shippingAddressone}
                        />
                      )}
                    />
                    <Controller
                      name="shippingAddresstwo"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="Address Two"
                          variant="outlined"
                          label="Address Two"
                          className={"w-full"}
                          error={!!errors.shippingAddresstwo}
                          helperText={errors.shippingAddresstwo?.message}
                          InputLabelProps={{ required: false }}
                          size="small"
                          onChange={handleShippingAddressChange}
                          onBlur={handleShippingAddressChange}
                          value={shippingAddresstwo}
                        />
                      )}
                    />
                  </div>
                  <div className="flex flex-row gap-2">  
                  <Controller
                    name="shippingCity"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="City"
                        variant="outlined"
                        label="City"
                        className={"w-full"}
                        error={!!errors.shippingCity}
                        helperText={errors.shippingCity?.message}
                        InputLabelProps={{ required: false }}
                        size="small"
                        onChange={handleShippingAddressChange}
                        onBlur={handleShippingAddressChange}
                        value={shippingCity}
                      />
                    )}
                  />
                  <Controller
                    name="shippingZipcode"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Zip Code"
                        variant="outlined"
                        label="Zip Code"
                        className={"w-full"}
                        error={!!errors.shippingZipcode}
                        helperText={errors.shippingZipcode?.message}
                        InputLabelProps={{ required: false }}
                        size="small"
                        onChange={handleShippingAddressChange}
                        onBlur={handleShippingAddressChange}
                        value={shippingZipcode}
                      />
                    )}
                  /> 
                  <Controller
                    name="shippingState"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        lable="State"
                        variant="outlined"
                        className={"w-full"}
                        error={!!errors.shippingState}
                        helperText={errors.shippingState?.message}
                        InputLabelProps={{ required: false }}
                        size="small"
                        onChange={handleShippingAddressChange}
                        onBlur={handleShippingAddressChange}
                        value={shippingState}
                      />
                    )}
                  />  
                   <Controller
                    name="shippingCountry"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Country"
                        variant="outlined"
                        label="Country"
                        className={"w-full"}
                        error={!!errors.shippingCountry}
                        helperText={errors.shippingCountry?.message}
                        InputLabelProps={{ required: false }}
                        size="small"
                        onChange={handleShippingAddressChange}
                        onBlur={handleShippingAddressChange}
                        value={shippingCountry}
                        readOnly
                        disabled
                      />
                    )}
                  />
                  </div>
                </div>
              </Box>         

              <Typography variant="subtitle1" sx={{mb:1, fontWeight:'bold'}}>
                Invoice details
              </Typography>
              <Box sx= {{mb:2, pr:5, py:2, mx:5, my:2, border:0, borderColor: 'grey.500' }}>
              <div className="flex flex-col gap-4">
                <Controller
                  name="invoiceTitle"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Invoice Title"
                      variant="outlined"
                      label="Invoice Title"
                      className={"w-full"}
                      error={!!errors.invoiceTitle}
                      helperText={errors.invoiceTitle?.message}
                      InputLabelProps={{ required: false }}
                      size="small"
                    />
                  )}
                />
                <Controller
                  name="invoiceId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Invoice ID"
                      variant="outlined"
                      label="Invoice ID"
                      className={"w-full"}
                      error={!!errors.invoiceId}
                      helperText={errors.invoiceId?.message}
                      InputLabelProps={{ required: true }}
                      size="small"
                    />
                  )}
                />
                <Controller
                  name="message"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Message"
                      variant="outlined"
                      label="Message"
                      className={"w-full"}
                      error={!!errors.message}
                      helperText={errors.message?.message}
                      InputLabelProps={{ required: false }}
                      size="medium"
                    />
                  )}
                />
                 <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Service Date"
                    inputFormat="MMM DD, YYYY"
                    value={serviceDate}
                    onChange={handleServiceDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DesktopDatePicker
                    label="Due Date"
                    inputFormat="MMM DD, YYYY"
                    value={dueDate}
                    onChange={handleDueDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              </Box>
              <Typography variant="subtitle1" sx={{mb:1, fontWeight:'bold'}}>
                Line Item
              </Typography>
              <Box sx= {{mb:2, pr:5, py:2, mx:5, my:2, border:0, borderColor: 'grey.500' }}>
              <div className="flex flex-col gap-4">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                      <Controller
                      name="item"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="Item"
                          variant="outlined"
                          label="Item or Description"
                          className={"w-full"}
                          error={!!errors.item}
                          helperText={errors.item?.message}
                          InputLabelProps={{ required: true }}
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                      <Controller
                      name="quantity"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="0"
                          variant="outlined"
                          label="Quantity"
                          className={"w-full"}
                          error={!!errors.quantity}
                          helperText={errors.quantity?.message}
                          InputLabelProps={{ required: false }}
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                      <Controller
                      name="price"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="0.00"
                          variant="outlined"
                          label="Price"
                          className={"w-full"}
                          error={!!errors.price}
                          helperText={errors.price?.message}
                          InputLabelProps={{ required: false }}
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                      <Controller
                      name="itemTotal"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Typography sx={{justifyContent: 'flex-end', display:'flex'}}>
                          {itemTotal}
                        </Typography>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography sx={{justifyContent: 'flex-start', display:'flex'}}>
                      Tax
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                      <Controller
                      name="taxPct"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="0.00"
                          variant="outlined"
                          label="Tax %"
                          className={"w-full"}
                          error={!!errors.taxPct}
                          helperText={errors.taxPct?.message}
                          InputLabelProps={{ required: false }}
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                      <Controller
                      name="taxTotal"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <Typography sx={{justifyContent: 'flex-end', display:'flex'}}>
                          {taxTotal}
                        </Typography>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography sx={{justifyContent: 'flex-start', display:'flex'}}>
                      Shipping
                    </Typography>
                  </Grid>      
                  <Grid item xs={4}>
                      <Controller
                      name="shipping"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="0.00"
                          variant="outlined"
                          label="Shipping Amount"
                          className={"w-full"}
                          error={!!errors.shipping}
                          helperText={errors.shipping?.message}
                          InputLabelProps={{ required: false }}
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                      <Controller
                      name="shippingTotal"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <Typography sx={{justifyContent: 'flex-end', display:'flex'}}>
                          {shippingTotal}
                        </Typography>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                  <Typography variant="subtitle1" sx={{mb:1, fontWeight:'bold'}}>
                      Total
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                      <Controller
                      name="total"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Typography sx={{justifyContent: 'flex-end', display:'flex'}}>
                          {(Number(itemTotal)+Number(taxTotal)+Number(shippingTotal)).toFixed(2)}
                          {/*{Math.trunc(((Number.parseFloat(itemTotal)+Number.parseFloat(taxTotal)+Number.parseFloat(shippingTotal))*100)/100).toFixed(2)}*/}
                        </Typography>
                      )}
                    />
                  </Grid>
                </Grid>
             </div>
              </Box>
              <Typography variant="subtitle1" sx={{mb:1, fontWeight:'bold'}}>
                Payment Options
              </Typography>
              <Box sx= {{mb:2, pr:5, py:2, mx:5, my:2, border:0, borderColor: 'grey.500' }}>
                <FormGroup>    
                    {acceptedPaymentCoins.map((data) => {
                      return (
                        <FormControlLabel control={<Checkbox id={data.key} onChange={handleAcceptedPaymentCoinChange}/>} label={data.label} />
                      );
                    })}
                </FormGroup>   
              </Box>
              <Box className="flex flex-row justify-center mx-auto gap-2 w-2/6 mt-3">
                <Button variant="outlined" onClick={() => navigate("/home")} className="mx-auto">
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleSubmit(onSubmit)} className="mx-auto">
                  Preview
                </Button>
              </Box>
          </Box>
          <InvoiceDialog open={previewInvoice} onClose={handleClose} invoice={invoiceData} createInvoiceRequest={createInvoiceRequest}></InvoiceDialog>
        </Container>
      </div>  
    </>
  );
};

const mapStatetoProps = (state) => {
  return {
    userdata: state.auth.userdata,
    createInvoiceStatus: state.invoice.createInvoiceResponse?.status,
    uploadInvoiceStatus: state.invoiceUpload.uploadInvoiceResponse?.status,
    token: state.auth.token,
    addressSuccessResponse: state.payment.addressSuccessResponse,
    isOpen: state.invoice.isOpen,
    uploadInvoiceResponse: state.invoiceUpload.uploadInvoiceResponse,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    callgetHelp: function (data, token) {
      dispatch(getHelp(data, token));
    },
    callCreateInvoice: function(data, token) {
      dispatch(createInvoice(data, token))
    },
    callRequestAddress: function(token){
      dispatch(getADDRESS(token));
    }
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(CreateInvoice);

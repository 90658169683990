export const initialState = {
  postPaymentMethodsSuccessResponse: null,
  postPaymentMethodsFailResponse: null,
  withdrawalRequestResponse: null,
  withdrawalStatus: null,
  bankAccounts: [],
  cashBalanceResponse: null,
  cashBalanceResponseStatus: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_WTI_SUCCESS":
      return {
        ...state,
        status: action.payload.status,
        wtiSuccessResponse: action.payload.data,
        msg: "GET_WTI_SUCCESS",
      };
    case "GET_WTI_FAIL":
      return {
        ...state,
        status: action.payload.status,
        wtiFailResponse: action.payload,
        msg: "GET_WTI_FAIL",
      };

    case "GET_ADDRESS_SUCCESS":
      return {
        ...state,
        status: action.payload.status,
        addressSuccessResponse: action.payload.data,
        msg: "GET_ADDRESS_SUCCESS",
      };
    case "GET_ADDRESS_FAIL":
      return {
        ...state,
        status: action.payload.status,
        addressFailResponse: action.payload,
        msg: "GET_ADDRESS_FAIL",
      };

    case "GET_CASH_SUCCESS":
      return {
        ...state,
        cashBalanceResponseStatus: action.payload.status,
        cashBalanceResponse: action.payload.data,
        msg: "GET_CASH_SUCCESS",
      };
    case "GET_CASH_FAIL":
      return {
        ...state,
        cashBalanceResponseStatus: action.payload.status,
        cashBalanceResponse: action.payload,
        msg: "GET_CASH_FAIL",
      };
    case "POST_PAYMENT_METHODS_SUCCESS":
      return {
        ...state,
        status: action.payload.status,
        postPaymentMethodsSuccessResponse: action.payload.data,
        msg: "POST_PAYMENT_METHODS_SUCCESS",
      };
    case "POST_PAYMENT_METHODS_FAIL":
      return {
        ...state,
        status: action.payload.status,
        postPaymentMethodsFailResponse: action.payload,
        msg: "POST_PAYMENT_METHODS_FAIL",
      };
    case "GET_PAYMENT_METHODS_SUCCESS":
      return {
        ...state,
        status: action.payload.status,
        bankAccounts: action.payload.data,
        msg: "GET_PLAID_METHODS_SUCCESS",
      };
    case "GET_PAYMENT_METHODS_FAIL":
      return {
        ...state,
        status: action.payload.status,
        bankAccounts: action.payload,
        msg: "GET_PLAID_METHODS_FAIL",
      };
    case "WITHDRAWAL_SUCCESS":
      return {
        ...state,
        withdrawalStatus: action.payload.status,
        msg: "WITHDRAWAL_SUCCESS",
      };
    case "WITHDRAWAL_FAIL":
      return {
        ...state,
        withdrawalStatus: action.payload.status,
        msg: "WITHDRAWAL_FAIL",
      };
    case "RESET_WITHDRAWAL_STATUS":
      return {
        ...state,
        withdrawalStatus: null,
      };
    default:
      return state;
  }
}
